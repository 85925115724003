import React, { useState } from 'react';
import {
    AppBar,
    Toolbar,
    Typography,
    Button,
    Box,
    MenuItem,
    Menu,
    Grid,
    Divider,
    ButtonGroup,
    Snackbar
} from '@material-ui/core';
import { useTranslation } from "react-i18next";
import { useFirebase } from '../shared/context/firebase-context';
import { useHistory, useLocation } from 'react-router-dom';
import { useAdmin } from '../shared/context/admin-context';
import { makeStyles } from '@material-ui/core/styles';
import ArrowDown from '@material-ui/icons/KeyboardArrowDown';
import { useEvent} from '../shared/hooks/useEvents';
import { useUsers } from '../shared/hooks/useUsers';
import Alert from '@material-ui/lab/Alert';
import {NewEventDialog} from './NewEventDialog';


const useStyles = makeStyles((theme) => ({
    appBar: {
        backgroundColor: "#ffffff",
    },
    img: {
        imageRendering: "auto",
    },
    blackButton: {
        color: "#000000",
        textTransform: "none",
        fontWeight: 600,
        fontSize: 17
    },
    accentButton: {
        color: "#FF5851",
        textTransform: "none",
        fontWeight: 600,
        fontSize: 17
    }, languageButton: {
        color: "#000000",
        fontWeight: 400,
        textTransform: "none",
        fontSize: 15
    }, center: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    }, image: {
        border: '1px solid #FF5851',
        width: 40,
        height: 40,
        borderRadius: 20,
        objectFit: 'contain'
    },
    event_item_name: {
        marginTop: "7px"
    },
    event_divider: {
        marginBottom: "6px"
    },
    languageButton: {
        fontSize: 15,
        borderColor: 'primary',
        color: 'black',
        textTransform: 'none' 
    },
    languageImg: {
        width: '25px'
    },
}));

const usePathname = () => {
    const location = useLocation();
    return location.pathname;
}

export function Header() {
    const { t, i18n } = useTranslation();
    const { auth } = useFirebase();
    const { event, userDetails, setEvent } = useAdmin();
    const history = useHistory();
    const [anchorEl, setAnchorEl] = useState(null);
    const classes = useStyles();
    const { eventList } = useEvent();
    const { changeDefaultEvent } = useUsers();
    const [openFailureSnackbar, setOpenFailureSnackbar] = useState(false);
    const [openDialog, setOpenDialog ] = useState(false);

    const handleNewEvent = () => {
        handleClose();
        setOpenDialog(true);
    };

    const handleSignOut = () => {
        auth.signOut().then(function () {
            history.push('/login');
        });
    };

    const handleShowGroups = () => {
        history.push(`/`);
    };

    const handleGoToSettings = () => {
        handleClose();
        history.push(`/ajustes`);
    };

    const handleShowUsers = () => {
        history.push(`/usuarios`);
    };

    const handleShowSessions = () => {
        history.push(`/sesiones`);
    };

    function handleLanguage(lang) {
        i18n.changeLanguage(lang);
    }


    const handleClick = (x) => {
        setAnchorEl(x.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleCloseFailureSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenFailureSnackbar(false);
    };

    let group_btn_class = usePathname() == "/" ? classes.accentButton : classes.blackButton;
    let users_btn_class = usePathname() == "/usuarios" ? classes.accentButton : classes.blackButton;
    let sessions_btn_class = usePathname() == "/sesiones" ? classes.accentButton : classes.blackButton;

    function handleChangeEvent( element ) {
        changeDefaultEvent(userDetails.id, element.id, { successCallback: (success) => { success ? onSuccess(element) : setOpenFailureSnackbar(true) } });
        userDetails.defaultEvent = element.id
    }

    function onSuccess(element){
        setEvent(element);
        setAnchorEl(null);
        history.push(`/`);
    }

    function handleAdminEvents(element) {
        if ((userDetails.isAdmin.includes(element.id))&&(element.id !== userDetails.defaultEvent)) {
            return (
                <div>
                <Divider className={classes.event_divider}/>
                <MenuItem onClick={() => {handleChangeEvent(element)}}>
                    <Grid container spacing={2} justify="flex-start" direction="row" >
                        <Grid item>
                            <img className={classes.image} src={(element.logoUrl !== '') ? element.logoUrl : "/assets/images/no_image.png"} alt={element.name} />
                        </Grid>
                        <Grid item className={classes.event_item_name}>
                            {element.name}
                        </Grid>
                    </Grid>
                </MenuItem>
                </div>
            )
        } else {
            return ''
        }
    }
 
    return (
        <AppBar className={classes.appBar} elevation={1}>
            <Toolbar>
                <Box m={1} mr={3} className={classes.img}>
                    <img src="/assets/images/wemit.png" width="90px" style={{ marginRight: "5px" }} />
                </Box>
                <Box flexGrow="1" textAlign="left">
                    <Button className={group_btn_class} onClick={handleShowGroups}>
                        {t("header.group_title")}
                    </Button>
                    <Button className={users_btn_class} onClick={handleShowUsers}>
                        {t("header.users_title")}
                    </Button>
                    <Button className={sessions_btn_class} onClick={handleShowSessions}>
                        {t("header.sessions_title")}
                    </Button>
                </Box>
                <Box pr={2}>
                    <ButtonGroup variant="text" color="black" aria-label="text primary button group" style={{ position: 'auto' }}>
                    <Button className={classes.languageButton} onClick={() => handleLanguage("es")}>
                        <img src="/assets/images/es.svg" alt='draft color' className={classes.languageImg} />
                    </Button>
                        <Button className={classes.languageButton} onClick={() => handleLanguage("en")}>
                        <img src="/assets/images/en.svg" alt='draft color' className={classes.languageImg} />
                    </Button>
                    </ButtonGroup>
                </Box>
                <Button variant="text" className={classes.languageButton} onClick={handleClick} endIcon={<ArrowDown />}>
                    <img className={classes.image} src={((event == null)||(event.logoUrl === "")) ? "/assets/images/no_image_logo.png" : event.logoUrl} alt={event == null ? '' : event.name} />
                    <Box pl={2}>{event == null ? '' : event.name}</Box>
                </Button>
                <Menu id="menu"
                    anchorEl={anchorEl}
                    elevation={1}
                    keepMounted
                    className={classes.blackButton}
                    open={Boolean(anchorEl)}
                    onClose={handleClose}>
                        {(event !== null)?(
                            <MenuItem onClick={handleGoToSettings}>
                                <Grid container spacing={2} justify="flex-start" direction="row" >
                                    <Grid item>
                                        <img className={classes.image} src={(event.logoUrl !== "")? event.logoUrl : "/assets/images/no_image_logo.png"} alt={event.name} />
                                    </Grid>
                                    <Grid item>
                                        {event.name}
                                        <Typography color="primary">
                                            {t("header.account_settings")}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </MenuItem>
                        ) : ''}
                        {eventList.map(element => (handleAdminEvents(element)))}   
                    <Box pt={1} pb={1}><Divider width="100%" /></Box>
                    <MenuItem onClick={handleClose, handleNewEvent} className={classes.center}>
                        <Box m={1}>
                            <Typography color="primary">
                                {t("header.new_event")}
                            </Typography>
                        </Box>
                    </MenuItem>
                    <MenuItem onClick={handleClose, handleSignOut} className={classes.center}>
                        <Box m={1}>
                            <Typography color="primary">
                                {t("header.logout")}
                            </Typography>
                        </Box>
                    </MenuItem>
                </Menu>
                <Snackbar open={openFailureSnackbar} autoHideDuration={3000} onClose={handleCloseFailureSnackbar}>
                        <Alert onClose={handleCloseFailureSnackbar} severity="error">
                            {t("header.change_event_error_snackbar")}
                        </Alert>
                </Snackbar>
            </Toolbar>
            <NewEventDialog 
             openNewEventDialog={openDialog}
             setOpenNewEventDialog={setOpenDialog}>
             </NewEventDialog>
        </AppBar>
    );
}