import React, { useState, useCallback } from 'react';
import { useForm, Controller } from "react-hook-form";
import { useHistory } from 'react-router-dom';
import { TextField, FormControl, Button, Box, Typography, Paper, Chip, Grid, IconButton, Divider, Dialog, DialogTitle, DialogContent, DialogActions, DialogContentText, Switch } from '@material-ui/core';
import { useGroupDetails } from '../shared/hooks/useGroupDetails';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from "react-i18next";
import Add from '@material-ui/icons/Add';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import Cropper from 'react-easy-crop'
import { getCroppedImg } from '../shared/utils/cropImage'
import { useGroup } from '../shared/context/group-context';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        justifyContent: 'left',
        flexWrap: 'wrap',
        listStyle: 'none',
        padding: 0,
        margin: 0,
    },
    img: {
        border: '1px solid #FF5851',
        padding: '5px',
        width: '414px',
        height: '235px',
    },
    imgSmall: {
        border: '1px solid #FF5851',
        padding: '55px',
        maxWidth: '814px',
        maxHeight: '435px',
    },
    input: {
        display: 'none',
    },
    chip: {
        marginRight: 10,
        fontSize: 14,
        fontWeight: 400,
        color: "#333333",
        deleteIconColor: 'red'
    },
    cropContainer: {
        position: "absolute",
    },
}));

export function EditGroupForm() {
    const classes = useStyles();
    const { t } = useTranslation();
    const history = useHistory();
    const { group, setGroup } = useGroup();
    const [unCroppedLogo, setUncroppedLogo] = useState();
    const [croppedLogoImage, setCroppedLogoImage] = useState();
    const [openImageDialog, setOpenImageDialog] = useState(false);
    const [crop, setCrop] = useState({ x: 0, y: 0 })
    const [zoom, setZoom] = useState(1)
    const [croppedAreaPixels, setcroppedAreaPixels] = useState()
    const [privateGroup, setPrivateGroup] = useState(group.isPrivate);


    const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
        setcroppedAreaPixels(croppedAreaPixels);
    }, [])

    const handleLogoCrop = (event) => {
        if(event.target.files.length > 0){
            setUncroppedLogo({ url: URL.createObjectURL(event.target.files[0]), file: event.target.files[0] });
            setOpenImageDialog(true);
        }
    }

    const saveCroppedImage = async () => {
        const croppedImage = await getCroppedImg(
            unCroppedLogo.url, croppedAreaPixels, 414, 235
        );
        setPhoto(croppedImage);
        var img = URL.createObjectURL(croppedImage);
        setCroppedLogoImage(img);
        setOpenImageDialog(false);
    }

    const [openDialog, setOpenDialog] = useState(false);
    const handleClickOpen = () => {
        setOpenDialog(true);
    };

    const [openSuccesSnackbar, setOpenSuccesSnackbar] = useState(false);
    const handleCloseSuccessSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenSuccesSnackbar(false);
    };

    const [openFailureSnackbar, setOpenFailureSnackbar] = useState(false);
    const handleCloseFailureSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenFailureSnackbar(false);
    };

    const handleClose = () => {
        setOpenDialog(false);
    };

    const handleDeleteGroup = () => {
        setOpenDialog(false);
        deleteGroup(group.id, { successCallback: () => history.push('/') });
    };

    
    const handleDeleteInterest = (chipToDelete) => () => {
        var topics = group.topics.filter((chip) => chip !== chipToDelete);
        const modifiedGroup = { ...group };
        modifiedGroup.topics = topics;
        setGroup(modifiedGroup);
    };

    const [photo, setPhoto] = useState();

    const [interest, setInterest] = useState('');
    const handleInterestChange = (event) => {
        setInterest(event.target.value);
    };
    const handleSaveInterest = (data) => {
        if (interest !== '') {
            const modifiedGroup = { ...group };
            modifiedGroup.topics.push(interest);
            setGroup(modifiedGroup);
            setInterest('');
        }
    };

    const [generalLoginError, setGeneralLoginError] = useState('');
    const { handleSubmit, control, reset, formState: { errors: fieldsErrors } } = useForm();

    const onSubmit = data => {
        const modifiedGroup = { ...group };
        modifiedGroup.name = data.groupName;
        modifiedGroup.description = data.groupDescription;
        modifiedGroup.isPrivate = privateGroup;
        updateGroupDetails(modifiedGroup, photo, { successCallback: (success) => { 
            if (success) {
                setGroup(modifiedGroup);
                setOpenSuccesSnackbar(true);
            } else {
                setOpenFailureSnackbar(true);
            }
        } });
    };

    const handleSave = () => {
    };

    const { updateGroupDetails, deleteGroup } =
        useGroupDetails();

    const handlePrivateGroup = (event) => {
        setPrivateGroup(event.target.checked);
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <style>{'body { background-color: #f2efea; }'}</style>
            <Box my={2}>
                <Typography variant="h1">
                    {t("group_details_form.title")}
                </Typography>
            </Box>
            <Grid container alignItems="center" spacing={2}>
                <Grid item xs>
                    <Box my={2}>
                        <FormControl fullWidth className={classes.margin} variant="outlined">
                            <Controller
                                name="groupName"
                                render={({ field }) =>
                                    <TextField
                                        id="groupName"
                                        labelwidth={40}
                                        helperText={fieldsErrors.groupName ? fieldsErrors.groupName.message : null}
                                        variant="outlined"
                                        label={t("group_details_form.group_name_label")}
                                        error={!!fieldsErrors.groupName}
                                        {...field}
                                    />}
                                control={control}
                                defaultValue={group.name}
                                rules={{
                                    required: 'Required'
                                }}
                            />
                        </FormControl>
                    </Box>
                </Grid>
                <Grid item xs>
                    <Typography variant="h4">
                        {t("group_details_form.group_name_info")}
                    </Typography>
                </Grid>
            </Grid>
            <Grid container alignItems="center" spacing={2}>
                <Grid item xs>
                    <Box my={2}>
                        <FormControl fullWidth className={classes.margin} variant="outlined">
                            <Controller
                                name="groupDescription"
                                render={({ field }) =>
                                    <TextField
                                        id="groupDescription"
                                        labelwidth={70}
                                        helperText={fieldsErrors.groupDescription ? fieldsErrors.groupDescription.message : null}
                                        variant="outlined"
                                        label={t("group_details_form.group_description_label")}
                                        multiline
                                        error={!!fieldsErrors.groupDescription}
                                        {...field}
                                    />}
                                control={control}
                                defaultValue={group.description}
                                rules={{
                                    required: 'Required'
                                }}
                            />
                        </FormControl>
                    </Box>
                </Grid>
                <Grid item xs>
                </Grid>
            </Grid>
            <Box my={2} width="50%">
                {group &&
                    (
                        <FormControl fullWidth className={classes.margin} variant="outlined">
                            <Grid
                                container
                                alignItems="center">
                                <Switch 
                                    checked={privateGroup}
                                    onChange={handlePrivateGroup}
                                    color="primary"
                                />
                                <Typography variant="p">{t("new_group_form.title_private")}</Typography>
                            </Grid>
                        </FormControl>
                    )}
            </Box>
            <Box my={2}>
                <Typography variant="h2">
                    {t("group_details_form.title_image")}
                </Typography>
            </Box>
            <Grid container alignItems="left" spacing={2} direction="column">
                <Grid item xs>
                    {photo
                        ? <img src={(croppedLogoImage !== "") ? croppedLogoImage : "/assets/images/event_logo_placeholder_grey.png"} alt={group ? group.name : ''} className={classes.img} />
                        : <img src={((group)&&(group.imageUrl !== "")) ? group.imageUrl :  "/assets/images/event_logo_placeholder_grey.png"} alt={group ? group.name : ''} className={classes.imgSmall} />}
                    <input accept="image/*" className={classes.input} id="contained-button-logo" multiple type="file" onChange={handleLogoCrop} />
                </Grid>
                <Grid item xs>
                    <Box mt={2}>
                        <label htmlFor="contained-button-logo">
                            <Button variant="contained" color="primary" component="span" disableElevation>
                                {t("group_details_form.button_upload_image")}
                            </Button>
                        </label>
                    </Box>
                </Grid>
            </Grid>
            <Box mt={4}>
                <Typography variant="h2">
                    {t("group_details_form.title_interests")}
                </Typography>
            </Box>
            <Box mb={4} mt={2}>
                <Grid container direction="row" justify="flex-start" alignItems="baseline">
                    {group &&
                        <Paper elevation={0} component="ul" className={classes.root}>
                            {group.topics.map((data, index) => {
                                return (
                                    <li key={index}>
                                        <Chip label={data} onDelete={handleDeleteInterest(data)} color="secondary" className={classes.chip} />
                                    </li>
                                );
                            })}
                        </Paper>}
                </Grid>
                <Box mt={4}>
                    <Grid container direction="row" justify="flex-start" alignItems="center" spacing={2}>
                        <Grid item>
                            <TextField id="interest" label={t("group_details_form.add_interest")} variant="outlined" value={interest} onChange={handleInterestChange} />
                        </Grid>
                        <Grid item>
                            <Box>
                                <IconButton aria-label="delete" color="primary" onClick={handleSaveInterest}>
                                    <Add />
                                </IconButton>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
            {generalLoginError && <div>{generalLoginError}</div>}
            <Grid container direction="row" justify="flex-start" alignItems="baseline" spacing={2}>
                <Grid item>
                    <Button variant="contained" color="primary" type="submit" onClick={handleSave} disableElevation>
                        {t("group_details_form.button_save")}
                    </Button>
                </Grid>
                <Grid item>
                    <Button variant="contained" color="secondary" onClick={handleClickOpen} disableElevation>
                        {t("group_details_form.button_delete")}
                    </Button>
                </Grid>
            </Grid>
            <Dialog
                open={openDialog}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {group && <Typography variant="h1">{t("group_details_form.dialog_delete_title") + group.name}</Typography>}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {t("group_details_form.dialog_delete_message")}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} variant="contained" color="secondary" autoFocus disableElevation>
                        {t("group_details_form.dialog_delete_cancel")}
                    </Button>
                    <Button onClick={handleDeleteGroup} variant="contained" color="primary" disableElevation>
                        {t("group_details_form.dialog_delete_acept")}
                    </Button>
                </DialogActions>
            </Dialog>
            <Snackbar open={openSuccesSnackbar} autoHideDuration={3000} onClose={handleCloseSuccessSnackbar}>
                <Alert onClose={handleCloseSuccessSnackbar} severity="success">
                    {t("group_details_form.snackbar_success")}
                </Alert>
            </Snackbar>
            <Snackbar open={openFailureSnackbar} autoHideDuration={3000} onClose={handleCloseFailureSnackbar}>
                <Alert onClose={handleCloseFailureSnackbar} severity="error">
                    {t("group_details_form.snackbar_success")}
                </Alert>
            </Snackbar>
            <Dialog
                open={openImageDialog}
                onClose={setOpenImageDialog}
                className={classes.crop_container}
            >
                <DialogTitle >
                    <Typography variant="h1">{t("crop_dialog.title")}</Typography>
                </DialogTitle>
                <DialogContent>
                    {unCroppedLogo
                        ? <Box height="400px" width="400px" pb={5} position="relative" color="white">
                            <Cropper
                                image={unCroppedLogo.url}
                                crop={crop}
                                zoom={zoom}
                                minZoom={-10}
                                aspect={414 / 235}
                                onCropChange={setCrop}
                                onCropComplete={onCropComplete}
                                onZoomChange={setZoom}
                            />
                        </Box>
                        : <Box></Box>}
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenImageDialog(false)} variant="contained" color="secondary" autoFocus disableElevation>
                        {t("crop_dialog.cancel")}
                    </Button>
                    <Button onClick={saveCroppedImage} variant="contained" color="primary" disableElevation>
                        {t("crop_dialog.crop")}
                    </Button>
                </DialogActions>
            </Dialog>
        </form>
    );
}