import React from 'react';

import { FirebaseProvider, useFirebase } from './shared/context/firebase-context';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom'
import { Login } from './components/Login';
import { Signin } from './components/SignIn';
import { Dashboard } from './components/Dashboard';
import { PrivateRoute } from './components/PrivateRoute';
import { Loading } from './components/Loading';
import { MuiThemeProvider } from '@material-ui/core/styles'
import { CssBaseline } from '@material-ui/core'
import { theme } from './shared/theme';
import { ActivateAccount } from './components/ActivateAccount';
import { CreateAdmin } from './components/CreateAdmin';
import { AdminProvider } from './shared/context/admin-context';
import { RecoverPassword } from './components/RecoverPassword';

function App() {

  return (
    <MuiThemeProvider theme={theme}>
			<CssBaseline />
      <FirebaseProvider>
        <AdminProvider>
          <AppBase />
        </AdminProvider>
      </FirebaseProvider>
    </MuiThemeProvider>
  );
}

function AppBase() {
  const { isLoading } = useFirebase();

  if (isLoading) {
    return <Loading />
  }

  return  <Router>
  <Switch>
    <Route path="/login">
      <Login />
    </Route>
    <Route path="/recoverpassword">
      <RecoverPassword />
    </Route>
    <Route path="/register">
      <Signin />
    </Route>
    <Route path="/activate">
      <ActivateAccount />
    </Route>
    <Route path="/createAdmin">
      <CreateAdmin />
    </Route>
    <PrivateRoute path="/">
      <Dashboard />
    </PrivateRoute>
  </Switch>
</Router>
}

export default App;
