import React, { useContext, useState, useEffect } from 'react';
import { useFirebase } from '../context/firebase-context';
import { useAdmin } from './admin-context';
import { useParams } from 'react-router-dom';

const GroupContext = React.createContext(null);

export function GroupProvider({ children }) {
  const { db } = useFirebase();
  const { event, groupList } = useAdmin();
  const { groupId } = useParams();
  const [group, setGroup] = useState(null);
  const [groupParticipants, setGroupParticipants] = useState(null);

  useEffect(() => {
    if (groupId && event) {
      if (groupList) {
        var groupFound = groupList.find(group => group.id === groupId);
        if (groupFound) {
          setGroup(groupFound);
        } else {
          db.collection('groups')
            .doc(groupId)
            .get()
            .then((doc) => {
              if (doc.data().eventId === event.id) {
                const participants = {...doc.data()}
                setGroup({ id: doc.id, ...doc.data() });
                if(participants){
                  setGroupParticipants(participants);
                }
              } else {
                setGroup(null);
              }
            });
        }
      } else {
        db.collection('groups')
          .doc(groupId)
          .get()
          .then((doc) => {
            if (doc.data().eventId === event.id) {
              setGroup({ id: doc.id, ...doc.data() });
            } else {
              setGroup(null);
            }
          });
      }

    }
  }, [db, event]);

  return (
    <GroupContext.Provider
      value={{
        group,
        groupParticipants,
        setGroup
      }}
    >
      {children}
    </GroupContext.Provider>
  );
}

export function useGroup() {
  const context = useContext(GroupContext);
  return context;
}