import React, { useEffect,useState } from 'react';
import { useForm, Controller } from "react-hook-form";
import { useFirebase } from '../shared/context/firebase-context';
import { useHistory } from 'react-router-dom';
import { Box, Typography, ButtonGroup, Button, Snackbar, TextField, FormControl } from '@material-ui/core';
import { useAuth } from '../shared/hooks/useAuth';
import Divider from '@material-ui/core/Divider';
import { useTranslation } from "react-i18next";
import MuiAlert from '@material-ui/lab/Alert';
import { useAdmin } from '../shared/context/admin-context';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export function RecoverPassword() {
    const { sendRecoveryEmail } = useAuth();
    const { auth, user } = useFirebase();
    const { userDetails } = useAdmin();
    const { t, i18n } = useTranslation();
    const history = useHistory();

    useEffect(() => {
        if (user && !user.emailVerified) {
            history.push('/activate');
        } else if (user && userDetails && !userDetails.isAdmin) {
            history.push('/createAdmin');
        } else if (user && userDetails && userDetails.isAdmin) {
            history.push('/');
        }
    }, [userDetails]);

    function handleLanguage(lang) {
        i18n.changeLanguage(lang);
    }

    const { handleSubmit, control, formState: { errors: fieldsErrors } } = useForm();

    const [openSuccesSnackbar, setOpenSuccesSnackbar] = useState(false);
    const handleCloseSuccessSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
    
        setOpenSuccesSnackbar(false);
    };

    const [openFailureSnackbar, setOpenFailureSnackbar] = useState(false);
    const handleCloseFailureSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
    
        setOpenFailureSnackbar(false);
    };

    const onSubmit = data => {
      sendRecoveryEmail(data.email, { successCallback: () =>  setOpenSuccesSnackbar(true), failureCallback: (error) => setOpenFailureSnackbar(true)});
    };

    return (
        <main>
            <Box display="flex" width="100%" alignItems="center" flexDirection="column" >
                <img src="/assets/images/wemit.png" alt="wemit logo" width="120" style={{margin: "25px"}}/>
                <ButtonGroup variant="text" color="primary" aria-label="text primary button group" style={{ marginTop: '5px', right: '5px', position: 'absolute' }}>
                    <Button onClick={() => handleLanguage("es")}>
                        ES
                    </Button>
                    <Button onClick={() => handleLanguage("en")}>
                        EN
                    </Button>
                </ButtonGroup>
                <Divider width="100%"/>
            </Box>
            <Box display="flex" width="100%" alignItems="center" flexDirection="column" style={{marginTop: "40px"}}>
                <Box display="flex" alignItems="center" flexDirection="column" style={{minWidth: "200px", maxWidth: "400px"}}>
                    <Typography variant="h1" >
                        {t("recover.title")}
                    </Typography>
                    <form onSubmit={handleSubmit(onSubmit)}>
                      <Box flexDirection="comumn" style={{display: 'flex', marginTop: "10px"}}>
                          <Typography variant="body1" style={{marginRight: "5px"}}>
                              {t("recover.subtitle")}
                          </Typography>
                          <Typography variant="body1" color="primary">
                              {auth.user ? auth.user.email : ""}
                          </Typography>
                      </Box>
                      <FormControl fullWidth margin="normal">
                        <Controller
                          name="email"
                          render={({ field }) =>
                            <TextField
                              id="email"
                              labelwidth={70}
                              helperText={fieldsErrors.email ? fieldsErrors.email.message : null}
                              label={t("recover.title_email")}
                              error={!!fieldsErrors.email}
                                {...field}
                            />}
                              control={control}
                              defaultValue=""
                              rules={{
                                required: t("recover.error_field_required"),
                                pattern: {
                                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                  message: t("recover.error_field_email_not_valid")
                              }
                              }}
                        />
                      </FormControl>
                      <Box display="flex" width="100%" alignItems="center" flexDirection="column" style={{marginTop: "40px"}}>
                          <img src="/assets/images/mail.png" alt="email icon" width="200" style={{margin: "25px"}}/>
                          <Button
                            variant="contained"
                            color="primary"
                            width="200"
                            type="submit"
                            disableElevation
                            style={{maxWidth: '25em', minWidth: '25em'}}>
                              {t("recover.button_send")}
                          </Button>
                      </Box>
                    </form>
                </Box>
            </Box>
            <Snackbar open={openSuccesSnackbar} autoHideDuration={3000} onClose={handleCloseSuccessSnackbar}>
                <Alert onClose={handleCloseSuccessSnackbar} severity="success">
                    {t("recover.success_send_email")}
                </Alert>
            </Snackbar>
            <Snackbar open={openFailureSnackbar} autoHideDuration={3000} onClose={handleCloseFailureSnackbar}>
                <Alert onClose={handleCloseFailureSnackbar} severity="error">
                    {t("recover.error_send_email")}
                </Alert>
            </Snackbar>	
		</main>
    );
}