import { useAdmin } from '../context/admin-context';
import { useFirebase } from '../context/firebase-context';

export function useAuth() {
    const { auth, db, setEmailCredential, arrayUnion } = useFirebase();
    const { setUserDetails } = useAdmin();

    const login = (username, password) => {
        return auth.signInWithEmailAndPassword(username, password)
    };

    const register = (username, password, name, surname, marketing, language, { successCallback, failureCallback }) => {
        auth.createUserWithEmailAndPassword(username, password).then((userCredential) => {
            var user = userCredential.user;
            user.sendEmailVerification();
            addUser(user.uid, username, name, surname, marketing, language, {successCallback: successCallback, failureCallback: failureCallback})
        })
        .catch((error) => {
            failureCallback(error.code);
        });
    };

    const addUser = (uid, username, name, surname, marketing, language, { successCallback, failureCallback }) => {
        const user = {
            name: name,
            surname: surname,
            email: username,
            marketingEnabled: marketing,
            topicsConfigured: "configurationState.FALSE",
            userDataConfigured: "configurationState.FALSE",
            defaultEvent: "",
            notification: {
                token: '',
                language: language,
                pause_all_notifications: false,
                new_session_notifications: true,
                remind_session_notifications: true,
                new_event_notifications: true
            },
        };

        db.collection('users')
        .doc(uid)
        .set(
            user
        )
        .then(function() {
            setUserDetails(user);
            addNotification(uid, username, {successCallback: successCallback, failureCallback: failureCallback});
        })
        .catch((error) => {
            failureCallback(error);
        });
    };

    const addNotification = (uid, email, { successCallback, failureCallback }) => {
        db.collection('users')
        .doc(uid)
        .collection('notifications')
        .doc('notification')
        .set(
            {notificationEvents: []}
        )
        .then(function() {
            successCallback();
            joinDefaultEvent(uid);
            joinInvitedEvents(email, uid);
        })
        .catch((error) => {
            failureCallback(error);
        });
    };

    const joinDefaultEvent = (uid) => {
        db.collection('events')
        .where('tag', '==', 'wemit_general')
        .limit(1)
        .get()
        .then(function(doc) {
            doc.docs.forEach(element => {
                element.ref.update({participants: arrayUnion(uid)});
            });
        });
    };

    const joinInvitedEvents = (email, uid) => {
        db.collection('invitations')
        .doc(email)
        .get()
        .then(function(doc) {
            if (doc.exists) {
                doc.data().eventList.forEach(element => {
                    db.collection('events')
                    .doc(element)
                    .get()
                    .then(function(doc) {
                        doc.ref.update({participants: arrayUnion(uid)});
                    });
                });
            }
        });
    };

    const updateEmailVerified = (uid) => {
        db.collection('users')
        .doc(uid)
        .update(
            {emailVerified: true}
        );
    };

    const updatePassword = (oldPassword, newPassword, { successCallback }) => {
        var user = auth.currentUser;
        const credential = setEmailCredential(user.email, oldPassword);
        user.reauthenticateWithCredential(credential).then(() => {
            user.updatePassword(newPassword).then(function () {
                successCallback(true);
            }).catch(function (error) {
                successCallback(false);
            });
        }).catch(function (error) {
            successCallback(false);
        });

    };

    const sendEmail = ({ successCallback, failureCallback }) => {
        var user = auth.currentUser;
        user.sendEmailVerification().then(() => {
            successCallback();
        }).catch(function (error) {
            failureCallback(error);
        });
    };

    const sendRecoveryEmail = (email, { successCallback, failureCallback }) => {
        auth.sendPasswordResetEmail(email).then(() => {
            successCallback();
        }).catch(function (error) {
            failureCallback(error);
        });
    };

    return {
        updatePassword,
        login,
        register,
        sendEmail,
        sendRecoveryEmail,
        updateEmailVerified
    };
}