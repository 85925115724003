import { createMuiTheme } from '@material-ui/core/styles';

export const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#FF5851',
    },
    secondary: {
      main: '#F2EFEA',
    },
  },
  typography: {
    fontFamily: ['Montserrat', 'sans-serif'].join(','),
    h1: {
      fontWeight: 600,
      fontSize: 22,
      color: '#FF5851',
    },
    h2: {
      fontWeight: 600,
      fontSize: 18,
      color: '#595959',
    }, body2: {
      fontSize: 14,
    },
    h3: {
      fontWeight: 500,
      fontSize: 16,
      color: '#B1B0B0',
    },
    h4: {
      fontWeight: 400,
      fontSize: 14,
      color: '#B1B0B0',
    },
    h6: {
      fontWeight: 700,
      fontSize: 15,
      color: '#111111',
    }
  },
  overrides: {
    // MuiExpansionPanel: {
    //   root: {
    //     boxShadow: 'none',
    //     borderBottom: `1px solid ${LIGHT_GREY}`,
    //     borderRadius: 0,
    //   },
    // },
    // MuiExpansionPanelSummary: {
    //   root: {
    //     padding: 0,
    //     color: VERY_DARK_GREY,
    //     '&$expanded': {
    //       minHeight: '0 !important',
    //       margin: 0,
    //     },
    //   },
    //   expandIcon: {
    //     marginRight: '-4px',
    //     fill: VERY_DARK_GREY,
    //   },
    //   content: {
    //     margin: '0 !important',
    //     padding: '16px',
    //     paddingRight: '70px',
    //   },
    // },
    // MuiExpansionPanelDetails: {
    //   root: {
    //     color: VERY_DARK_GREY,
    //     padding: '16px',
    //     fontSize: '16px',
    //     fontWeight: 500,
    //     lineHeight: 1.42857143,
    //   },
    // },
    // MuiSelect: {
    //   select: {
    //     '&:focus': {
    //       backgroundColor: 'transparent',
    //     },
    //   },
    // },
    // MuiSwitch: {
    //   switchBase: {
    //     '&$checked': {
    //       '&+ $track': {
    //         opacity: 1,
    //       },
    //     },
    //   },
    // },
    MuiButton: {
      root: {
        borderRadius: '20px',
      },
    },
  },
});