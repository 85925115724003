import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { useTranslation } from "react-i18next";

export function Terms() {
  const { i18n } = useTranslation();

  if (i18n.language === 'en') {
    return (
      <main>
        <Box>
          <Typography variant="body1">These terms of use regulate the rules governing the use of the WEMIT app that users can download from the platforms 
              provided for this purpose. The download or use of the APP confers the condition of User to whoever does so and implies 
              the acceptance of all the conditions included in this document and in the Privacy Policy. The User should read these 
              conditions every time he/she uses the APP, as they may be modified in the future.
          </Typography>
          <br/>
          <Typography variant="h2">1.- What is WEMIT?.</Typography>
          <Typography variant="body1">Wemit is the virtual networking application.

              It is no longer necessary to attend events physically to expand your circle of contacts.

              It has been developed and is owned by KELEA EUROPEAN CENTER, S.L, (KELEA) whose details are set out below.
              following:

              Address: POLÍGONO DE POCOMACO, PRIMERA AVENIDA C10, EDIFICIO ABANCA INNOVA 3ª PLANTA (SALA CURIE) - 15190
              A CORUÑA (A CORUÑA)

              Contact e-mail address: kelea@kelea.es.

              Tax ID Number: B70556188

              Registered in the Mercantile Registry of A Coruña, volume 3652, page 210, sheet C-57556.

              Wemit is an application that allows users to contact each other. It is designed so that users attending
              virtual events can connect with other users with similar interests.

              In these conditions we explain its use and conditions.
          </Typography>
          <br/>
          <Typography variant="h2">2.- User registration: Access and Registration:</Typography>
          <Typography variant="body1">If you want to become a user of Wemit you will have to register with a few simple steps through the app.

              You will find a registration form in which you will enter your email, name and surname and a password
              to access the Wemit ecosystem. This password will be your responsibility.

              You will have to accept the present conditions and our privacy policy.

              When you accept these terms and conditions, you enter into a contract with KELEA (a license of use) through which
              KELEA offers you this professional networking application that you can use whenever you wish and enter the information you consider appropiate.
            
              We will also ask for your consent to send you marketing information.

              We will also ask for your consent to send you marketing information. We will never send you
              commercial information without your consent.

              Once registered and through a few simple forms you will be able to configure your professional preferences and interests in order to make the
              interaction with other users as effective as possible.

              Once registered you will be able to access whenever you want with your email and password.

              Registration and use of the WEMIT app are free of charge.

              Registration conditions:

          </Typography>
          <ul>
              <li>Registration may not be accessed by minors under 18 years of age.</li>
              <li>The user will provide correct, accurate, truthful, not misleading information and is responsible for all 
                  information about himself/herself that he/she enters in the application including profiles in social networks.</li>
          </ul>
          <br/>
          <Typography variant="h2">3.- Privacy Policy</Typography>
          <Typography variant="body1">
              We are scrupulous with the user's personal information. The user will enter as much information as he/she wants.
              The more complete your profile is, the easier it will be to get in contact with other users with common interests.

              For more information about the treatment of your personal data please consult our PRIVACY POLICY.
          </Typography>
          <br/>
          <Typography variant="h2">4.- Responsibility and obligations</Typography>
          <Typography variant="body">
              The use of WEMIT for purposes other than those for which this app is intended is prohibited. 
              The user is responsible for its use and for the information entered in it, as well as for the password, which will be personal and non-transferable.

              It is forbidden to use the application for illegal purposes and / or contrary to law, morality and public order.
              If any conduct contrary to these conditions is detected, the user account will be deleted.

              The User shall be liable to KELEA and to third parties for any damages that may be caused by breach of these obligations.

              It is the User's responsibility, in any case, to have adequate tools for the detection and elimination of malicious programs or any other harmful computer element. 
              KELEA shall not be liable for any damage caused to computer equipment during the use of the APP. Likewise, KELEA shall not be liable for damages caused to Users 
              when such damages are caused by failures or disconnections in telecommunications networks that interrupt the service.

          </Typography>
          <br/><br/>
          <Typography variant="h2">5.- Intellectual Property</Typography>
          <Typography variant="body1">
              KELEA is the owner of WEMIT and the owner of its content, understanding as such the operation of the application, its design and functionalities.

              The user is the owner and responsible for their personal data and personal information entered in the app.

              All trademarks, designs, logos, text, images, illustrations, photographs, videos, graphics, software, source code, etc. 
              are the property of KELEA or have been legally acquired by KELEA from their owners. Their use outside the app is prohibited 
              without the express written authorization of KELEA.

              The use of the app implies the granting of a limited, temporary, revocable, non-exclusive and national license to use it 
              under the terms contemplated in these conditions, that is to say, for the operation of the app to contact other users.

              This license is also granted under the same terms with respect to updates and improvements made to the application. 
              Said user licenses may be revoked by KELEA unilaterally at any time, by simply notifying the User.
          </Typography>
          <br/>
          <Typography variant="h2">6.- Miscellaneous</Typography>
          <Typography variant="body1">
              For any dispute related to the application, the consumer and user regulations and the Spanish legal system shall be applicable. 
              In those cases in which the forum can be chosen, the courts and tribunals of A Coruña will be competent.

              These conditions may be updated. Relevant modifications will be communicated in the app itself to the users.

              December 2020
          </Typography>
        </Box>
      </main>
    );
  } else {
    return(
      <main>
        <Box>
        <Typography variant="body1">Las presentes condiciones de uso regulan las reglas a las que se somete el uso de la app WEMIT que los
            usuarios podrán descargarse desde las plataformas habilitadas a tales efectos. La descarga o utilización de
            la APP atribuye la condición de Usuario a quien lo haga e implica la aceptación de todas las condiciones
            incluidas en este documento y en la Política de Privacidad. El Usuario debería leer estas condiciones cada
            vez que utilice la APP, ya que podrían ser modificadas en lo sucesivo.
        </Typography>
        <br/>
        <Typography variant="h2">1.- ¿Qué es WEMIT?</Typography>
        <Typography variant="body1">Wemit es la aplicación de networking virtual.

            Ya no es necesario asistir a los eventos físicamente para ampliar su círculo de contactos.

            Ha sido desarrollada y es propiedad de KELEA EUROPEAN CENTER, S.L, (KELEA) cuyos detalles se exponen a
            continuación:

            Domicilio: POLÍGONO DE POCOMACO, PRIMERA AVENIDA C10, EDIFICIO ABANCA INNOVA 3ª PLANTA (SALA CURIE) – 15190
            A CORUÑA (A CORUÑA)

            Correo electrónico de contacto: kelea@kelea.es.

            NIF: B70556188

            Inscripción en el Registro Mercantil de A Coruña, al tomo 3652, folio 210, hoja C-57556.

            Wemit es una aplicación que permite poner en contacto a sus usuarios entre sí. Está diseñada para que los
            usuarios asistentes a eventos virtuales puedan conectar con otros usuarios con intereses similares.

            En estas condiciones le explicamos su uso y condiciones
        </Typography>
        <br/>
        <Typography variant="h2">2.- Alta como usuario: Acceso y Registro:</Typography>
        <Typography variant="body1">Si quiere ser usuario de Wemit tendrá que registrarse con unos sencillos pasos a través de la app.

            Encontrará un formulario de registro en el que introducirá su email, nombre y apellidos y una contraseña
            para
            acceder al ecosistema Wemit. Esta contraseña será de su responsabilidad.

            Tendrá que aceptar las presentes condiciones y nuestra política de privacidad.

            Cuando Vd. acepta estas condiciones suscribe un contrato con KELEA (una licencia de uso) mediante el cual
            KELEA
            le ofrece esta aplicación de networking profesional que Vd. podrá utilizar cuando lo desee e introducir la
            información que considere oportuna.

            También le solicitaremos el consentimiento para enviarle información de marketing. Nunca le enviaremos
            información comercial sin su consentimiento.

            Una vez registrado y a través de unos sencillos formularios podrá configurar sus preferencias profesionales
            y de
            intereses a fin de que la interactuación con otros usuarios sea lo más eficaz posible.

            Una vez registrado podrá acceder cuando lo desee con su correo electrónico y su contraseña.

            El registro y uso de la app WEMIT son gratuitos.

            Condiciones de registro:

        </Typography>
        
        <ul>
            <li>No podrán acceder al registro menores de 18 años.</li>
            <li>El usuario facilitará información correcta, exacta, verdad, no engañosa y se hace responsable de toda la
                información sobre su persona que introduzca en la aplicación incluyendo perfiles en redes sociales.</li>
        </ul>
        <br/>
        <Typography variant="h2">3.- Privacidad</Typography>
        <Typography variant="body1">
            Somos escrupulosos con la información personal de los usuarios. El usuario introducirá la información que
            desee. Cuanto más completo sea su perfil, más sencillo será entrar en contacto con otros usuarios con
            intereses comunes.

            Para más información sobre el tratamiento de sus datos personales consulte nuestra POLÍTICA DE PRIVACIDAD.
        </Typography>
        <br/>
        <Typography variant="h2">4.- Responsabilidad y obligaciones</Typography>
        <Typography variant="body1">
            Queda prohibido el uso de WEMIT para fines distintos a los que está destinada esta app. El usuario se hace
            responsable de su uso y de la información introducida en la misma así como de la contraseña que será
            personal e intransferible.

            Queda prohibido el uso de la aplicación con fines ilícitos y/o contrarios a la ley, la moral y el orden
            público. Si se detecta una conducta contraria a las presentes condiciones la cuenta de usuario será
            eliminada.

            El Usuario responderá frente a KELEA y frente a terceros de cualesquiera daños o perjuicios que pudieran
            causarse por incumplimiento de estas obligaciones.

            Corresponde al Usuario, en todo caso, disponer de herramientas adecuadas para la detección y eliminación de
            programas maliciosos o cualquier otro elemento informático dañino. KELEA no se responsabiliza de los daños
            producidos a equipos informáticos durante el uso de la APP. Igualmente, KELEA no será responsable de los
            daños producidos a los Usuarios cuando dichos daños tengan su origen en fallos o desconexiones en las redes
            de telecomunicaciones que interrumpan el servicio.
        </Typography>
        <br/>
        <Typography variant="h2">5.- Propiedad intelectual</Typography>
        <Typography variant="body1">
            KELEA es titular de WEMIT y propietaria de su contenido entendiendo como tal el funcionamiento de la
            aplicación, su diseño y funcionalidades.

            El usuario es propietario y responsable de sus datos personales y de la información personal que introduzca
            en la app.

            Todas las marcas, diseños, logotipos, textos, imágenes, ilustraciones, fotografías, videos, gráficos,
            software, código fuente etc son propiedad de KELEA o han sido adquiridos legalmente por KELEA de sus
            titulares. Queda prohibida su utilización fuera de la app sin la autorización expresa y por escrito de
            KELEA.

            El uso de la app implica el otorgamiento de una licencia de uso limitada, temporal, revocable y no exclusiva
            y con ámbito nacional para su utilización en los términos contemplados en estas condiciones, es decir para
            el funcionamiento de la app para contactar con otros
            usuarios.

            Dicha licencia se concede también en los mismos términos con respecto a las actualizaciones y mejoras que se
            realizasen en la aplicación. Dichas licencias de uso podrán ser revocadas por KELEA unilateralmente en
            cualquier momento, mediante la mera notificación al Usuario.
        </Typography>
        <br/>
        <Typography variant="h2">6.- Varios</Typography>
        <Typography variant="body1">
            Para cualquier disputa relacionada con la aplicación será aplicable la normativa de consumidores y usuarios
            y el ordenamiento jurídico español. En aquellos casos en los que pueda elegirse el foro, serán competentes
            los juzgados y tribunales de A Coruña.

            Las presentes condiciones podrán ser actualizadas. Las modificaciones relevantes serán comunicadas en la
            propia app a los usuarios.

            Diciembre 2020
        </Typography>
      </Box>
    </main>
    );
  }
}