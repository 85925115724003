import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import Route from 'route-parser';
import { makeStyles } from '@material-ui/core/styles';
import { useAdmin } from '../shared/context/admin-context';
import { useGroups } from '../shared/hooks/useGroups';

const isFunction = value => typeof value === 'function';

const getPathTokens = pathname => {
  const paths = ['/'];

  if (pathname === '/') return paths;

  pathname.split('/').reduce((prev, curr) => {
    const currPath = `${prev}/${curr}`;
    if (currPath !== '/grupos') {
      paths.push(currPath);
    }
    return currPath;
  });

  return paths;
};

function getRouteMatch(routes, path) {
  return Object.keys(routes)
    .map(key => {
      const params = new Route(key).match(path);
      return {
        didMatch: params !== false,
        params,
        key
      };
    })
    .filter(item => item.didMatch)[0];
}

function getGroupName(groupList, groupId) {
  if (!groupList.length) {
    return null;
  } else {
    return groupList.filter(item => item.id === groupId)[0];
  }
  
}

function getBreadcrumbs({ routes, match, location, eventName, groupList }) {
  const pathTokens = getPathTokens(location.pathname);
  return pathTokens.map((path, i) => {
    const routeMatch = getRouteMatch(routes, path);
    const routeValue = routes[routeMatch.key];
    var name = isFunction(routeValue)
      ? routeValue(routeMatch.params)
      : routeValue;
    if (path === '/') {
      name = eventName
    } else if (path.match("^/grupos/") && !path.match("addSession$") && !path.match("editSession$")) {
      const group = getGroupName(groupList, name);
      name = '';
      if (group) {
        name = group.name;
      }
    }
    return { name, path };
  });
}

function Breadcrumbs({ routes, match, location }) {
  const { event } = useAdmin();
  const { groupList } = useGroups();
  var eventName = event ? event.name : '';
  const breadcrumbs = getBreadcrumbs({ routes, match, location, eventName, groupList });
  const classes = useStyles();

  if (location.pathname === '/usuarios' || location.pathname === '/ajustes' || location.pathname === '/sesiones') {
    return(null);
  } else {
    return (
      <div className={classes.breadcrumbContainer}>
        {' '}
        {breadcrumbs.map((breadcrumb, i) =>
          <span key={breadcrumb.path} style={{ fontFamily: 'Montserrat', fontSize: '16px', color: '#969696' , fontWeight: 'bold', marginRight: '5px'}}>
            <Link to={breadcrumb.path} style={{ textDecoration: 'none', fontFamily: 'Montserrat', fontSize: '16px', color: i === (breadcrumbs.length - 1) ? '#474747' : '#969696', fontWeight: 'bold', marginRight: '5px'}}>
              {breadcrumb.name}
            </Link>
            {i < breadcrumbs.length - 1 ? ' > ' : ''}
          </span>
        )}
      </div>
    );
  }
}

const useStyles = makeStyles({
    breadcrumbContainer: {
        position: 'fixed',
        width: '100%',
        background: '#f2efea',
        zIndex: '1000',
        height: '50px',
        display: 'flex',
        alignItems: 'center',
        paddingLeft: '23px',
    }
});

export default withRouter(Breadcrumbs);