import { useState, useEffect } from 'react';
import { useFirebase } from '../context/firebase-context';
import { useTranslation } from "react-i18next";

export function useAddSession() {
  const [session] = useState();
  const { i18n } = useTranslation();
  const { db, functions } = useFirebase();
  const [icebreakers, setIcebreakers] = useState([]);

  const addSession = (session, event, speech, userId, groupId, group, isSessionToEdit, { successCallback }) => {
    const { id, ...auxSession } = session;
    auxSession.type = "speedNetworking";
    auxSession.state = "active";
    auxSession.eventName = event.name;
    auxSession.eventId = event.id;
    auxSession.groupId = groupId;
    auxSession.groupName = group.name;
    auxSession.participants = [];
    auxSession.duration = Math.ceil((auxSession.rounds * auxSession.roomDuration) + (0.5 * auxSession.rounds));
    if (speech) {
      auxSession.speechUid = userId;
    }

    if(isSessionToEdit){
      db.collection('sessions')
      .doc(isSessionToEdit.id)
      .update({
        recurrenceDates: session.recurrenceDates,
          description: session.description,
          duration: auxSession.duration,
          icebreakers: session.icebreakers,
          maxParticipants: session.maxParticipants,
          name: session.name,
          personsOnRoom: session.personsOnRoom,
          roomDuration: session.roomDuration,
          rounds: session.rounds,
          speechUid: (speech) ? auxSession.speechUid : null,
          startDate: session.startDate
        }).then(function (){
          successCallback(true);
        }).catch(err => {
          successCallback(false);
        });
    } else {
      db.collection('sessions').add(auxSession)
      .then(function () {
        db.collection('groups').doc(auxSession.groupId).update({
          'updatedDate': new Date()
        });
        sendPushNotification(group, event.id);
        successCallback(true);
      })
      .catch(err => {
        successCallback(false);
      });
    }
  };

  const sendPushNotification = (group, eventId) => {
    var sendNotification = functions.httpsCallable('sendNewSessionCreatedNotification');
    sendNotification({ 'eventId': eventId, 'group': group })
      .then((result) => {
      });
  }

  useEffect(() => {
    db.collection('defaulttopics')
      .doc('icebreakers')
      .get()
      .then(function (doc) {
        const g = {...doc.data()};
        if(i18n.language === 'es'){
          setIcebreakers(g.es);
        } else {
          setIcebreakers(g.en);
        }
      });
  }, [db])

  return {
    session,
    addSession,
    sendPushNotification,
    icebreakers
  }

}