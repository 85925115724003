import React, { useState, useCallback } from 'react';
import { useForm, Controller } from "react-hook-form";
import { useHistory } from 'react-router-dom';
import { TextField, FormControl, Button, Box, Typography, Paper, Chip, Grid, IconButton, Divider, Dialog, DialogTitle, DialogContent, DialogActions, Switch } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from "react-i18next";
import { useAddGroup } from '../shared/hooks/useAddGroup';
import Add from '@material-ui/icons/Add';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import Cropper from 'react-easy-crop'
import { getCroppedImg } from '../shared/utils/cropImage'
import { InviteSubscribersDialog } from './InviteSubscribersDialog';
import { useUsers } from '../shared/hooks/useUsers';
import { useAdmin } from '../shared/context/admin-context';



function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'left',
        overflow: 'hidden',
        backgroundColor: theme.palette.background.paper,
        margin: '30px',
        width: '100%'
    },
    root: {
        display: 'flex',
        justifyContent: 'left',
        flexWrap: 'wrap',
        listStyle: 'none',
        padding: theme.spacing(0.5),
        margin: 0,
    },
    img: {
        border: '1px solid #FF5851',
        padding: '5px',
        width: '414px',
        height: '235px',
    },
    imgSmall: {
        border: '1px solid #FF5851',
        padding: '55px',
    },
    input: {
        display: 'none',
    },
    chip: {
        marginRight: 10,
        fontSize: 14,
        fontWeight: 400,
        color: "#333333",
        deleteIconColor: 'red'
    },
}));

export function AddGroup() {
    const classes = useStyles();
    const { t } = useTranslation();
    const history = useHistory();
    const { saveGroup } = useAddGroup();
    const [unCroppedLogo, setUncroppedLogo] = useState();
    const [croppedLogoImage, setCroppedLogoImage] = useState();
    const [openDialog, setOpenDialog] = useState(false);
    const [openCropDialog, setOpenCropDialog] = useState(false);
    const [openSubscribersDialog, setOpenSubscribersDialog] = useState(false);
    const [crop, setCrop] = useState({ x: 0, y: 0 })
    const [zoom, setZoom] = useState(1)
    const [croppedAreaPixels, setcroppedAreaPixels] = useState()
    const [privateGroup, setPrivateGroup] = useState(false);
    const { sendPrivateGroupNotification } = useUsers();
    const { userDetails } = useAdmin();


    const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
        setcroppedAreaPixels(croppedAreaPixels);
    }, [])

    const handleLogoCrop = (event) => {
        if(event.target.files.length > 0){
            setUncroppedLogo({ url: URL.createObjectURL(event.target.files[0]), file: event.target.files[0] });
            setOpenCropDialog(true);
        }
    }

    const saveCroppedImage = async () => {
        const croppedImage = await getCroppedImg(
            unCroppedLogo.url, croppedAreaPixels, 414, 235
        );
        setPhoto(croppedImage);
        var img = URL.createObjectURL(croppedImage);
        setCroppedLogoImage(img);
        setOpenCropDialog(false);
    }

    const [openFailureSnackbar, setOpenFailureSnackbar] = useState(false);
    const handleCloseFailureSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenFailureSnackbar(false);
    };

    const [topics, setTopics] = useState([]);
    const handleDeleteInterest = (chipToDelete) => () => {
        let topicsAux = topics.slice();
        topicsAux = topicsAux.filter((chip) => chip !== chipToDelete);
        setTopics(topicsAux);
    };

    const [photo, setPhoto] = useState();

    const [interest, setInterest] = useState('');
    const handleInterestChange = (event) => {
        setInterest(event.target.value);
    };
    const handleSaveInterest = (data) => {
        if (interest !== '') {
            let topicsAux = topics.slice();
            topicsAux.push(interest);
            setTopics(topicsAux);
            setInterest('');
        }
    };

    const [generalLoginError, setGeneralLoginError] = useState('');
    const { handleSubmit, control, formState: { errors: fieldsErrors } } = useForm();

    const [draft, setDraft] = useState(false);
    const handleSave = () => {
        setDraft(false);
    };

    const handleDraft = () => {
        setDraft(true);
    };

    const handlePrivateGroup = (event) => {
        setPrivateGroup(event.target.checked);
    };

    const [newGroupData, setNewGroupData] = useState();
    const onSubmit = (data) => {
        if (privateGroup) {
            setNewGroupData({
                name: data.groupName,
                groupDescription: data.groupDescription,
                privateGroup: privateGroup,
                topics: topics,
                draft: draft,
                imageUrl: photo
            })
            setOpenSubscribersDialog(true);
        } else {
            saveGroup(
                data.groupName,
                data.groupDescription,
                undefined,
                privateGroup,
                topics,
                draft,
                photo,
                {
                    successCallback: (success) => {
                        success
                            ? history.push("/")
                            : setOpenFailureSnackbar(true)
                    }
                });
        } 
    };

    const inviteDialogCloseCallback = (userList) => {
        saveGroup(
            newGroupData.name,
            newGroupData.groupDescription,
            userList,
            newGroupData.privateGroup,
            newGroupData.topics,
            newGroupData.draft,
            newGroupData.imageUrl,
            {
                successCallback: (success, imageUrl) => {
                    success
                        ? sendNotifications(userList, imageUrl)
                        : setOpenFailureSnackbar(true)
                }
            });
    };

    function sendNotifications(userList, imageUrl){
        newGroupData.imageUrl = imageUrl
        sendPrivateGroupNotification(userList, newGroupData, { successCallback: (success) => { success ? history.push("/") : setOpenFailureSnackbar(true) } });
    }
    

    return (
        <div className={classes.container}>
            <style>{'body { background-color: #f2efea; }'}</style>
            <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }} >
                <Box p={3}>
                    <Box my={2}>
                        <Typography variant="h1">
                            {t("new_group_form.title")}
                        </Typography>
                    </Box>
                    <Box my={2} width="50%">
                        <Typography variant="h3">
                            {t("new_group_form.description")}
                        </Typography>
                    </Box>
                    <Box py={2}><Divider width="100%" /></Box>
                    <Box my={2}>
                        <Typography variant="h1">
                            {t("new_group_form.title_details")}
                        </Typography>
                    </Box>
                    <Box my={2} width="50%">
                        <FormControl fullWidth className={classes.margin} variant="outlined">
                            <Controller
                                name="groupName"
                                render={({ field }) =>
                                    <TextField
                                        id="groupName"
                                        labelwidth={40}
                                        helperText={fieldsErrors.groupName ? fieldsErrors.groupName.message : null}
                                        variant="outlined"
                                        label={t("new_group_form.title_name")}
                                        error={!!fieldsErrors.groupName}
                                        {...field}
                                    />}
                                control={control}
                                defaultValue=""
                                rules={{
                                    required: 'Required'
                                }}
                            />
                        </FormControl>
                    </Box>
                    <Box my={4} width="50%">
                        <FormControl fullWidth className={classes.margin} variant="outlined">
                            <Controller
                                name="groupDescription"
                                render={({ field }) =>
                                    <TextField
                                        id="groupDescription"
                                        labelwidth={70}
                                        helperText={fieldsErrors.groupDescription ? fieldsErrors.groupDescription.message : null}
                                        variant="outlined"
                                        label={t("new_group_form.title_description")}
                                        multiline
                                        error={!!fieldsErrors.groupDescription}
                                        {...field}
                                    />}
                                control={control}
                                defaultValue=""
                                rules={{
                                    required: 'Required'
                                }}
                            />
                        </FormControl>
                    </Box>
                    <Box my={4} width="50%">
                        <FormControl fullWidth className={classes.margin} variant="outlined">
                            <Grid
                                container
                                alignItems="center">
                                <Switch
                                    checked={privateGroup}
                                    onChange={handlePrivateGroup}
                                    color="primary"
                                />
                                <Typography variant="p">{t("new_group_form.title_private")}</Typography>
                            </Grid>
                        </FormControl>
                    </Box>
                    <Box my={2}>
                        <Typography variant="h2">
                            {t("new_group_form.title_image")}
                        </Typography>
                    </Box>
                    <Box my={2}>
                        <Grid container alignItems="left" spacing={2} direction="column">
                            <Grid item >
                                {photo
                                    ? <img src={(croppedLogoImage !== "") ? croppedLogoImage : "/assets/images/event_logo_placeholder_grey.png"} alt='Group avata' className={classes.img} />
                                    : <img src="/assets/images/event_logo_placeholder_grey.png" alt='event logo' className={classes.imgSmall} />}
                                <input accept="image/*" className={classes.input} id="contained-button-file" multiple type="file" onChange={handleLogoCrop} />
                            </Grid>
                            <Grid item justify="center">
                                <Box mt={2}>
                                    <label htmlFor="contained-button-file">
                                        <Button variant="contained" disableElevation color="primary" component="span">
                                            {t("new_group_form.button_upload_image")}
                                        </Button>
                                    </label>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                    <Box mt={4}>
                        <Typography variant="h2">
                            {t("new_group_form.title_interests")}
                        </Typography>
                    </Box>
                    <Box mb={4} mt={2}>
                        <Grid container direction="row" justify="flex-start" alignItems="baseline">
                            <Paper elevation={0} component="ul" className={classes.root}>
                                {topics.map((data, index) => {
                                    return (
                                        <li key={index}>
                                            <Chip label={data} onDelete={handleDeleteInterest(data)} color="secondary" className={classes.chip} />
                                        </li>
                                    );
                                })}
                            </Paper>
                        </Grid>
                        <Box mt={4}>
                            <Grid container direction="row" justify="flex-start" alignItems="center" spacing={2}>
                                <Grid item>
                                    <TextField id="interest" label={t("group_details_form.add_interest")} variant="outlined" value={interest} onChange={handleInterestChange} />
                                </Grid>
                                <Grid item>
                                    <Box>
                                        <IconButton aria-label="delete" color="primary" onClick={handleSaveInterest}>
                                            <Add />
                                        </IconButton>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                    {generalLoginError && <div>{generalLoginError}</div>}
                    <Grid container direction="row" justify="flex-start" alignItems="baseline" spacing={2}>
                        <Grid item>
                            <Button variant="contained" color="secondary" disableElevation type="submit" onClick={handleDraft}>
                                {t("new_group_form.button_draft")}
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button variant="contained" color="primary" disableElevation type="submit" onClick={handleSave}>
                                {t("new_group_form.button_save")}
                            </Button>
                        </Grid>
                    </Grid>
                    <Snackbar open={openFailureSnackbar} autoHideDuration={3000} onClose={handleCloseFailureSnackbar}>
                        <Alert onClose={handleCloseFailureSnackbar} severity="error">
                            {t("new_group_form.snackbar_failuer")}
                        </Alert>
                    </Snackbar>
                    <Dialog
                        open={openCropDialog}
                        onClose={setOpenCropDialog}
                        className={classes.crop_container}
                    >
                        <DialogTitle >
                            <Typography variant="h1">{t("crop_dialog.title")}</Typography>
                        </DialogTitle>
                        <DialogContent>
                            {unCroppedLogo
                                ? <Box height="400px" width="400px" pb={5} position="relative" color="white">
                                    <Cropper
                                        image={unCroppedLogo.url}
                                        crop={crop}
                                        zoom={zoom}
                                        minZoom={-10}
                                        aspect={414 / 235}
                                        onCropChange={setCrop}
                                        onCropComplete={onCropComplete}
                                        onZoomChange={setZoom}
                                    />
                                </Box>
                                : <Box></Box>}
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => setOpenCropDialog(false)} variant="contained" color="secondary" autoFocus disableElevation>
                                {t("crop_dialog.cancel")}

                            </Button>
                            <Button onClick={saveCroppedImage} variant="contained" color="primary" disableElevation>
                                {t("crop_dialog.crop")}
                            </Button>
                        </DialogActions>
                    </Dialog>
                    <InviteSubscribersDialog
                        openDialog={openSubscribersDialog}
                        setOpenDialog={setOpenSubscribersDialog}
                        isNewGroup={true}
                        closeCallback={inviteDialogCloseCallback}
                        adminUser={userDetails}
                        group={newGroupData}
                    >
                    </InviteSubscribersDialog>
                </Box>
            </form>
        </div>
    );
}