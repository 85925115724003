import React, { useEffect,useState } from 'react';
import { useFirebase } from '../shared/context/firebase-context';
import { useHistory } from 'react-router-dom';
import { Box, Typography, ButtonGroup, Button, Snackbar } from '@material-ui/core';
import { useAuth } from '../shared/hooks/useAuth';
import Divider from '@material-ui/core/Divider';
import { useTranslation } from "react-i18next";
import MuiAlert from '@material-ui/lab/Alert';
import { useAdmin } from '../shared/context/admin-context';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export function ActivateAccount() {
    const { sendEmail, updateEmailVerified } = useAuth();
    const { auth, user } = useFirebase();
    const { userDetails } = useAdmin();
    const { t, i18n } = useTranslation();
    const history = useHistory();

    useEffect(() => {
        if (!user) {
            history.push('/login');
        } else if (user && user.emailVerified && userDetails && !userDetails.isAdmin) { 
            history.push('/createAdmin');
        } else if (user && userDetails && userDetails.isAdmin) {
            history.push('/');
        }
    }, [userDetails]);

    useEffect(() => {
        const interval = setInterval(() => {
            auth.currentUser.reload().then(function() {
                var user = auth.currentUser;
                if (user.emailVerified) {
                    updateEmailVerified(user.uid);
                    history.push('/createAdmin');
                }
            });
        }, 5000);

        return () => clearInterval(interval);
    }, []);

    function handleLanguage(lang) {
        i18n.changeLanguage(lang);
    }

    const [openSuccesSnackbar, setOpenSuccesSnackbar] = useState(false);
    const handleCloseSuccessSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
    
        setOpenSuccesSnackbar(false);
    };

    const [openFailureSnackbar, setOpenFailureSnackbar] = useState(false);
    const handleCloseFailureSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
    
        setOpenFailureSnackbar(false);
    };

    const handleReSend = () => {
        sendEmail({ successCallback: () =>  setOpenSuccesSnackbar(true), failureCallback: (error) => setOpenFailureSnackbar(true)});
    };

    const handleLogOut = () => {
        auth.signOut().then(function () {
            history.push('/login');
        });
    }

    return (
        <main>
            <Box display="flex" width="100%" alignItems="center" flexDirection="column" >
                <img src="/assets/images/wemit.png" alt="wemit logo" width="120" style={{margin: "25px"}}/>
                <ButtonGroup variant="text" color="primary" aria-label="text primary button group" style={{ marginTop: '5px', right: '5px', position: 'absolute' }}>
                    <Button onClick={() => handleLanguage("es")}>
                        ES
                    </Button>
                    <Button onClick={() => handleLanguage("en")}>
                        EN
                    </Button>
                </ButtonGroup>
                <Divider width="100%"/>
            </Box>
            <Box display="flex" width="100%" alignItems="center" flexDirection="column" style={{marginTop: "40px"}}>
                <Box display="flex" alignItems="center" flexDirection="column" style={{minWidth: "200px", maxWidth: "400px"}}>
                    <Typography variant="h1" >
                        {t("activateEmail.title")}
                    </Typography>
                    <Box flexDirection="comumn" style={{display: 'flex', marginTop: "10px"}}>
                        <Typography variant="body1" style={{marginRight: "5px"}}>
                            {t("activateEmail.subtitle")}
                        </Typography>
                        <Typography variant="body1" color="primary">
                            {auth.user ? auth.user.email : ""}
                        </Typography>
                    </Box>
                    <Box display="flex" width="100%" alignItems="center" flexDirection="column" style={{marginTop: "40px"}}>
                        <img src="/assets/images/mail.png" alt="email icon" width="200" style={{margin: "25px"}}/>
                            <Button
                                variant="contained"
                                color="primary"
                                width="200"
                                onClick={handleReSend} 
                                disableElevation
                                style={{maxWidth: '25em', minWidth: '25em'}}>
                                {t("activateEmail.button_send_email")}
                            </Button>
                            <Box mt={3} mb={3}>
                                <Button
                                    color="primary"
                                    width="200" 
                                    onClick={handleLogOut}>
                                    {t("activateEmail.button_logout")}
                                </Button>
                            </Box>
                        </Box>
                </Box>
            </Box>
            <Snackbar open={openSuccesSnackbar} autoHideDuration={3000} onClose={handleCloseSuccessSnackbar}>
                <Alert onClose={handleCloseSuccessSnackbar} severity="success">
                    {t("activateEmail.success_send_email")}
                </Alert>
            </Snackbar>
            <Snackbar open={openFailureSnackbar} autoHideDuration={3000} onClose={handleCloseFailureSnackbar}>
                <Alert onClose={handleCloseFailureSnackbar} severity="error">
                    {t("activateEmail.error_send_email")}
                </Alert>
            </Snackbar>	
		</main>
    );
}