import React, { useState } from 'react';
import { useGroupSessions } from '../shared/hooks/useGroupSessions';
import { useTranslation } from "react-i18next";
import { useParams } from 'react-router';
import { IconButton, Grid, Avatar, Typography, Box, Snackbar, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Dialog, DialogTitle, DialogContent, DialogActions, Button, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import Collapse from '@material-ui/core/Collapse';
import { useGroupSubscribers } from '../shared/hooks/useGroupSubscribers';
import Rating from '@material-ui/lab/Rating';
import { useUsers } from '../shared/hooks/useUsers';
import { useHistory } from 'react-router-dom';


const useStyles = makeStyles((theme) => ({
    rowTitle: {
        fontWeight: 600,
        fontSize: 16,
        color: "#aaaaaa"
    },
    cellDark: {
        fontSize: 15,
        fontWeight: 500,
    },
    cellLight: {
        fontSize: 15,
        fontWeight: 500,
        color: "#bbbbbb",
    },
    droplist_arrow: {
        width: "16px",
        objectFit: 'cover',
    },
    user_name: {
        marginBottom: "0px",
        fontWeight: 600,
        fontSize: 14,
    },
    user_email: {
        marginTop: "5px"
    },
    avatar: {
        marginRight: 20,
        width: "40px",
        height: "40px"
    },
    icon_edit: {
        paddingLeft: "25px",
        paddingRight: "5px",
        paddingTop: "10px",
        width: "35px",
        height: "35px",
        borderRadius: "50%",
        "&:hover": { backgroundColor: "#F3F2F2" }
    },
    icon_delete: {
        paddingLeft: "5px",
        paddingRight: "5px",
        paddingTop: "10px",
        width: "35px",
        height: "35px",
        borderRadius: "50%",
        "&:hover": { backgroundColor: "#F3F2F2" }
    },
    icon_droplist: {
        paddingLeft: "12px",
        paddingTop: "10px",
        width: "35px",
        height: "35px",
        borderRadius: "50%",
        "&:hover": { backgroundColor: "#F3F2F2" }
    },
}));

export function GroupSessions(props) {
    const { editSessionCallback } = props;
    let { groupId } = useParams();
    let history = useHistory();
    const { sessions, deleteSession, feedback } = useGroupSessions(groupId);
    const { t, i18n } = useTranslation();
    const classes = useStyles();
    const { subscribers } = useGroupSubscribers();
    const { users } = useUsers();
    const [sessionDetails, setSessionDetails] = useState(null);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    var subs = []
    subscribers.forEach(subscriber => {
        subs.push(subscriber.id);
    });

    function getSessionDate(date) {
        const monthFormatter = new Intl.DateTimeFormat(i18n.language, { month: 'short' });
        const dayFormatter = new Intl.DateTimeFormat(i18n.language, { weekday: 'short' });
        const monthName = monthFormatter.format(date);
        const dayName = dayFormatter.format(date);
        var day = date.getDate();
        var hour = date.getHours();
        var minute = date.getMinutes();
        return `${monthName.charAt(0).toUpperCase() + monthName.slice(1)} ${day} - ${dayName.charAt(0).toUpperCase() + dayName.slice(1)} ${hour}:${minute} `
    }

    function isBeforeNow(date) {
        var todaysDate = new Date();
        return date <= todaysDate;
    }

    const [openDeleteErrorSnackbar, setOpenDeleteErrorSnackbar] = useState(false);
    const handleCloseDeleteErrorSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenDeleteErrorSnackbar(false);
    };

    const [selectedSession, setSelectedSession] = useState(null);
    function handleOpenDeleteDialog(session) {
        setSelectedSession(session);
        setOpenDeleteDialog(true);
    }

    const handleDeleteSession = (sessionId) => {
        deleteSession(sessionId, { errorCallback: () => setOpenDeleteErrorSnackbar(true) });
        setOpenDeleteDialog(false);
    };

    function handleEditSession(session) {
        editSessionCallback(session)
        history.push(`/grupos/${groupId}/editSession`);
    }

    function handleShowParticipants(participant, session) {
        var element = null;
        users.map(subs => {
            if (subs.id === participant) {
                element = subs;
            }
        })
        var valoration = null;
        feedback.map(doc => {
            if (doc.userId === participant) {
                valoration = doc;
            }
        })

        return ((element !== null)
            ? <TableRow>
                <TableCell>
                    <Grid
                        container
                        direction="row"
                        justify="flex-start"
                        alignItems="center"
                    >
                        <Avatar className={classes.avatar} alt={element.name} src={element.imageUrl ?? ''} />
                        <Grid item>
                            <p className={classes.user_name}>{element.name} {element.surname}</p>
                            <p className={classes.user_email}>{element.email}</p>
                        </Grid>
                    </Grid>
                </TableCell>
                <TableCell>
                    <p>{(element.topicsConfigured === "ConfigurationState.TRUE") ? element.topics.roles[0] : ''}</p>
                </TableCell>
                {(session.state === "finished")
                    ? <TableCell scope="row" >
                        {(valoration !== null)
                            ? <Rating
                                name="simple-controlled"
                                value={valoration.questions[0].answer}
                            />
                            : ''}

                    </TableCell>
                    : ''}
                {(session.state === "finished")
                    ? <TableCell scope="row" >
                        {(valoration !== null)
                            ? <p>{valoration.comment}</p>
                            : ''}

                    </TableCell>
                    : ''}
            </TableRow>
            : <p></p>
        )
    }

    function SessionDetails(props) {
        const { session } = props;

        function handleSessionDetails() {
            if (sessionDetails !== session.id) {
                setSessionDetails(session.id);
            } else {
                setSessionDetails(null);
            }
        }

        return (
            <React.Fragment>
                <TableRow key={session.id} style={(sessionDetails === session.id) ? { backgroundColor: '#F1F1F1' } : { backgroundColor: '#FFFFFF' }}>
                    <TableCell scope="row" style={{ width: "2%" }}>
                        <Tooltip title={t("group_sessions.tooltip_droplist")} aria-label="add" style={{ marginRight: "10px" }}>
                            {(sessionDetails !== session.id)
                                ? <IconButton className={classes.icon_droplist}><img src="/assets/images/droplist_arrow_down.svg" alt='arrowdown' className={classes.droplist_arrow} onClick={() => handleSessionDetails()}></img></IconButton>
                                : <IconButton className={classes.icon_droplist}><img src="/assets/images/droplist_arrow_up.svg" alt='arrowdown' className={classes.droplist_arrow} onClick={() => handleSessionDetails()}></img></IconButton>
                            }
                        </Tooltip>
                    </TableCell>
                    <TableCell scope="row" style={{ width: "15%" }}>
                        {isBeforeNow(session.startDate.toDate())
                            ? <p className={classes.cellLight}>{getSessionDate(session.startDate.toDate())}</p>
                            : <p className={classes.cellDark}>{getSessionDate(session.startDate.toDate())}</p>}
                    </TableCell>
                    <TableCell component="th" scope="row" style={{ width: "35%" }}>
                        {isBeforeNow(session.startDate.toDate())
                            ? <p className={classes.cellLight}>{session.name}</p>
                            : <p className={classes.cellDark}>{session.name}</p>}
                    </TableCell>
                    <TableCell scope="row" align="left">
                        {isBeforeNow(session.startDate.toDate())
                            ? <p className={classes.cellLight}>{session.maxParticipants}</p>
                            : <p className={classes.cellDark}>{session.maxParticipants}</p>}
                    </TableCell>
                    <TableCell scope="row" align="left">
                        {isBeforeNow(session.startDate.toDate())
                            ? <p className={classes.cellLight}>{session.participants.length}</p>
                            : <p className={classes.cellDark}>{session.participants.length}</p>}
                    </TableCell>
                    <TableCell scope="row" align="left">
                        {isBeforeNow(session.startDate.toDate())
                            ? <p className={classes.cellLight}>{session.duration + "'"}</p>
                            : <p className={classes.cellDark}>{session.duration + "'"}</p>}
                    </TableCell>
                    <TableCell scope="row">
                        <Grid
                            container
                            direction={"row"}>
                            <span title={t("group_sessions.tooltip_edit")} aria-label="add" style={{ marginRight: "10px" }}>
                                {(isBeforeNow(new Date(session.startDate.toDate() - 600000)))
                                    ? <IconButton disabled className={classes.icon_edit} >
                                        <img disable src={"/assets/images/edit_icon_disabled.svg"} style={{ backgroundColor: "transparent", border: "none", textAlign: "center", marginRight: "20px" }} />
                                    </IconButton>
                                    : <IconButton className={classes.icon_edit} >
                                        <img src={"/assets/images/edit_icon.svg"} onClick={() => handleEditSession(session)} style={{ backgroundColor: "transparent", border: "none", textAlign: "center", marginRight: "20px" }} />
                                    </IconButton>}
                            </span>
                            <span title={t("group_sessions.tooltip_delete")} aria-label="add">
                                <IconButton className={classes.icon_delete} >
                                    <img src={"/assets/images/delete_icon.svg"} onClick={() => handleOpenDeleteDialog(session)} style={{ backgroundColor: "transparent", border: "none", textAlign: "center", color: "red" }} />
                                </IconButton>
                            </span>
                        </Grid>
                    </TableCell>
                </TableRow>
                <TableRow >
                    {<TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={10}>
                        <Collapse in={(sessionDetails === session.id) ? true : false} timeout="auto">
                            <Box margin={5}>
                                <Typography variant="h1" gutterBottom component="div" style={{ fontSize: "16px", margin: "15px" }}>
                                    {t("group_sessions.participants_title")}
                                </Typography>
                                <Table size="small" aria-label="purchases">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell scope="row" style={{ width: "35%" }}>
                                                <Typography variant="h6" gutterBottom component="div">
                                                    {t("group_sessions.participants_name")}
                                                </Typography>
                                            </TableCell>
                                            <TableCell scope="row" style={{ width: "20%" }}>
                                                <Typography variant="h6" gutterBottom component="div">
                                                    {"Rol"}
                                                </Typography>
                                            </TableCell>
                                            {(session.state === "finished")
                                                ? <TableCell scope="row" style={{ width: "20%" }}>
                                                    <Typography variant="h6" gutterBottom component="div">
                                                        {t("group_sessions.participants_valoration")}
                                                    </Typography>
                                                </TableCell>
                                                : ''
                                            }
                                            {(session.state === "finished")
                                                ? <TableCell scope="row" style={{ width: "20%" }}>
                                                    <Typography variant="h6" gutterBottom component="div">{t("group_sessions.participants_comment")}
                                                    </Typography>
                                                </TableCell>
                                                : ''
                                            }
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {((session.participants.length > 0) && (subscribers !== undefined) && (subscribers.length > 0))
                                            ? session.participants.map((participant) => (handleShowParticipants(participant, session)))
                                            : (isBeforeNow(session.startDate.toDate())
                                                ? <p style={{ marginLeft: "15px" }}>{t("group_sessions.not_participants_before")}</p>
                                                : <p style={{ marginLeft: "15px" }}>{t("group_sessions.not_participants_after")}</p>)}
                                    </TableBody>
                                </Table>
                            </Box>
                        </Collapse>
                    </TableCell>}
                </TableRow>
            </React.Fragment>
        )
    }

    return (
        <Box>
            <TableContainer>
                <Table size="big" aria-label="group_users_table">
                    <TableHead>
                        <TableRow>
                            <TableCell className={classes.rowTitle} >{''}</TableCell>
                            <TableCell className={classes.rowTitle} >{t("group_sessions.date")}</TableCell>
                            <TableCell className={classes.rowTitle} >{t("group_sessions.name")}</TableCell>
                            <TableCell className={classes.rowTitle} >{t("group_sessions.seats")}</TableCell>
                            <TableCell className={classes.rowTitle} >{t("group_sessions.inscribed")}</TableCell>
                            <TableCell className={classes.rowTitle} >{t("group_sessions.duration")}</TableCell>
                            <TableCell className={classes.rowTitle} style={{ width: "10%" }}>{""}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody >
                        {sessions.map((session) => (
                            <SessionDetails session={session} />
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            <Dialog
                open={openDeleteDialog}
                onClose={setOpenDeleteDialog}
                sessionState={selectedSession}
                maxWidth={"sm"}
                fullWidth={true}
            >
                <DialogTitle style={{ marginTop: "10px", marginLeft: "10px" }}>
                    {(selectedSession !== null) &&
                        <Typography variant="h1" >{((selectedSession.state === "active") && (isBeforeNow(new Date(selectedSession.startDate.toDate() - 600000))))
                            ? t("group_sessions.delete_dialog_actual_sesion")
                            : t("group_sessions.delete_dialog_title")}
                        </Typography>}
                </DialogTitle>
                <DialogContent style={{ marginLeft: "10px" }}>
                    {(selectedSession !== null) &&
                        <Typography variant="h3">{(selectedSession.state === "active") && (!isBeforeNow(new Date(selectedSession.startDate.toDate() - 600000)))
                            ? t("group_sessions.delete_dialog_subtitle_future_sesion")
                            : (selectedSession.state === "finished")
                                ? t("group_sessions.delete_dialog_subtitle_past_sesion")
                                : ''
                        }
                        </Typography>}
                </DialogContent>
                <DialogActions style={{ marginRight: "10px", marginBottom: "10px" }}>
                    {(selectedSession !== null) &&
                        ((selectedSession.state === "active") && (isBeforeNow(new Date(selectedSession.startDate.toDate() - 600000))))
                        ? <Button onClick={() => setOpenDeleteDialog(false)} variant="contained" color="primary" disableElevation >
                            {t("group_sessions.accept")}
                        </Button>
                        :
                        <div>
                            <Button onClick={() => setOpenDeleteDialog(false)} variant="contained" color="secondary" autoFocus disableElevation style={{ marginTop: "15px", marginRight: "15px" }}>
                                {t("group_sessions.cancel")}
                            </Button>
                            <Button onClick={() => handleDeleteSession(selectedSession.id)} variant="contained" color="primary" disableElevation style={{ marginTop: "15px" }}>
                                {t("group_sessions.delete")}
                            </Button>
                        </div>}
                </DialogActions>
            </Dialog>
            <Snackbar open={openDeleteErrorSnackbar} autoHideDuration={3000} onClose={handleCloseDeleteErrorSnackbar}>
                <Alert onClose={handleCloseDeleteErrorSnackbar} severity="error">
                    {t("group_sessions.delete_error")}
                </Alert>
            </Snackbar>
        </Box>

    );
}