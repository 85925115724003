import { useState, useEffect } from 'react';
import { useFirebase } from '../context/firebase-context';
import { useAdmin } from '../context/admin-context';
import { useGroup } from '../context/group-context';


export function useUsers() {
    const [users, setUsers] = useState([]);
    const [invitedUsers, setInvitedUsers] = useState([]);

    const { db, fromDate, functions, arrayUnion, arrayRemove } = useFirebase();

    const { event } = useAdmin();

    useEffect(() => {
        if (event) {
            db.collection('users')
            .orderBy('name', 'asc')
                .onSnapshot((snapshot) => {
                    const g = [];
                    snapshot.forEach(function (doc) {
                        if (event.participants.includes(doc.id)) {
                            g.push({ id: doc.id, ...doc.data() });
                        }
                    });
                    setUsers(g);
                });
        }
    }, [db, event]);

    useEffect(() => {
        if (event) {
            db.collection('invitations')
                .onSnapshot((snapshot) => {
                    const g = [];
                    snapshot.forEach(function (doc) {
                        var element = doc.data();
                        if (element.eventList.includes(event.id)) {
                            g.push({ id: doc.id, ...doc.data() });
                        }
                    });
                    setInvitedUsers(g);
                });
        }
    }, [db, event]);

    const sendInvitations = (invitationsList, { successCallback }) => {
        const activity = {
            name: event.name,
            type: 'newEvent',
            imageUrl: event.imageUrl ?? null,
            eventId: event.id,
            time: fromDate(new Date())
        };
        
        invitationsList.map(invitation => (
            db.collection('users')
                .where('email', '==', invitation.email)
                .onSnapshot((snapshot) => {
                    if (snapshot.docs.length > 0) {
                        db.collection('users')
                            .doc(snapshot.docs[0].id)
                            .collection('activities')
                            .add(activity)
                            .then(function () {
                                const actualUser = { id: snapshot.docs[0].id, ...snapshot.docs[0].data() };
                                if (actualUser.hasOwnProperty('notifications')) {
                                    successCallback(sendPushNotification(actualUser.notifications.token, actualUser.notifications.language, activity.name));
                                } else {
                                    successCallback(sendPushNotification(actualUser.token, 'en', activity.name));
                                }
                            }).catch(function (error) {
                                successCallback(false);
                            });
                    } else {
                        successCallback(sendInvitationToMail(invitation));
                    }
                })
        ))
    }

    const sendPrivateGroupNotification = (invitationsList, group, { successCallback }) => {  
        const activity = {
            name: event.name,
            type: 'group',
            infoText: group.name,
            infoImageUrl: (group.imageUrl === undefined) ? '' : group.imageUrl,
            imageUrl: (event.imageUrl === undefined) ? '' : event.imageUrl,
            time: fromDate(new Date())
        }
        invitationsList.forEach( user => {
            db.collection('users')
            .doc(user)
            .collection('activities')
            .add(activity)
            .then(function () {
            })
            .catch(err => {
            });
        })
        successCallback(true);
    }

    function sendInvitationToMail(invitation) {

        db.collection('mail').add({
            'template': {
                'name': "eventInvitation",
                'data': { 'email': invitation.email, 'eventName': event.name }
            },
            'to': invitation.email
        })
        db.collection('invitations')
            .doc(invitation.email)
            .get()
            .then(doc => {
                if (doc.exists) {
                    db.collection('invitations')
                        .doc(invitation.email)
                        .update({
                            eventList: arrayUnion(event.id)
                        })
                } else {
                    db.collection('invitations')
                        .doc(invitation.email)
                        .set({
                            'eventList': [event.id]
                        })
                }
            }).catch(function (error) {
                return false;
            });
        return true;
    }

    const sendPushNotification = (token, languageCode, eventName) => {
        var sendNotification = functions.httpsCallable('sendNotification');
        sendNotification({ 'token': token, 'languageCode': languageCode, 'eventName': eventName })
            .then((result) => {
            }).catch(function (error) {
                return false;
            });
        return true;
    }

    function blockUser(user) {
        db.collection('users')
            .doc(user.id)
            .update({
                "bannedEvents": arrayUnion(event.id)
            }).catch(function (error) {
                console.log(error);
            });
    }    
    
    function unBlockUser(user) {
        db.collection('users')
            .doc(user.id)
            .update({
                "bannedEvents": arrayRemove(event.id)
            }).catch(function (error) {
                console.log(error);
            });
    }

    function changeDefaultEvent(id, eventId, {successCallback}) {
        db.collection('users')
            .doc(id)
            .update({
                "defaultEvent": eventId
            }).catch(function (error) {
                console.log(error);
                successCallback(false);
            });
            successCallback(true);
    }
    

    return {
        users,
        invitedUsers,
        sendInvitations,
        sendPrivateGroupNotification,
        unBlockUser,
        blockUser,
        changeDefaultEvent
    }

}