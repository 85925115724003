import { useEffect } from 'react';
import { useFirebase } from '../context/firebase-context';
import { useAdmin } from '../context/admin-context';


export function useGroups() {
    const { db } = useFirebase();
    const { event, setGroupList, groupList } = useAdmin();

    useEffect( () => {
        if (event) {
            db.collection('groups')
            .where('eventId', '==', event.id)
            .onSnapshot((snapshot) => {
                const g = [];
                snapshot.forEach(function (doc) {
                    g.push({ id: doc.id, ...doc.data() });
                });
                setGroupList(g);
            });
        }

    }, [db, event, setGroupList]);

    return{
        groupList,
    }

}