import React from 'react';
import { GroupList } from './GroupList';
import { UserList } from './UserList';
import { GroupDetails } from './GroupDetails';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Toolbar } from '@material-ui/core';
import Breadcrumbs from './Breadcrumbs';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom'
import { Header } from './Header';
import { Settings } from './Settings';
import { AddGroup } from './AddGroup';
import { useTranslation } from "react-i18next";
import { GroupProvider } from '../shared/context/group-context';
import { AddSession } from './AddSession';
import { SessionList } from './SessionList';

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: 0,
        backgroundColor: '#f2efea',
    }
}));

export function Dashboard() {
    const classes = useStyles();
    const { t } = useTranslation();

    const routes = {
        '/': 'Home',
        '/login': 'Login',
        '/register': 'register',
        '/activate': 'activate',
        '/nuevogrupo': t("dashboard.title_new_group"),
        '/createAdmin': t("dashboard.title_new_event"),
        '/usuarios': 'Usuarios',
        '/ajustes': 'Ajustes',
        '/sesiones': 'Sesiones',
        '/grupos/:groupId': params => params.groupId,
        '/grupos/:groupId/addSession': t("dashboard.title_new_sesion"),
        '/grupos/:groupId/editSession': t("dashboard.title_edit_session")
    };

    return (
        <Router>
            <Header />
                <Toolbar />
                <Breadcrumbs routes={routes} />
                <Box>
                    <Switch>
                        <Route exact path="/">
                            <Box display="flex" flexDirection="column" paddingTop={1} height="100%" width="100%" style={{marginTop: "40px"}}>
                                <GroupList />
                            </Box>
                        </Route>
                        <Route path="/usuarios">
                            <Box display="flex" flexDirection="column" paddingTop={1} height="100%" width="100%">
                                <UserList />
                            </Box>
                        </Route>
                        <Route path="/sesiones">
                            <Box display="flex" flexDirection="column" paddingTop={1} height="100%" width="100%">
                                <SessionList />
                            </Box>
                        </Route>
                        <Route path="/ajustes">
                            <Box display="flex" flexDirection="column" paddingTop={1} height="100%" width="100%">
                                <Settings />
                            </Box>
                        </Route>
                        <Route path="/nuevogrupo">
                            <Box display="flex" flexDirection="column" paddingTop={1} height="100%" width="100%">
                                <AddGroup />
                            </Box>
                        </Route>
                        <Route path="/grupos/:groupId">
                            <Box display="flex" flexDirection="column" paddingTop={1} height="100%" width="100%">
                                <GroupProvider>
                                    <GroupDetails />
                                </GroupProvider>
                            </Box>
                        </Route>
                        <Route path="/grupos/:groupId/editSession">
                            <Box display="flex" flexDirection="column" paddingTop={1} height="100%" width="100%">
                                <AddSession/>
                            </Box>
                        </Route>
                        <Route path="*">
                            <h1>404 not found</h1>
                        </Route>
                    </Switch>
                </Box>
        </Router>
    );
}