import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import Checkbox from '@material-ui/core/Checkbox';
import { makeStyles } from '@material-ui/core/styles';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import { useTranslation } from "react-i18next";
import { Typography, Button } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';


const useStyles = makeStyles((theme) => ({
  dialog_content: {
    margin: "1%"
  },
  dialog_title: {
    paddingBottom: "2%"
  },
  table: {
    marginTop: "2%",
  },
  save_button: {
    marginLeft: "30px"
  },
  input: {
    display: 'none',
  },
  icebreker_text: {
    fontSize: '16px'
  },
  icebreakers_overflow: {
    fontSize: '16px',
  }
}));

export function AddIcebreakersDialog(props) {
  const { openDialog, setOpenDialog, icebreakers, preSelectedIcebreakers, rounds, closeCallback } = props;
  const { t } = useTranslation();
  const classes = useStyles();
  const [counter, setCounter] = useState((preSelectedIcebreakers.length > 0) ? preSelectedIcebreakers.length : 0);
  const [checkedIcebreakers, setCheckedIcebreakers] = useState(preSelectedIcebreakers);

  function isSelected(key) {
    return (checkedIcebreakers.includes(key));
  }

  const handleIcebreakersChecked = (value, key) => {
    if (value.target.checked) {
      var g = checkedIcebreakers;
      g.push(key)
      setCheckedIcebreakers(g);
      handleCounter(true);
    } else {
      const index = checkedIcebreakers.indexOf(key);
      if (index > -1) {
        checkedIcebreakers.splice(index, 1);
        handleCounter(false);
      }
    }
  };

  function handleCounter(value) {
    setCounter((value) ? counter + 1 : counter - 1)
  }

  function closeDialog() {
    setCounter(0);
    setCheckedIcebreakers([]);
    closeCallback(false);
  }

  function saveIcebreakers() {
    const icebreakersSelection = [];

    icebreakers.forEach((element, index) => {
      if (checkedIcebreakers.includes(index)) {
        icebreakersSelection.push(element);
      }
    })
    closeCallback(icebreakersSelection);
  }

  function addIcebreakers(element, key) {

    return (
      <TableRow key={key}>
        <TableCell>
          <Typography variant="h2" className={classes.icebreker_text}>{key + 1}</Typography>
        </TableCell>
        <TableCell component="th" scope="row">
          <Grid
            container
            direction="row"
            justify="flex-start"
            alignItems="center"
          >
            <Typography variant="h2" className={classes.icebreker_text}>{element}</Typography>
          </Grid>
        </TableCell>
        <TableCell >
          <Checkbox defaultChecked={isSelected(key)} onChange={value => handleIcebreakersChecked(value, key)} name={key} color="primary" />
        </TableCell>
      </TableRow>
    )
  }

  return (
    <Dialog
      open={openDialog}
      onClose={setOpenDialog}
      maxWidth={"md"}
      fullWidth={false}
      scroll={"paper"}
    >
      <DialogContent className={classes.dialog_content}>
        <Typography variant="h1" className={classes.dialog_title}>{"Icebreakers"}</Typography>
        <Typography variant="h3">{t("icebreakers_dialog.icebreakers_dialog_subtitle")}</Typography>
        {(icebreakers != undefined) ? (
          <Table className={classes.table} size="medium" aria-label="icebreakers_table">
            <TableHead>
              <TableRow>
                <TableCell >{""}</TableCell>
                <TableCell align="left" style={{ width: "85%"}}>{<Typography variant="h2">{t("Icebreaker")}</Typography>}</TableCell>
                {(counter <= rounds)
                  ? <TableCell align="right">{<Typography variant="h3" >{counter + ' / ' + rounds}</Typography>}</TableCell>
                  : <TableCell align="right">{<Typography variant="h1" className={classes.icebreakers_overflow}>{counter + ' / ' + rounds}</Typography>}</TableCell>
                }
              </TableRow>
            </TableHead>
            <TableBody >
              {(icebreakers.length > 0)
                && icebreakers.map((element, key) => (
                  addIcebreakers(element, key)
                ))
              }
            </TableBody>
          </Table>
        ) : ''}
      </DialogContent>
      <DialogActions className={classes.dialog_content}>
        <Button
          className={classes.description_button_csv}
          variant="contained"
          disableElevation
          color="secondary"
          onClick={() => closeDialog()}>
          {t("add_users_dialog.button_cancel")}
        </Button>
        <Button
          className={classes.save_button}
          variant="contained"
          disableElevation
          color="primary"
          onClick={() => saveIcebreakers()}
          disabled={(rounds === counter) ? false : true}
        >
          {t("icebreakers_dialog.icebreakers_dialog_save_button")}
        </Button>
      </DialogActions>
    </Dialog>
  )
}