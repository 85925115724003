import React, { useState } from 'react';
import { useUsers } from '../shared/hooks/useUsers';
import { Avatar, Typography, Button } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from "react-i18next";
import Chip from '@material-ui/core/Chip';
import { useGroups } from '../shared/hooks/useGroups';
import { AddUsersDialog } from './AddUsersDialog';
import { useAdmin } from '../shared/context/admin-context';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';


const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        justifyContent: 'left',
        flexWrap: 'wrap',
        listStyle: 'none',
        width: "96%",
        padding: theme.spacing(0.5),
        backgroundColor: theme.palette.background.paper,
        margin: 20,
    },
    header_div: {
        padding: "30px",
    },
    header_description: {
        width: "60%",
        marginBottom: "1%"
    },
    header_grid: {
        marginTop: "20px"
    },
    blackButton: {
        color: "#000000",
        textTransform: "none",
        fontWeight: 600,
        fontSize: 18
    },
    accentButton: {
        color: "#FF5851",
        textTransform: "none",
        fontWeight: 600,
        fontSize: 18
    },
    user_avatar: {
        marginRight: 30,
        width: "55px",
        height: "55px"
    },
    user_name: {
        marginBottom: "0px",
        fontWeight: 600,
        fontSize: 14,
    },
    user_email: {
        marginTop: "5px"
    },
    invitedRow: {
        height: "80px"
    },
    rowTitle: {
        color: "DarkGray",
        fontWeight: "bold",
        fontSize: "17px"
    },
    rowTitle_groups: {
        color: "DarkGray",
        fontWeight: "bold",
        fontSize: "17px",
        width: "40%",
    },
    groups_chips: {
        marginRight: 15,
    },
    blockUserButton: {
        backgroundColor: "transparent",
        border: "none",
        textAlign: "center",
        fontSize: 12,
        color: "red",
        textTransform: "none"
    },
    state_chips_verified: {
        width: "10px",
        height: "10px",
        backgroundColor: '#3BA72D',
        marginLeft: 15,
    },
    state_chips_invited: {
        width: "10px",
        height: "10px",
        backgroundColor: '#0094FF',
        marginLeft: 15,
    },
    state_chips_blocked: {
        width: "10px",
        height: "10px",
        backgroundColor: 'red',
        marginLeft: 15,
    },
    state_chips_not_verified: {
        width: "10px",
        height: "10px",
        backgroundColor: '#F3F048',
        marginLeft: 15,
    },
    tableCell: {
        paddingLeft: "20px",
        paddingRight: "20px",
        padding: "3px"
    }
}));

export function UserList() {
    const classes = useStyles();
    const { t } = useTranslation();
    const { users, invitedUsers } = useUsers();
    const [openDialog, setOpenDialog] = useState(false);
    const [openSuccesSnackbar, setOpenSuccesSnackbar] = useState(false);
    const [showInvited, setShowInvited] = useState(false);

    const handleCloseSuccessSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSuccesSnackbar(false);
    };

    const handleSuccessSnacKbar = (value) => {
        if (value) {
            setOpenSuccesSnackbar(true)
        }
    };

    function handleShowInvited(value) {
        setShowInvited(value);
    }

    const registeredTabStyle = showInvited ? classes.blackButton : classes.accentButton;
    const pendingTabStyle = showInvited ? classes.accentButton : classes.blackButton;

    return (
        <div className={classes.root}>
            <style>{'body { background-color: #f2efea; }'}</style>
            <div className={classes.header_div}>
                <Typography variant="h1">
                    {t("user_list.title")}
                </Typography>
                <Grid
                    container
                    direction="row"
                    justify="space-between"
                    alignItems="center"
                    className={classes.header_grid}
                >
                    <Typography className={classes.header_description} variant="h3">
                        {t("user_list.description")}
                    </Typography>
                    <Button variant="contained" disableElevation color="primary" onClick={() => setOpenDialog(true)}>{t("user_list.inviteUsersButton")}</Button>
                </Grid>
                <AddUsersDialog
                    openDialog={openDialog}
                    setOpenDialog={setOpenDialog}
                    successCallback={handleSuccessSnacKbar}
                >
                </AddUsersDialog>
            </div>

            <hr style={{ height: "1px", width: "100%", borderTop: "1px solid #E2E2E2"}} />
            <Grid
                container
                direction="row"
                justify="flex-start"
                alignItems="left">
                <Button onClick={() => handleShowInvited(false)} style={{ marginLeft: "3%", marginBottom: "2%" }}>
                    <Typography className={registeredTabStyle}>
                        {t("session_list.registered_users")} ({users.length})
                </Typography>
                </Button>
                <Button onClick={() => handleShowInvited(true)} style={{ marginLeft: "3%", marginBottom: "2%" }}>
                    <Typography className={pendingTabStyle}>
                        {t("session_list.pending_users")} ({invitedUsers.length})
                </Typography>
                </Button>
            </Grid>
            {showInvited
                ?
                <ShowInvitedUsers />
                :
                <ShowRegisteredUsers />
            }
            <Snackbar open={openSuccesSnackbar} autoHideDuration={3000} onClose={handleCloseSuccessSnackbar}>
                <Alert onClose={handleCloseSuccessSnackbar} severity="success">
                    {t("add_users_dialog.invitation_succes")}
                </Alert>
            </Snackbar>
        </div>
    );
}

function ShowInvitedUsers() {
    const classes = useStyles();
    const { t } = useTranslation();
    const { invitedUsers } = useUsers();

    return (
        <Table size="medium" aria-label="group_users_table">
            <TableHead>
                <TableRow>
                    <TableCell className={classes.rowTitle} >{t("user_list.emailRowTitle")}</TableCell>
                    <TableCell className={classes.rowTitle} style={{ width: "65%"}}>{t("user_list.stateRowTitle")}</TableCell>
                </TableRow>
            </TableHead>
            <TableBody >
                {invitedUsers.map((element) => (
                    <TableRow key={element} className={classes.invitedRow}>
                        <TableCell className={classes.tableCell}>
                            <Grid
                                container
                                direction="row"
                                justify="flex-start"
                                alignItems="center"
                            >
                                <Avatar className={classes.user_avatar} alt={element} src={''} />
                                <Grid item>
                                    <p>{element.id}</p>
                                </Grid>
                            </Grid>
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                            <Grid
                                container
                                direction="row"
                                justify="flex-start"
                                alignItems="center">
                                <p className={classes.invitedEmail} >{t("user_list.stateInvited")}</p>
                                <Chip className={classes.state_chips_invited} />
                            </Grid>
                        </TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    )
}

function ShowRegisteredUsers() {
    const classes = useStyles();
    const { event } = useAdmin();
    const { t } = useTranslation();
    const { groupList } = useGroups();
    const { users, blockUser, unBlockUser } = useUsers();
    const [filter, setFilter] = useState(null);


    function handleBlockUser(element) {
        blockUser(element.user);
    };

    function handleUnblockUser(element) {
        unBlockUser(element.user);
    };

    function userIsBanned(user) {
        if (user.bannedEvents != null && user.bannedEvents.includes(event.id)) {
            return true;
        } else {
            return false;
        }
    }

    function handleUserState(user) {
        return (
            (userIsBanned(user)) ? (<Grid
                container
                direction="row"
                justify="flex-end"
                alignItems="center">
                <p>{t("user_list.stateBlocked")}</p>
                <Chip className={classes.state_chips_blocked} />
            </Grid>) :
                (user.emailVerified)
                    ? (<Grid
                        container
                        direction="row"
                        justify="flex-end"
                        alignItems="center">
                        <p>{t("user_list.stateActive")}</p>
                        <Chip className={classes.state_chips_verified} />
                    </Grid>)
                    : (<Grid
                        container
                        direction="row"
                        justify="flex-end"
                        alignItems="center">

                        <p>{t("user_list.stateNotVerified")}</p>
                        <Chip className={classes.state_chips_not_verified} />
                    </Grid>)
        )
    }

    function userGroupsHandler() {
        if (users) {
            const userList = [];
            users.map((user) => {
                const userWithGroups = {
                    user: user,
                    groups: []
                };
                groupList.forEach(group => {
                    if ((group.hasOwnProperty("participants")) && (group.participants !== null)) {
                        if (group.participants.includes(user.id)) {
                            userWithGroups.groups.push(group.name);
                        }
                    }
                });
                userList.push(userWithGroups);
            })
            return userList;
        }
    }

    const rows = userGroupsHandler().map((element) => (
        (element.groups.length < 1)
            ? createUserData(element.user, undefined)
            : createUserData(element.user, element.groups)
    ))

    function createUserData(user, groups) {
        return { user, groups };
    }

    function setGroupsChips(element) {
        if (element.groups != undefined) {
            if (element.groups.length <= 3) {
                return element.groups.map((group) => {
                    return (
                        <Chip key={group.id} variant="outlined" size="small" className={classes.groups_chips} label={group} />
                    );
                });
            } else {
                return element.groups.map((group, index) => {
                    if (index < 3) {
                        return (
                            <Chip key={group.id} variant="outlined" size="small" className={classes.groups_chips} label={group} />
                        );
                    } else if (index == 3) {
                        return <Chip key={group.id} variant="outlined" size="small" className={classes.groups_chips} label={'...'} />
                    }
                })
            }
        }
    }

    function handleFilteredUsers(value) {
        setFilter(value);
    }

    function handleAutocompleteOptions() {
        const g = [];
        users.map((user) => {
            if (user.name !== undefined) {
                if (!g.includes(user.name)) {
                    g.push(user.name);
                }
            }
        })
        return g;
    }

    return (
        <div style={{ width: "100%" }}>
            <Autocomplete
                onChange={(event, value) => handleFilteredUsers(value)}
                id="free-solo"
                options={(users.length > 0) ? handleAutocompleteOptions() : ''}
                getOptionLabel={(option) => (option !== undefined) ? option.toString() : ''}
                style={{ width: 600, marginLeft: 20 }}
                renderInput={(params) => (
                    <TextField {...params} label="Filtrar por nombre" margin="normal" variant="outlined" />)}
            />
            <Table size="medium" aria-label="group_users_table">
                <TableHead>
                    <TableRow>
                        <TableCell className={classes.rowTitle} >{t("user_list.userRowTitle")}</TableCell>
                        <TableCell className={classes.rowTitle} align="right">{t("user_list.stateRowTitle")}</TableCell>
                        <TableCell className={classes.rowTitle_groups} align="right">{t("user_list.groupsRowTitle")}</TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody >
                    {rows.map((element) => (
                        ((filter === null) || (element.user.name === filter))
                        &&
                        <TableRow key={element.user.id}>
                            <TableCell component="th" scope="row" className={classes.tableCell}>
                                <Grid
                                    container
                                    direction="row"
                                    justify="flex-start"
                                    alignItems="center"
                                >
                                    <Avatar className={classes.user_avatar} alt={element.user.name} src={element.user.imageUrl ?? ''} />
                                    <Grid item>
                                        <p className={classes.user_name}>{element.user.name} {element.user.surname}</p>
                                        <p className={classes.user_email}>{element.user.email}</p>
                                    </Grid>
                                </Grid>
                            </TableCell>
                            <TableCell align="right" className={classes.tableCell}>{handleUserState(element.user)}</TableCell>
                            <TableCell align="right" className={classes.tableCell}>
                                <Grid
                                    container
                                    direction="row"
                                    justify="flex-end"
                                >
                                </Grid>
                                {setGroupsChips(element)}
                            </TableCell>

                            {userIsBanned(element.user) ? <TableCell align="right" className={classes.tableCell}>
                                <Button onClick={() => handleUnblockUser(element)} className={classes.blockUserButton}>
                                    {t("user_list.unBlockButton")}
                                </Button>
                            </TableCell> : <TableCell align="right" className={classes.tableCell}>
                                <Button onClick={() => handleBlockUser(element)} className={classes.blockUserButton}>
                                    {t("user_list.blockButton")}
                                </Button>
                            </TableCell>}
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </div>
    )
}