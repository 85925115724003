import { makeStyles } from '@material-ui/core/styles';
import { Tabs, Tab, Box, InputAdornment, Divider, Grid, Dialog, DialogTitle, DialogContent, DialogActions } from '@material-ui/core';
import React, { useRef, useState, useCallback } from 'react';
import { useForm, Controller } from "react-hook-form";
import { TextField, FormControl, Button, Typography } from '@material-ui/core';
import { useTranslation } from "react-i18next";
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import { useEvent } from '../shared/hooks/useEvents';
import { useAdmin } from '../shared/context/admin-context';
import { useAuth } from '../shared/hooks/useAuth';
import { Loading } from '../components/Loading';
import Cropper from 'react-easy-crop'
import { getCroppedImg } from '../shared/utils/cropImage'


const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        justifyContent: 'left',
        flexWrap: 'wrap',
        listStyle: 'none',
        width: "100%",
        padding: theme.spacing(0.5),
        backgroundColor: theme.palette.background.paper,
        margin: 20,
    },
    imgRound: {
        border: '1px solid #FF5851',
        width: 150,
        height: 150,
        borderRadius: 150 / 2,
        objectFit: 'contain'
    },
    cropContainer: {
        position: "absolute",
    },
    input: {
        display: 'none',
    },
    chip: {
        margin: theme.spacing(0.5)
    }
}));

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div {...other}>
            {value === index && <Box p={3}>{children}</Box>}
        </div>
    );
}

export function Settings() {
    const [value, setValue] = useState(0);
    const classes = useStyles();
    const { t } = useTranslation();

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <div className={classes.root}>
            <style>{'body { background-color: #f2efea; }'}</style>
            <Box width="100%">
                <Tabs
                    value={value}
                    indicatorColor="primary"
                    textColor="primary"
                    onChange={handleChange}
                    aria-label="group detail tabs">
                    <Tab label={t("event_settings.tab_title")} />
                    <Tab label={t("security_settings.tab_title")} />
                </Tabs>
                <TabPanel value={value} index={0}>
                    <GeneralSettings />
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <SecuritySettings />
                </TabPanel>
            </Box>
        </div>
    );
}

function GeneralSettings() {
    const classes = useStyles();
    const { t } = useTranslation();
    const [generalLoginError, setGeneralLoginError] = useState('');
    const { handleSubmit, control, formState: { errors: fieldsErrors }, reset } = useForm();
    const { event } = useAdmin();
    const { updateEventDetails } = useEvent();
    const [openSuccesSnackbar, setOpenSuccesSnackbar] = useState(false);
    const [openFailureSnackbar, setOpenFailureSnackbar] = useState(false);
    const [logo, setLogo] = useState();
    const [unCroppedLogo, setUncroppedLogo] = useState();
    const [croppedLogoImage, setCroppedLogoImage] = useState();
    const [openDialog, setOpenDialog] = useState(false);
    const [crop, setCrop] = useState({ x: 0, y: 0 })
    const [zoom, setZoom] = useState(1)
    const [croppedAreaPixels, setcroppedAreaPixels] = useState()

    const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
        setcroppedAreaPixels(croppedAreaPixels);
    }, [])

    const handleCloseSuccessSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSuccesSnackbar(false);
    };

    const handleCloseFailureSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenFailureSnackbar(false);
    };

    const handleLogoCrop = (event) => {
        if(event.target.files.length > 0){
            setUncroppedLogo({ url: URL.createObjectURL(event.target.files[0]), file: event.target.files[0] });
            setOpenDialog(true);
        }
    }

    const saveCroppedImage = async () => {
        const croppedImage = await getCroppedImg(
            unCroppedLogo.url, croppedAreaPixels, 256, 256
        );
        setLogo(croppedImage);
        var img = URL.createObjectURL(croppedImage);
        setCroppedLogoImage(img);
        setOpenDialog(false);
    }

    const onSubmit = data => {
        const modifiedEvent = { ...event };
        modifiedEvent.name = data.eventName;
        modifiedEvent.description = data.eventDescription;
        modifiedEvent.website = data.eventWebsite;
        updateEventDetails(modifiedEvent, logo,
            { successCallback: (success) => { success ? setOpenSuccesSnackbar(true) : setOpenFailureSnackbar(true) } });
    };

    if (event) {
        return (
            <form onSubmit={handleSubmit(onSubmit)} width={9999}>
                <Box my={2}>
                    <Typography variant="h1">
                        {t("event_settings.profile_title")}
                    </Typography>
                </Box>
                <Box my={2} width="50%">
                    <Typography variant="h3">
                        {t("event_settings.title_info")}
                    </Typography>
                </Box>
                <Box py={2}><Divider width="100%" /></Box>
                <Box my={2}>
                    <Typography variant="h1">
                        {t("event_settings.title")}
                    </Typography>
                </Box>
                <Box mb={4} mt={4} width="70%">
                    <FormControl fullWidth className={classes.margin} variant="outlined">
                        <Controller
                            name="eventName"
                            render={({ field }) =>
                                <TextField
                                    id="eventName"
                                    labelwidth={40}
                                    helperText={fieldsErrors.eventName ? fieldsErrors.eventName.message : null}
                                    variant="outlined"
                                    label={t("event_settings.name_label")}
                                    error={!!fieldsErrors.eventName}
                                    {...field}
                                />}
                            control={control}
                            defaultValue={event.name}
                            rules={{
                                required: 'Required'
                            }}
                        />
                    </FormControl>
                </Box>
                <Box mb={3} width="70%">
                    <FormControl fullWidth className={classes.margin} variant="outlined">
                        <Controller
                            name="eventDescription"
                            render={({ field }) =>
                                <TextField
                                    id="eventDescription"
                                    labelwidth={70}
                                    helperText={fieldsErrors.eventDescription ? fieldsErrors.eventDescription.message : null}
                                    variant="outlined"
                                    label={t("event_settings.description_label")}
                                    multiline
                                    error={!!fieldsErrors.eventDescription}
                                    {...field}
                                />}
                            control={control}
                            defaultValue={event.description}
                            rules={{
                                required: 'Required'
                            }}
                        />
                    </FormControl>
                </Box>
                <Typography variant="h2">
                    {t("event_settings.logo_title")}
                </Typography>
                <Box my={2}>
                    <Grid container justify="flex-start" spacing={5} alignItems="center" >
                        <Grid item>
                            {croppedLogoImage
                                ? <img src={croppedLogoImage} alt={event ? event.name : ''} className={classes.imgRound} />
                                : <img src={((event)&&(event.logoUrl !== "")) ? event.logoUrl : "/assets/images/no_image_logo.png"} alt={event ? event.name : ''} className={classes.imgRound} />}
                            <input accept="image/*" className={classes.input} id="contained-button-logo" multiple type="file" onChange={handleLogoCrop} />
                        </Grid>
                        <Grid item >
                            <label htmlFor="contained-button-logo">
                                <Button variant="contained" color="primary" component="span" disableElevation>
                                    {t("group_details_form.button_upload_image")}
                                </Button>
                            </label>
                        </Grid>
                    </Grid>
                </Box>
                <Typography variant="h2">
                    {t("event_settings.website_title")}
                </Typography>
                <Box my={3} width="70%">
                    <FormControl fullWidth className={classes.margin} variant="outlined">
                        <Controller
                            name="eventWebsite"
                            render={({ field }) =>
                                <TextField
                                    id="eventWebsite"
                                    labelwidth={70}
                                    helperText={fieldsErrors.eventWebsite ? fieldsErrors.eventWebsite.message : null}
                                    variant="outlined"
                                    label={t("event_settings.website_label")}
                                    multiline
                                    error={!!fieldsErrors.eventWebsite}
                                    {...field}
                                />}
                            control={control}
                            defaultValue={event.website}
                        />
                    </FormControl>
                </Box>
                {generalLoginError && <div>{generalLoginError}</div>}
                <Button variant="contained" color="primary" type="submit" disableElevation>
                    {t("event_settings.save_button")}
                </Button>
                <Snackbar open={openSuccesSnackbar} autoHideDuration={3000} onClose={handleCloseSuccessSnackbar}>
                    <Alert onClose={handleCloseSuccessSnackbar} severity="success">
                        {t("event_settings.save_success")}
                    </Alert>
                </Snackbar>
                <Snackbar open={openFailureSnackbar} autoHideDuration={3000} onClose={handleCloseFailureSnackbar}>
                    <Alert onClose={handleCloseFailureSnackbar} severity="error">
                        {t("event_settings.save_error")}
                    </Alert>
                </Snackbar>
                <Dialog
                    open={openDialog}
                    onClose={setOpenDialog}
                    className={classes.crop_container}
                >
                    <DialogTitle >
                        <Typography variant="h1">{t("crop_dialog.title")}</Typography>
                    </DialogTitle>
                    <DialogContent>
                        {unCroppedLogo
                            ? <Box height="400px" width="400px" pb={5} position="relative" color="white">
                                <Cropper
                                    image={unCroppedLogo.url}
                                    crop={crop}
                                    zoom={zoom}
                                    minZoom={-10}
                                    cropShape="round"
                                    showGrid={false}
                                    aspect={1}
                                    onCropChange={setCrop}
                                    onCropComplete={onCropComplete}
                                    onZoomChange={setZoom}
                                />
                            </Box>
                            : <Box></Box>}
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setOpenDialog(false)} variant="contained" color="secondary" autoFocus disableElevation>
                            {t("crop_dialog.cancel")}
                        </Button>
                        <Button onClick={saveCroppedImage} variant="contained" color="primary" disableElevation>
                            {t("crop_dialog.crop")}
                        </Button>
                    </DialogActions>
                </Dialog>
            </form>
        );
    } else {
        return (
            <Loading />
        );
    }
}

function SecuritySettings() {
    const classes = useStyles();
    const { t } = useTranslation();
    const [generalLoginError, setGeneralLoginError] = useState('');
    const { handleSubmit, control, formState: { errors: fieldsErrors }, watch } = useForm();
    const [showOldPassword, setShowOldPassword] = useState(false);
    const handleClickShowOldPassword = () => setShowOldPassword(!showOldPassword);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const handleClickShowNewPassword = () => setShowNewPassword(!showNewPassword);
    const [showRepeatPassword, setShowRepeatPassword] = useState(false);
    const handleClickShowRepeatPassword = () => setShowRepeatPassword(!showRepeatPassword);
    const password = useRef({});
    const { updatePassword } = useAuth();
    password.current = watch("newPassword", "");
    const [openSuccesSnackbar, setOpenSuccesSnackbar] = useState(false);
    const [openFailureSnackbar, setOpenFailureSnackbar] = useState(false);

    const handleCloseSuccessSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSuccesSnackbar(false);
    };

    const handleCloseFailureSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenFailureSnackbar(false);
    };

    const onSubmit = data => {
        updatePassword(data.oldPassword, data.newPassword, { successCallback: (success) => { success ? setOpenSuccesSnackbar(true) : setOpenFailureSnackbar(true) } });
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)} width={9999}>
            <Box my={2}>
                <Typography variant="h1">
                    {t("security_settings.security_title")}
                </Typography>
            </Box>
            <Box my={2} width="50%">
                <Typography variant="h3">
                    {t("security_settings.security_subtitle")}
                </Typography>
            </Box>
            <Box py={2}><Divider width="100%" /></Box>
            <Box mt={2} mb={4}>
                <Typography variant="h1">
                    {t("security_settings.password_title")}
                </Typography>
            </Box>
            <Typography variant="h2">
                {t("security_settings.current_password_title")}
            </Typography>
            <Box mt={3} mb={5} width="70%">
                <FormControl fullWidth className={classes.margin} variant="outlined">
                    <Controller
                        name="oldPassword"
                        render={({ field }) =>
                            <TextField
                                id="oldPassword"
                                labelwidth={40}
                                variant="outlined"
                                label={t("security_settings.current_password_title")}
                                type={showOldPassword ? "text" : "password"}
                                helperText={fieldsErrors.oldPassword ? fieldsErrors.oldPassword.message : null}
                                error={!!fieldsErrors.oldPassword}
                                autoComplete="current-password"
                                {...field}
                                InputProps={{ // <-- This is where the toggle button is added.
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <Button
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowOldPassword}
                                            >
                                                {showOldPassword
                                                    ? <Typography variant="body2">
                                                        {t("security_settings.hide")}
                                                    </Typography>
                                                    : <Typography variant="body2">
                                                        {t("security_settings.show")}
                                                    </Typography>}
                                            </Button>
                                        </InputAdornment>
                                    )
                                }}
                            />}
                        control={control}
                        defaultValue=""
                        rules={{
                            required: { value: true, message: `${t("security_settings.required_error")}` },
                        }}
                    />
                </FormControl>
            </Box>
            <Typography variant="h2">
                {t("security_settings.new_password_title")}
            </Typography>
            <Box mt={2} mb={1} width="70%">
                <Typography variant="h3">
                    {t("security_settings.new_pass_subtitle")}
                </Typography>
            </Box>
            <Box mt={3} mb={5} width="70%">
                <FormControl fullWidth className={classes.margin} variant="outlined">
                    <Controller
                        name="newPassword"
                        render={({ field }) =>
                            <TextField
                                id="newPassword"
                                labelwidth={40}
                                variant="outlined"
                                type={showNewPassword ? "text" : "password"}
                                label={t("security_settings.new_password_title")}
                                error={!!fieldsErrors.eventName}
                                helperText={fieldsErrors.newPassword ? fieldsErrors.newPassword.message : null}
                                error={!!fieldsErrors.newPassword}
                                {...field}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <Button
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowNewPassword}
                                            >
                                                {showNewPassword
                                                    ? <Typography variant="body2">
                                                        {t("security_settings.hide")}
                                                    </Typography>
                                                    : <Typography variant="body2">
                                                        {t("security_settings.show")}
                                                    </Typography>}
                                            </Button>
                                        </InputAdornment>
                                    )
                                }}
                            />}
                        control={control}
                        defaultValue=""
                        rules={{
                            required: { value: true, message: `${t("security_settings.required_error")}` },
                            minLength: { value: 8, message: `${t("security_settings.short_error")}` },
                            pattern: {
                                value: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/,
                                message: t("signin.error_field_password_not_valid")
                            }
                        }}
                    />
                </FormControl>
            </Box>
            <Typography variant="h2">
                {t("security_settings.old_password_title")}
            </Typography>
            <Box mt={3} mb={5} width="70%">
                <FormControl fullWidth className={classes.margin} variant="outlined">
                    <Controller
                        name="repeatPassword"
                        render={({ field }) =>
                            <TextField
                                id="repeatPassword"
                                labelwidth={40}
                                variant="outlined"
                                type={showRepeatPassword ? "text" : "password"}
                                label={t("security_settings.old_password_title")}
                                autoComplete="new-password"
                                helperText={fieldsErrors.repeatPassword ? fieldsErrors.repeatPassword.message : null}
                                error={!!fieldsErrors.repeatPassword}
                                {...field}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <Button
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowRepeatPassword}
                                            >
                                                {showRepeatPassword
                                                    ? <Typography variant="body2">
                                                        {t("security_settings.hide")}
                                                    </Typography>
                                                    : <Typography variant="body2">
                                                        {t("security_settings.show")}
                                                    </Typography>}
                                            </Button>
                                        </InputAdornment>
                                    )
                                }}
                            />}
                        control={control}
                        defaultValue=""
                        rules={{
                            required: { value: true, message: `${t("security_settings.required_error")}` },
                            minLength: { value: 8, message: `${t("security_settings.short_error")}` },
                            pattern: {
                                value: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/,
                                message: t("signin.error_field_password_not_valid")
                            },
                            validate: value =>
                                value === password.current || `${t("security_settings.match_error")}`
                        }}
                    />
                </FormControl>
            </Box>
            {generalLoginError && <div>{generalLoginError}</div>}
            <Button variant="contained" color="primary" type="submit" disableElevation>
                {t("security_settings.save_button")}
            </Button>
            <Snackbar open={openSuccesSnackbar} autoHideDuration={3000} onClose={handleCloseSuccessSnackbar}>
                <Alert onClose={handleCloseSuccessSnackbar} severity="success">
                    {t("security_settings.success_snackbar")}
                </Alert>
            </Snackbar>
            <Snackbar open={openFailureSnackbar} autoHideDuration={3000} onClose={handleCloseFailureSnackbar}>
                <Alert onClose={handleCloseFailureSnackbar} severity="error">
                    {t("security_settings.error_snackbar")}
                </Alert>
            </Snackbar>
        </form>
    );
}