import { useFirebase } from '../context/firebase-context';

export function useGroupDetails() {
    const { db, storage } = useFirebase();

    const updateGroupDetails = (group, groupImage, { successCallback }) => {
      const {id, ...auxGroup} = group;

      if (groupImage) {
        var storageRef = storage.ref();
        var storageEventRef = storageRef.child('eventLogos/' + group.eventId + '/' + Date.now().toString());
        storageEventRef.put(groupImage).then(function( snapshot ) {
          snapshot.ref.getDownloadURL().then((url) => {
            auxGroup.imageUrl = url;
            updateFirestoreGroupDetails(group.id, auxGroup, url, { successCallback: successCallback});
          })
          .catch(err => {
            successCallback(false);
          });
        })
        .catch(err => {
          successCallback(false);
        });
      } else {
        updateFirestoreGroupDetails(group.id, auxGroup, null, { successCallback: successCallback});
      }
  };

  const updateFirestoreGroupDetails = (idGroup, group, imageUrl, { successCallback }) => {
    db.collection('groups')
        .doc(idGroup).update(group)
        .then(function() {
          if (imageUrl) {
            updateSessionsImages(idGroup, imageUrl);
          }
          successCallback(true);
        })
        .catch(err => {
          successCallback(false);
        });
  };

  const updateSessionsImages = (idGroup, imageUrl) => {
    db.collection('sessions')
        .where('groupId', '==', idGroup)
        .get()
        .then(function(doc) {
          doc.docs.forEach(element => {
            element.ref.update({groupImageUrl: imageUrl});
          });
        });
  };

  const deleteGroup = (groupId, { successCallback }) => {
    db.collection('groups')
        .doc(groupId).delete().then(function() {
          successCallback();
        })
        .catch(err => {
        });
  };

    return{
      updateGroupDetails,
      deleteGroup
    }

}