import React, { useEffect, useState } from 'react';
import { useForm, Controller } from "react-hook-form";
import { useFirebase } from '../shared/context/firebase-context';
import { useHistory } from 'react-router-dom';
import { Box, Typography, ButtonGroup, Button, FormControl, InputAdornment, TextField, Snackbar } from '@material-ui/core';
import { useAuth } from '../shared/hooks/useAuth';
import Divider from '@material-ui/core/Divider';
import { useTranslation } from "react-i18next";
import Alert from '@material-ui/lab/Alert';
import { makeStyles, } from '@material-ui/core/styles';


const useStyles = makeStyles((theme) => ({
    errorText: {
        color: 'red',
    },
    emailInput: {
        marginTop: '20px',
    },
    passwordInput: {
        marginTop: '10px',
    },
    forgotButton: {
        fontSize: 13,
        fontWeight: 600,
        textTransform: 'none',
    },
    button: {
        fontSize: 17,
        borderWidth: '1.5px',
        borderColor: 'black'
    },
    languageButton: {
        fontSize: 15,
        borderColor: 'primary',
        color: 'black',
        textTransform: 'none' 
    },
    showButton: {
        fontSize: 13,
        textTransform: 'none',
    },
    divider: {
        backgroundColor: 'black',
        height: '0.1px'
    },
    languageImg: {
        width: '25px'
    },
}));

export function Login() {
    const { login } = useAuth();
    const { auth } = useFirebase();
    const { t, i18n } = useTranslation();
    const history = useHistory();
    const classes = useStyles();

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged(function (user) {
            if (user) {
                history.push('/');
            }
        });

        return () => unsubscribe();
    }, []);

    function handleLanguage(lang) {
        i18n.changeLanguage(lang);
    }

    const { handleSubmit, control, formState: { errors: fieldsErrors } } = useForm();

    const [showPassword, setShowPassword] = useState(false);
    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const onSubmit = data => {
        setPasswordError(false);
        login(data.email, data.password)
            .then(function () {
                history.push(`/`);
            })
            .catch(function (error) {
                if (error.code == "auth/user-not-found") {
                    setOpenNoUserSnackBar(true);
                } else if (error.code == "auth/wrong-password") {
                    setPasswordError(true);
                } else {
                    setOpenUnknownSnackbar(true);
                }
            });
    };

    const [passwordError, setPasswordError] = useState(false);

    const [openUnknownSnackbar, setOpenUnknownSnackbar] = useState(false);
    const handleCloseUnknownSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenUnknownSnackbar(false);
    };

    const [openNoUserSnackbar, setOpenNoUserSnackBar] = useState(false);
    const handleCloseNoUserSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenNoUserSnackBar(false);
    };

    const handleRecoverPass = () => {
        history.push('/recoverpassword');
    };

    const handleSignIn = () => {
        history.push('/register');
    };

    return (
        <main>
            <Box display="flex" width="100%" alignItems="center" flexDirection="column">
                <img src="/assets/images/wemit.png" width="120" style={{ margin: "30px" }} />
                <ButtonGroup color="black" aria-label="outlined primary button group" style={{ marginTop: '10px', right: '15px', position: 'absolute'}}>
                <Button className={classes.languageButton} onClick={() => handleLanguage("es")}>
                        <img src="/assets/images/es.svg" alt='draft color' className={classes.languageImg} />
                    </Button>
                        <Button className={classes.languageButton} onClick={() => handleLanguage("en")}>
                        <img src="/assets/images/en.svg" alt='draft color' className={classes.languageImg} />
                    </Button>
                </ButtonGroup>
                <Divider width="100%" />
            </Box>
            <Box display="flex" width="100%" alignItems="center" flexDirection="column" style={{ marginTop: "40px" }}>
                <Box display="flex" alignItems="center" flexDirection="column" style={{ minWidth: "200px", maxWidth: "400px" }}>
                    <Typography variant="h1" >
                        {t("login.title")}
                    </Typography>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <FormControl fullWidth margin="normal">
                            <Controller
                                name="email"
                                render={({ field }) =>
                                    <TextField
                                        className={classes.emailInput}
                                        id="email"
                                        labelwidth={30}
                                        helperText={fieldsErrors.email ? fieldsErrors.email.message : null}
                                        label={t("login.title_email")}
                                        error={!!fieldsErrors.email}
                                        {...field}
                                    />}
                                control={control}
                                defaultValue=""
                                rules={{
                                    required: t("login.error_field_required")
                                }}
                            />
                        </FormControl>
                        <FormControl fullWidth margin="normal">
                            <Controller
                                name="password"
                                render={({ field }) =>
                                    <TextField
                                        className={classes.passwordInput}
                                        id="password"
                                        labelwidth={70}
                                        type={showPassword ? "text" : "password"}
                                        autoComplete="off"
                                        helperText={fieldsErrors.password ? fieldsErrors.password.message : null}
                                        label={t("login.title_password")}
                                        error={!!fieldsErrors.password}
                                        {...field}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <Button
                                                        className={classes.showButton}
                                                        style={{ backgroundColor: 'transparent' }}
                                                        disableRipple
                                                        aria-label="toggle password visibility"
                                                        onClick={handleClickShowPassword}
                                                        onMouseDown={handleMouseDownPassword}>
                                                        {showPassword
                                                            ? <div>
                                                                {t("security_settings.hide")}
                                                            </div>
                                                            : <div>
                                                                {t("security_settings.show")}
                                                            </div>}
                                                    </Button>
                                                </InputAdornment>
                                            )
                                        }}
                                    />}
                                control={control}
                                defaultValue=""
                                rules={{
                                    required: t("login.error_field_required")
                                }}
                            />
                        </FormControl>
                        <Typography variant="h3" className={classes.errorText}>
                            {passwordError ? t("login.password_error") : ""}
                        </Typography>
                        <Box display="flex" width="100%" alignItems="center" flexDirection="column" style={{ marginTop: "40px" }}>
                            <Button
                                className={classes.button}
                                type="submit"
                                variant="contained"
                                color="primary"
                                width="200"
                                disableElevation
                                style={{ maxWidth: '25em', minWidth: '25em', textTransform: 'none' }}>
                                {t("login.button_login")}
                            </Button>
                            <Box mt={1} mb={3}>
                                <Button
                                    className={classes.forgotButton}
                                    onClick={handleRecoverPass}
                                    color="primary"
                                    width="200" >
                                    {t("login.button_forgot_password")}
                                </Button>
                            </Box>
                            <Divider width="100%" className={classes.divider} />
                            <Box mt={3} mb={3}>
                                <Typography variant="h6">
                                    {t("login.title_new_user")}
                                </Typography>
                            </Box>
                            <Button
                                className={classes.button}
                                variant="outlined"
                                color="black"
                                onClick={handleSignIn}
                                style={{ maxWidth: '25em', minWidth: '25em', textTransform: 'none' }}>
                                {t("login.button_new_user")}
                            </Button>
                        </Box>
                    </form>
                </Box>
            </Box>
            <Snackbar open={openUnknownSnackbar} autoHideDuration={3000} onClose={handleCloseUnknownSnackbar}>
                <Alert onClose={handleCloseUnknownSnackbar} severity="error">
                    {t("login.error_snackbar")}
                </Alert>
            </Snackbar>
            <Snackbar open={openNoUserSnackbar} autoHideDuration={6000} onClose={handleCloseNoUserSnackbar}>
                <Alert onClose={handleCloseNoUserSnackbar} severity="error">
                    {t("login.no_user_snackbar")}
                </Alert>
            </Snackbar>
        </main>
    );
}