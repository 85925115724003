import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { useAdmin } from '../shared/context/admin-context';
import { useFirebase } from '../shared/context/firebase-context';
import { Loading } from './Loading';

export function PrivateRoute({ children, ...others }) {
    const { user } = useFirebase();
    const { userDetails } = useAdmin();

    if (user) {
        if (user.emailVerified) {
            if (userDetails && userDetails.isAdmin) {
                return (
                    <Route {...others}> {children} </Route>
                );
            } else if (userDetails && !userDetails.isAdmin) {
                return (
                    <Route {...others}><Redirect to="/createAdmin" /></Route>
                );
            } else {
                return (
                    <Route {...others}><Loading /></Route>
                );
            }
        } else {
            return (
                <Route {...others}><Redirect to="/activate" /></Route>
            );
        }
    } else {
        return (
            <Route {...others}><Redirect to="/login" /></Route>
        );
    }
}