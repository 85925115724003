import { useFirebase } from '../context/firebase-context';
import { useAdmin } from '../context/admin-context';

export function useAddGroup() {
  const { db, storage, fromDate } = useFirebase();
  const { event } = useAdmin();

  const saveGroup = (name, description, userList, isPrivate, topics, draft, groupImage, { successCallback }) => {
    const group = {
      eventId: event.id,
      name: name,
      participants: (userList !== undefined) ? userList : null,
      description: description,
      isPrivate: isPrivate,
      topics: topics,
      imageUrl: "",
      isDraft: draft,
      updatedDate: fromDate(new Date())
    };
    if (groupImage) {
      var storageRef = storage.ref();
      var storageEventRef = storageRef.child('eventLogos/' + group.eventId + '/' + Date.now().toString());
      storageEventRef.put(groupImage).then(function (snapshot) {
        snapshot.ref.getDownloadURL().then((url) => {
          group.imageUrl = url;
          updateFirestoreGroupDetails(group, { successCallback: successCallback });
        })
          .catch(err => {
            successCallback(false);
          });
      })
        .catch(err => {
          successCallback(false);
        });
    } else {
      updateFirestoreGroupDetails(group, { successCallback: successCallback });
    }
  };
  
  const updateFirestoreGroupDetails = (group, { successCallback }) => {
    db.collection('groups')
      .add(group)
      .then(function () {
        successCallback(true, group.imageUrl);
        if(!group.isPrivate){
        addUserActivity(group);
        }
      })
      .catch(err => {
        successCallback(false);
      });
  };

  const addUserActivity = (group) => {
    const activity = {
      name: event.name,
      type: 'group',
      infoText: group.name,
      infoImageUrl: (group.imageUrl === undefined) ? '' : group.imageUrl ,
      imageUrl: event.imageUrl,
      time: fromDate(new Date())
    };

    event.participants.forEach(participant => {
      db.collection('users')
        .doc(participant)
        .collection('activities')
        .add(activity)
        .then(function () {
        })
        .catch(err => {
        });
    });
  };

  return {
    saveGroup
  }

}