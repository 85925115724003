import React, { useEffect, useState, useRef } from 'react';
import { useForm, Controller } from "react-hook-form";
import { useFirebase } from '../shared/context/firebase-context';
import { useHistory } from 'react-router-dom';
import { Box, Typography, ButtonGroup, Button, FormControl, InputAdornment, TextField, Link, Checkbox, Divider, Snackbar, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import { useAuth } from '../shared/hooks/useAuth';
import { useTranslation } from "react-i18next";
import { Terms } from './Terms';
import { Privacy } from './Privacy';
import { useAdmin } from '../shared/context/admin-context';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export function Signin() {
    const { register } = useAuth();
    const { user } = useFirebase();
    const { userDetails } = useAdmin()
    const { t, i18n } = useTranslation();
    const history = useHistory();

    useEffect(() => {
        if (user && !user.emailVerified) {
            history.push('/activate');
        } else if (user && userDetails && !userDetails.isAdmin) {
            history.push('/createAdmin');
        } else if (user && userDetails && userDetails.isAdmin) {
            history.push('/');
        }
    }, [userDetails]);

    function handleLanguage(lang) {
        setHelperTermsError('');
        i18n.changeLanguage(lang);
    }

    const { handleSubmit, control, formState: { errors: fieldsErrors } } = useForm();

    const [showPassword, setShowPassword] = useState(false);
    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const [checked, setChecked] = useState({
        terms: true,
        marketing: false
    });
    const [helperTermsError, setHelperTermsError] = useState('');

    const handleChangeCheck = (event) => {
        setChecked({ ...checked, [event.target.name]: event.target.checked });
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const [openFailureSnackbar, setOpenFailureSnackbar] = useState(false);
    const handleCloseFailureSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
    
        setOpenFailureSnackbar(false);
    };

    const [openDialog, setOpenDialog] = useState(false);
    const [typeDialog, setTypeDialog] = useState('privacy');

    const handleClickOpenDialog = (dialogType) => () => {
        setOpenDialog(true);
        setTypeDialog(dialogType);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const descriptionElementRef = useRef(null);
    useEffect(() => {
        if (openDialog) {
            const { current: descriptionElement } = descriptionElementRef;
            if (descriptionElement !== null) {
                descriptionElement.focus();
            }
        }
    }, [openDialog]);

    const onSubmit = data => {
        if (checked.terms) {
            register(data.email, data.password, data.name, data.surname, checked.marketing, i18n.language, { successCallback: () =>  history.push('/activate'), failureCallback: failureCallback});
            setHelperTermsError('');
        }
    };

    const failureCallback = (errorCode) => {
        if (errorCode === 'auth/email-already-in-use') {
            setHelperTermsError(t('signin.error_user_exists'));
        } else {
            setOpenFailureSnackbar(true);
        }
    }

    const handleLogin = () => {
        history.push(`/login`);
    };

    const handleRegister = () => {
        if (!checked.terms) {
            setHelperTermsError(t('signin.error_terms_required'));
        } else {
            setHelperTermsError('');
        }
    };

    return (
        <main>
            <Box display="flex" width="100%" alignItems="center" flexDirection="column" >
                <img src="/assets/images/wemit.png" alt="wemit logo" width="120" style={{margin: "25px"}}/>
                <ButtonGroup variant="text" color="primary" aria-label="text primary button group" style={{ marginTop: '5px', right: '5px', position: 'absolute' }}>
                    <Button onClick={() => handleLanguage("es")}>
                        ES
                    </Button>
                    <Button onClick={() => handleLanguage("en")}>
                        EN
                    </Button>
                </ButtonGroup>
                <Divider width="100%"/>
            </Box>
            <Box display="flex" width="100%" alignItems="center" flexDirection="column" style={{marginTop: "40px"}}>
                <Box display="flex" alignItems="center" flexDirection="column" style={{minWidth: "200px", maxWidth: "410px"}}>
                    <Typography variant="h1" >
                        {t("signin.title")}
                    </Typography>
                    <Box flexDirection="comumn" style={{display: 'flex', marginTop: "10px"}}>
                        <Typography variant="body1" style={{marginRight: "5px"}}>
                            {t("signin.subtitle")}
                        </Typography>
                        <Link href="#" onClick={handleLogin} color="primary" variant="body1">
                            {t("signin.login_link")}
                        </Link>
                    </Box>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <FormControl fullWidth margin="normal">
                            <Controller
                                name="email"
                                render={({ field }) =>
                                    <TextField
                                        id="email"
                                        labelwidth={70}
                                        helperText={fieldsErrors.email ? fieldsErrors.email.message : null}
                                        label={t("signin.title_email")}
                                        error={!!fieldsErrors.email}
                                        {...field}
                                    />}
                                control={control}
                                defaultValue=""
                                rules={{
                                    required: t("signin.error_field_required"),
                                    pattern: {
                                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                        message: t("signin.error_field_email_not_valid")
                                    }
                                }}
                            />
                        </FormControl>
                        <FormControl fullWidth margin="normal">
                            <Controller
                                name="name"
                                render={({ field }) =>
                                    <TextField
                                        id="name"
                                        labelwidth={70}
                                        helperText={fieldsErrors.name ? fieldsErrors.name.message : null}
                                        label={t("signin.title_name")}
                                        error={!!fieldsErrors.name}
                                        {...field}
                                    />}
                                control={control}
                                defaultValue=""
                                rules={{
                                    required: t("signin.error_field_required")
                                }}
                            />
                        </FormControl>
                        <FormControl fullWidth margin="normal">
                            <Controller
                                name="surname"
                                render={({ field }) =>
                                    <TextField
                                        id="surname"
                                        labelwidth={70}
                                        helperText={fieldsErrors.surname ? fieldsErrors.surname.message : null}
                                        label={t("signin.title_surname")}
                                        error={!!fieldsErrors.surname}
                                        {...field}
                                    />}
                                control={control}
                                defaultValue=""
                                rules={{
                                    required: t("signin.error_field_required")
                                }}
                            />
                        </FormControl>
                        <FormControl fullWidth margin="normal">
                            <Controller
                                name="password"
                                render={({ field }) =>
                                    <TextField
                                        id="password"
                                        labelwidth={70}
                                        type={showPassword ? "text" : "password"}
                                        autoComplete="off"
                                        helperText={fieldsErrors.password ? fieldsErrors.password.message : null}
                                        label={t("signin.title_password")}
                                        error={!!fieldsErrors.password}
                                        {...field}
                                        InputProps={{ 
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <Button
                                                        aria-label="toggle password visibility"
                                                        onClick={handleClickShowPassword}
                                                        onMouseDown={handleMouseDownPassword}>
                                                        {showPassword
                                                                ? <Typography variant="body2">
                                                                    {t("security_settings.hide")}
                                                                </Typography>
                                                                : <Typography variant="body2">
                                                                    {t("security_settings.show")}
                                                                </Typography>}
                                                    </Button>
                                                </InputAdornment>
                                            )
                                        }}
                                    />}
                                control={control}
                                defaultValue=""
                                rules={{
                                    required: t("signin.error_field_required"),
                                    pattern: {
                                        value: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/,
                                        message: t("signin.error_field_password_not_valid")
                                    }
                                }}
                            />
                        </FormControl>
                        <Typography variant="body2" style={{marginRight: "5px"}}>
                            {t("signin.title_password_requirements")}
                        </Typography>
                        <Box display="flex" width="100%" alignItems="center" flexDirection="column">
                            <Box mt={3} mb={3}>
                                <Box flexDirection="comumn" style={{display: 'flex'}}>
                                    <Checkbox
                                        checked={checked.terms}
                                        onChange={handleChangeCheck}
                                        name="terms"
                                        color="primary"
                                    />
                                    <Box>
                                        <Typography variant="body2">
                                            {t("signin.text_privacy_terms")}
                                        </Typography>
                                        <Box flexDirection="comumn" style={{display: 'flex'}}>
                                            <Link href="#" onClick={handleClickOpenDialog("terms")} color="primary" variant="body2">
                                                {t("signin.link_terms")}
                                            </Link>
                                            <Typography variant="body2" style={{marginLeft: "5px", marginRight: "5px"}}>
                                                {t("signin.link_privacy_terms_connector")}
                                            </Typography>
                                            <Link href="#" onClick={handleClickOpenDialog("privacy")} color="primary" variant="body2">
                                                {t("signin.link_privacy")}
                                            </Link>
                                        </Box>
                                    </Box>
                                </Box>
                                <Box mt={1} flexDirection="comumn" style={{display: 'flex'}}>
                                    <Checkbox
                                        checked={checked.marketing}
                                        onChange={handleChangeCheck}
                                        name="marketing"
                                        color="primary"
                                    />
                                    <Typography variant="body2">
                                        {t("signin.text_marketing_consent")}
                                    </Typography>
                                </Box>
                                <Typography variant="body2" style={{color: "#FF0000", marginTop: "10px"}}>
                                    {helperTermsError}
                                </Typography>
                            </Box>
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                width="200"
                                onClick={handleRegister} 
                                disableElevation
                                style={{maxWidth: '25em', minWidth: '25em', marginTop: "10px"}}>
                                    {t("signin.button_new_user")}
                            </Button>
                        </Box>
                    </form>
                </Box>
            </Box>
            <Snackbar open={openFailureSnackbar} autoHideDuration={3000} onClose={handleCloseFailureSnackbar}>
                <Alert onClose={handleCloseFailureSnackbar} severity="error">
                    {t("signin.error_general_retry")}
                </Alert>
            </Snackbar>
            <Dialog open={openDialog} onClose={handleCloseDialog} scroll="body" maxWidth="lg" aria-labelledby="scroll-dialog-title" aria-describedby="scroll-dialog-description" >
                <DialogTitle id="scroll-dialog-title">
                    <Typography variant="h1">{typeDialog === "terms" ? t("signin.link_terms") : t("signin.link_privacy")}</Typography>
                </DialogTitle>
                <DialogContent dividers={false} width="70%">
                    <DialogContentText id="scroll-dialog-description" ref={descriptionElementRef} tabIndex={-1} >
                    {typeDialog === "terms" ? <Terms></Terms> : <Privacy></Privacy>}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog} color="primary">
                        {t("signin.button_close")}
                    </Button>
                </DialogActions>
            </Dialog>	
		</main>
    );
}