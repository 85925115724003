import React, { useState } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import { useTranslation } from "react-i18next";
import { Checkbox, Table, TableContainer, TableBody, TableHead, Typography, Button, TableCell, TableRow } from '@material-ui/core';
import { useUsers } from '../shared/hooks/useUsers';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import { useGroupSubscribers } from '../shared/hooks/useGroupSubscribers';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';


const useStyles = makeStyles((theme) => ({
    avatar: {
        marginRight: 20,
        width: "55px",
        height: "55px"
    },
    user_name: {
        marginBottom: "0px",
        fontWeight: 600,
        fontSize: 14,
    },
    user_email: {
        marginTop: "5px"
    },
    button: {
        marginLeft: "30px"
    },
    table_title_status: {
        color: "DarkGray",
        fontWeight: "bold",
        fontSize: "17px"
    },
    table_title_users: {
        marginLeft: "15px",
        color: "DarkGray",
        fontWeight: "bold",
        fontSize: "17px"
    },
    dialog_title: {
        marginTop: "10px",
    },
    dialog_subtitle: {
        marginLeft: "25px",
        marginBottom: "20px",
        width: "90%",
        marginTop: "10px",
    },
    table_users: {
        marginLeft: "15px"
    },
    table_status: {
        marginTop: "0px"
    },
    dialog_actions: {
        marginTop: "15px",
        margin: "10px"
    }

}));

export function InviteSubscribersDialog(props) {
    const { openDialog, setOpenDialog, isNewGroup, closeCallback, adminUser, group } = props;
    const { t } = useTranslation();
    const classes = useStyles();
    const [openFailureSnackbar, setOpenFailureSnackbar] = useState(false);
    const { subscribers, updatePrivateGroupUsers } = useGroupSubscribers();
    const { users, sendPrivateGroupNotification } = useUsers();
    const [ filter, setFilter ] = useState(null);

    var checkedUsers = (isNewGroup) ? [adminUser] : subscribers; //List of checked users
    var checkedIds = (isNewGroup) ? [adminUser.id] : []; //Ids for checked users, needed for enable or disable the checkbox
    subscribers.forEach(subscriber => {
        checkedIds.push(subscriber.id);
    });
    var initialUsers = [];
    subscribers.forEach(subscriber => {
        initialUsers.push(subscriber);
    });

    const handleCloseFailureSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenFailureSnackbar(false);
    };

    const handleUserInvited = (e, user) => {
        if (e.target.checked) {
            checkedUsers.push(user);
            checkedIds.push(user.id);
        } else {
            const index = checkedIds.indexOf(user.id);
            if (index > -1) {
                checkedUsers.splice(index, 1);
                checkedIds.splice(index, 1);
            }
        }
    };

    function sendInvitations() {
        setFilter(null);
         if (isNewGroup) {
            closeCallback(checkedIds);
        } else {
            updatePrivateGroupUsers(checkedIds, { successCallback: (success) => { success ? console.log("success") : setOpenFailureSnackbar(true) } });
            var invitationList = [];
            checkedUsers.forEach( user => {
                if(!initialUsers.includes(user)){
                    invitationList.push(user.id);
                }
            })
            if (invitationList.length > 0) {
                sendPrivateGroupNotification(invitationList, group, { successCallback: (success) => { success ? closeCallback(checkedIds) : setOpenFailureSnackbar(true) } });
            } else {
                setOpenDialog(false);
            }
        } 
    }

    function isSelected(user) {
        return (checkedIds.includes(user.id));
    }

    function handleCancel() {
        if(isNewGroup){
            closeCallback([]);
        } else {
            setFilter(null);
            checkedUsers.splice(0, checkedUsers.length);
            checkedIds.splice(0, checkedIds.length);
            initialUsers.forEach( user => {
                checkedUsers.push(user);
                checkedIds.push(user.id);
            });
            setOpenDialog(false);
        }
    }

    function handleFilteredUsers(value){
        setFilter(value);
    }

    function handleAutocompleteOptions(){
        const g = [];
        users.map((user) => {
            if(user.name !== undefined){
            if(!g.includes(user.name)){
                g.push(user.name);
            }
        }
        })
        return g;
    }

    return (
        <Dialog
            open={openDialog}
            onClose={setOpenDialog}
            maxWidth={"lg"}
            fullWidth={false}
            scroll={"paper"}
        >
            <DialogTitle className={classes.dialog_title}><Typography variant="h1">{t("invite_subscribers_dialog.title")}</Typography></DialogTitle>
            <Typography variant="h3" className={classes.dialog_subtitle}> {t("invite_subscribers_dialog.description")} </Typography>
            <DialogContent >
                <Grid
                    container
                    direction="row"
                    justify="flex-start"
                    alignItems="center"
                >
                    <TableContainer >
                    <Autocomplete
                        onChange={(event, value) => handleFilteredUsers(value)}
                        id="free-solo"
                        options={(users.length > 0) ? handleAutocompleteOptions() : ''}
                        getOptionLabel={(option) => (option !== undefined ) ? option.toString() : ''}
                        style={{ width: 900 }}
                        renderInput={(params) => (
                            <TextField {...params} label="Filtrar por nombre" margin="normal" variant="outlined" />)}
                    />
                        <Table size="big" aria-label="group_users_table">
                            <TableHead>
                                <TableRow>
                                    <TableCell><Typography className={classes.table_title_users}>{t("invite_subscribers_dialog.user_row_title")}</Typography></TableCell>
                                    <TableCell><Typography className={classes.table_title_status} >{t("invite_subscribers_dialog.invite_row_title")}</Typography></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody >
                                {(users.length === 0)
                                    ? <div
                                        className={classes.noUsers_message_div}>
                                        <Typography variant="h3">{t("group_subscribers.noUsersMessage")}</Typography>
                                    </div>
                                    : users.map((user, index) => (
                                        ((!checkedIds.includes(user.id))&&((filter === null) || (user.name === filter)))
                                        &&
                                        <TableRow key={user.name + index}>
                                            <TableCell component="th" scope="row">
                                                <Grid
                                                    container
                                                    direction="row"
                                                    justify="flex-start"
                                                    alignItems="center"
                                                    className={classes.table_users}
                                                >
                                                    <Avatar className={classes.avatar} alt={user.name} src={user.imageUrl ?? ''} />
                                                    <Grid item>
                                                        <p className={classes.user_name}>{user.name} {user.surname}</p>
                                                        <p className={classes.user_email}>{user.email}</p>
                                                    </Grid>
                                                </Grid>
                                            </TableCell>
                                            <TableCell >
                                                <Checkbox className={classes.table_status} defaultChecked={isSelected(user)} onChange={value => handleUserInvited(value, user)} name={user.name} color="primary" />
                                            </TableCell>
                                        </TableRow>
                                     ))}
                                    {(checkedUsers.length > 0)
                                        && 
                                        checkedUsers.map((user, index) => (
                                            ((filter === null) || (user.name ===filter))
                                            && 
                                            <TableRow key={user.name + index}>
                                            <TableCell component="th" scope="row">
                                                <Grid
                                                    container
                                                    direction="row"
                                                    justify="flex-start"
                                                    alignItems="center"
                                                    className={classes.table_users}
                                                >
                                                    <Avatar className={classes.avatar} alt={user.name} src={user.imageUrl ?? ''} />
                                                    <Grid item>
                                                        <p className={classes.user_name}>{user.name} {user.surname}</p>
                                                        <p className={classes.user_email}>{user.email}</p>
                                                    </Grid>
                                                </Grid>
                                            </TableCell>
                                            <TableCell >
                                                <Checkbox className={classes.table_status} defaultChecked={isSelected(user)} onChange={value => handleUserInvited(value, user)} name={user.name} color="primary" />
                                            </TableCell>
                                        </TableRow>
                                           
                                        ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </DialogContent>
            <DialogActions className={classes.dialog_actions}>
                <Button
                    className={classes.button}
                    variant="contained"
                    disableElevation
                    color="secondary"
                    onClick={() => handleCancel()}>
                    {(isNewGroup) ? t("invite_subscribers_dialog.button_later") : t("invite_subscribers_dialog.button_cancel")}
                </Button>
                <Button
                    className={classes.button}
                    variant="contained"
                    disableElevation
                    color="primary"
                    onClick={() => sendInvitations()}>
                    {t("invite_subscribers_dialog.button_accept")}
                </Button>
            </DialogActions>
            <Snackbar open={openFailureSnackbar} autoHideDuration={3000} onClose={handleCloseFailureSnackbar}>
                <Alert onClose={handleCloseFailureSnackbar} severity="error">
                    {t("add_users_dialog.invitation_error")}
                </Alert>
            </Snackbar>
        </Dialog>

    )
}