import React, { useContext, useState, useEffect, useMemo } from 'react';
import app from 'firebase/app';
import firebase from 'firebase/app';
import 'firebase/functions';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';

const config = {
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_DATABASE_URL,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_APP_ID,
    measurementId: process.env.REACT_APP_MEASUREMENT_ID,
};

app.initializeApp(config);

const FirebaseContext = React.createContext(null);

export function FirebaseProvider({ children }) {
    const [user, setUser] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const setEmailCredential = useMemo(() => app.auth.EmailAuthProvider.credential, []);
    const auth = useMemo(() => app.auth(), [app.auth().currentUser]);
    const db = useMemo(() => app.firestore(), []);
    const fromDate = useMemo(() => app.firestore.Timestamp.fromDate, []);
    const arrayUnion = useMemo(() => app.firestore.FieldValue.arrayUnion, []);
    const arrayRemove = useMemo(() => app.firestore.FieldValue.arrayRemove, []);
    const storage = useMemo(() => app.storage(), []);
    const functions = useMemo(() => firebase.app().functions('europe-west1'), []);

    useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(function(user) {
        if (user) {
            setUser(user);
        } else {
            setUser(null);
        }
        setIsLoading(false);
    });

    return () => unsubscribe();
    }, [auth]);

    return (
    <FirebaseContext.Provider
        value={{
        auth,
        db,
        fromDate,
        arrayUnion,
        arrayRemove,
        storage,
        functions,
        user,
        setEmailCredential,
        isLoading
    }}
    >
        {children}
    </FirebaseContext.Provider>
    );
}

export function useFirebase() {
    const context = useContext(FirebaseContext);
    return context;
}