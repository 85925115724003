import React from 'react';
import { useGroups } from '../shared/hooks/useGroups';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import QueueOutlined from '@material-ui/icons/QueueOutlined';
import { Box, Grid, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        overflow: 'hidden',
        backgroundColor: theme.palette.background.paper,
    },
    gridList: {
        width: "100%",
        height: "100%",
        backgroundColor: '#f2efea',
        paddingLeft: "20px"
    },
    listItem: {
        borderRadius: 10,
        overflow: "hidden",
    },
    img: {
        borderLeft: '1px solid #bbbbbb',
        borderRight: '1px solid #bbbbbb',
        borderTop: '1px solid #bbbbbb',
        borderTopLeftRadius: 10,
        borderTopRightRadius: 10,
        width: "300px",
        height: "200px",
        objectFit: 'cover',
        marginBlock: -5,
    },
    draftImg: {
        width: "16px",
        objectFit: 'cover',
    },
    draftBox: {
        backgroundColor: "white",
        width: "auto",
        height: "45px",
        border: '1px solid #bbbbbb',
        borderBottomLeftRadius: 10,
        borderBottomRightRadius: 10,
    },
    transparentdraftBox: {
        backgroundColor: "transparent",
        width: "auto",
        height: "1px",
        border: '1px dashed #969696',
        borderTop: 'none',
        borderBottomLeftRadius: 10,
        borderBottomRightRadius: 10,
    },
    addImg: {
        border: '1px dashed #969696',
        borderRadius: 10,
        backgroundColor: 'rgba(52, 52, 52, 0.1)',
        width: "300px",
        height: "245px",
        objectFit: 'cover',
        color: "#969696",
        padding: "80px",
        marginBlock: -5,
    },
    icon: {
        color: 'rgba(255, 255, 255, 0.54)',
    },
}));

function useWindowDimensions() {
    const [width, setWidth] = React.useState(window.innerWidth);

    const updateWidthAndHeight = () => {
        setWidth(window.innerWidth);
    };

    React.useEffect(() => {
        window.addEventListener("resize", updateWidthAndHeight);
        return () => window.removeEventListener("resize", updateWidthAndHeight);
    });

    return {
        width,
    }
}

export function GroupList() {
    const { width } = useWindowDimensions()
    const classes = useStyles();
    const { groupList } = useGroups();
    const history = useHistory();
    const { t } = useTranslation();

    const handleNewGroup = () => {
        history.push("/nuevogrupo");
    };

    const handleSelectGroup = (group) => {
        history.push(`/grupos/${group.id}`);
    };

    function getColums() {
        console.log(window.innerWidth);
        console.log(Math.floor(width / 300));
        return Math.floor(window.innerWidth / 300);
    };

    return (
        <div className={classes.root}>
            <style>{'body { background-color: #f2efea; }'}</style>
            <GridList className={classes.gridList} cellHeight={290} cols={getColums()} spacing={5} >
                {groupList.map((group) => (
                    <GridListTile key={group.id}>
                        <Box pt={1} style={{ width: "100%", height: "100%", }}>
                            <Grid container direction="column" justify="flex-start"
                                alignItems="flex-start" style={{ width: "100%", height: "100%", }}>
                                <Grid item item style={{ cursor: 'pointer' }} onClick={() => { handleSelectGroup(group) }}>
                                    <img src={(group.imageUrl !== "") ? group.imageUrl : "/assets/images/no_image.png"} alt={group.name} className={classes.img} />
                                    <Box pb={0.7} pt={1} pl={1.5} className={classes.draftBox}>
                                        <Grid container spacing="2" >
                                            <Grid item>
                                                {group.isDraft
                                                    ? <img src="/assets/images/draft_true.png" alt='draft color' className={classes.draftImg} />
                                                    : <img src="/assets/images/draft_false.png" alt='draft grey' className={classes.draftImg} />}
                                            </Grid>
                                            <Grid item>
                                                {(group.isPrivate) && <img src="/assets/images/lock.svg" alt='draft color' className={classes.draftImg} />}
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Grid>
                                <Grid item item style={{ cursor: 'pointer' }}>
                                    <Box pt={1} pl={0.4}>
                                        <Typography style={{ fontWeight: '500' }}>
                                            {group.name}
                                        </Typography>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    </GridListTile>
                ))}
                <div onClick={handleNewGroup}>
                    <GridListTile variant="outlined" color="secondary" aria-label="add group" onClick={console.log("")} item style={{ cursor: 'pointer' }}>
                        <Box pt={1} style={{ width: "100%", height: "100%", }}>
                            <Grid container direction="column" justify="flex-start"
                                alignItems="flex-start" style={{ width: "100%", height: "100%", }}>
                                <Grid item >
                                    <QueueOutlined className={classes.addImg} />
                                </Grid>
                                <Grid item style={{ cursor: 'pointer' }}>
                                    <Box pt={1} pl={0.4}>
                                        <Typography style={{ fontWeight: '400' }}>
                                            {t("group_list.add_group")}
                                        </Typography>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    </GridListTile>
                </div>
            </GridList>
        </div>
    );
}