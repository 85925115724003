import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { useTranslation } from "react-i18next";

export function Privacy() {
  const { i18n } = useTranslation();

  if (i18n.language === 'en') {
    return (
      <main>
        <Box>
          <Typography variant="body1">
              In accordance with the provisions of the regulations on Personal Data Protection, we inform you that by 
              registering in this application (WEMIT) you are providing your personal data to the Data Controller 
              KELEA EUROPEAN CENTER, S.L, hereinafter KELEA, with address at POLÍGONO DE POCOMACO, PRIMERA AVENIDA C10, 
              EDIFICIO ABANCA INNOVA 3ª PLANTA (SALA CURIE) - 15190 A CORUÑA (A CORUÑA) and email kelea@kelea.es.  

              We comply with the provisions of REGULATION (EU) 2016/679 OF THE EUROPEAN PARLIAMENT AND OF THE COUNCIL 
              of 27 April 2016 on the protection of natural persons with regard to the processing of personal data and 
              on the free movement of such data and repealing Directive 95/46/EC (General Data Protection Regulation or GDPR) 
              and in the Organic Law 3/2018 on the Protection of Personal Data and the guarantee of digital rights (LOPDGDDD). 
          </Typography>
          <br/>
          <Typography variant="h2">1. Purpose of processing</Typography>
          <Typography variant="body1">
              Wemit is an application that allows you to establish contacts with other users of your company or the event 
              you are attending and interact with them.  

              The data collected will be of two types:
          </Typography>
          <ul>
              <li>Identifying data</li>
              <li>Professional data</li>
              <li>Data on interests (thematic, professional, etc)</li>
          </ul>
          To register in the app we will only ask you for identification data (name, surname and, if applicable, an 
          email) but in order to get the most out of it we will ask for information about your professional and work 
          profile, your training and your professional interests.  

          Logically with this information we will elaborate a professional profile to offer you to contact those 
          users with whom you share common interests.  

          The data will be processed:
          <ul>
              <li>To manage your registration in the app.</li>
              <li>To establish a professional profile and interests</li>
              <li>So that you can establish contact with other users</li>
              <li>To perform analytics on the use of the app</li>
              <li>To send notifications related to the use of the app.</li>
          </ul>
          If you authorize us to do so, we will use your data to send you commercial communications by electronic means.
          <br/><br/>
          <Typography variant="h2">2. Legitimation of the processing of your personal data</Typography>
          <Typography variant="body1">
              The legitimacy to process your registration data is based on the consent you give us when you register in 
              the app and use it according to the terms and conditions established. We will also ask for your consent 
              to send you information by electronic means.  

              However, since we will need certain data in order to provide you with the service that the app consists of, 
              the basis of legitimacy will be the execution of the contract.  

              We rely on our legitimate interest to perform analytics of the website and its operation. These analytics 
              will be anonymous and will allow us to know the use of the app by users and work on improvements and new utilities.
          </Typography>
          <br/>
          <Typography variant="h2">3. Data Retention</Typography>
          <Typography variant="body1">
              We will keep your data as long as you remain registered in our application. If you stop using it for more than 4 
              years you will have to register again.  

              If you unsubscribe or delete the app, we will retain your data for 4 years in order to comply with our legal 
              obligations but the data will be blocked with appropriate safeguards.
          </Typography>
          <br/>
          <Typography variant="h2">4. Data recipients</Typography>
          <Typography variant="body1">
              We will not transfer data to third parties without prejudice to the fulfillment of our legal obligations. 
              You as a user will interact with other users of the app that interest you.  

              However, we work with service providers who may have access to the data. These service providers are 
              our data processors and we have entered into appropriate data processing agreements with them to ensure 
              the security of your data.  

              Some of these providers may be outside the European Union but have adopted the compliance guarantees 
              required by the GDPR. Specifically they are the following:
          </Typography>
          <ul>
              <li>Google Cloud</li>
          </ul>
          <br/>
          <Typography variant="h2">5. Rights</Typography>
          <Typography variant="body1"p>Finally, we inform you of your rights regarding data protection.

          </Typography>
          <ul>
              <li>Right to request access to your personal data.</li>
              <li>Right to request its rectification or deletion (right to be forgotten).</li>
              <li>The right to request the limitation of their processing, and to oppose their processing.</li>
              <li>Right to data portability.</li>
              <li>The right to withdraw your consent.</li>
              <li>Right not to be subject to an automated decision, including profiling (however please note that 
                  if you consent to use the application, its proper functioning will depend on the profile we 
                  develop to contact other users).</li> 
          </ul>
          To exercise your rights, simply make a request by contacting us at kelea@kelea.es. 

          KELEA has the appropriate forms for this purpose. You only have to request them or submit your own written 
          request to kelea@kelea.es.  

          You also have the right to file a complaint with the Spanish Data Protection Agency.  
          <br/><br/>
          <Typography variant="h2">6. Security Measures</Typography>
          <Typography variant="body1">KELEA has implemented the necessary technical and organizational measures at WEMIT to ensure the security 
              of your personal data and prevent its alteration, loss and unauthorized processing and/or access, taking 
              into account the state of technology, the nature of the data stored and the risks arising from human 
              action or the physical and natural environment to which they are exposed.    

              Updated in February 2021.
          </Typography>
        </Box>
      </main>
    );
  } else {
    return(
      <main>
        <Box>
          <Typography variant="body1">
              De acuerdo con lo establecido por la normativa de Protección de Datos de Carácter Personal, le informamos
              que
              al registrarse en esta aplicación (WEMIT) está facilitando sus datos de carácter personal al Responsable de
              Tratamiento KELEA EUROPEAN CENTER, S.L, en adelante KELEA, con dirección en POLÍGONO DE POCOMACO, PRIMERA
              AVENIDA C10, EDIFICIO ABANCA INNOVA 3ª PLANTA (SALA CURIE) – 15190 A CORUÑA (A CORUÑA) y con correo
              electrónico kelea@kelea.es.  

              Cumplimos con lo dispuesto en el REGLAMENTO (UE) 2016/679 DEL PARLAMENTO EUROPEO Y DEL CONSEJO de 27 de
              abril de 2016 relativo a la protección de las personas físicas en lo que respecta al tratamiento de datos
              personales y a la libre circulación de estos datos y por el que se deroga la Directiva 95/46/CE (Reglamento
              general de protección de datos o RGPD) y en la Ley Orgánica 3/2018 de Protección de Datos Personales y de
              garantía de derechos digitales (LOPDGDD). 
          </Typography>
          <br/>
          <Typography variant="h2">1. Finalidad del tratamiento</Typography>
          <Typography variant="body1">
              Wemit es una aplicación que permite establecer contactos con otros usuarios de tu empresa o del evento al
              que
              asista e interactuar con ellos.  

              Los datos recopilados serán de dos tipos:
          </Typography>
          <ul>
              <li>Datos identificativos</li>
              <li>Datos profesionales</li>
              <li>Datos sobre intereses (temáticas, profesionales, etc)</li>
          </ul>
          Para registrarse en la app sólo le vamos a solicitar datos identificativos (nombre, apellidos y en su caso, un
          email) pero para que pueda sacarle el máximo partido solicitaremos información sobre su perfil profesional y
          laboral, su formación y sus intereses profesionales.  

          Lógicamente con esta información elaboraremos un perfil profesional para ofrecerte contactar con aquellos
          usuarios con los que compartas intereses comunes.  

          Los datos serán tratados:
          <ul>
              <li>Para gestionar su registro en la app</li>
              <li>Para establecer un perfil profesional y de intereses</li>
              <li>Para que Vd. puede establecer contacto con otros usuarios</li>
              <li>Para realizar analíticas de uso de la app</li>
              <li>Enviar notificaciones relacionadas con el uso de la app</li>
          </ul>
          Si nos lo autoriza, utilizaremos sus datos enviarle comunicaciones comerciales por medios electrónicos.
          <br/><br/>
          <Typography variant="h2">2. Legitimación del tratamiento de sus datos de carácter personal</Typography>
          <Typography variant="body1">
              La legitimación para tratar sus datos de registro se basa en el consentimiento que usted nos otorga al darse
              de alta en la app y utilizarla según los términos y condiciones establecidos. También le solicitaremos el
              consentimiento para enviarle información por medios electrónicos.  

              No obstante puesto que necesitaremos determinados datos para poder proporcionarle el servicio en que
              consiste la app la base de legitimación será la ejecución de contrato.  

              Nos basamos en nuestro interés legítimo para realizar analíticas de la web y de su funcionamiento. Dichas
              analíticas serán anónimas y nos permitirán conocer el uso de la app por los usuarios y trabajar en mejoras y
              nuevas utilidades.
          </Typography>
          <br/>
          <Typography variant="h2">3. Conservación de los datos</Typography>
          <Typography variant="body1">
              Vamos a conservar sus datos mientras Vd. se mantenga de alta en nuestra aplicación. Si dejara de utilizarla
              durante un tiempo superior a 4 años tendrá que volver a registrarse.  

              Si Vd. solicita la baja de la app o la elimina, conservaremos sus datos 4 años con el objeto de cumplir con
              nuestras obligaciones legales pero los datos estarán bloqueados con las garantías adecuadas.
          </Typography>
          <br/>
          <Typography variant="h2">4. Destinatarios de los datos</Typography>
          <Typography variant="body1">Nosotros no vamos a ceder datos a terceros sin perjuicio del cumplimiento de nuestras obligaciones legales.
              Vd. como usuario interactuará con los otros usuarios de la app que le interesen.  

              No obstante trabajamos con proveedores de servicios que pueden tener acceso a los datos. Dichos proveedores
              son nuestros encargados de tratamiento y con ellos hemos suscrito los correspondientes acuerdos de
              tratamiento de datos a fin de garantizar la seguridad de dichos datos.  

              Algunos de estos proveedores pueden estar fuera de la Unión Europea pero han adoptado las garantías de
              cumplimiento exigidas por el RGPD. En concreto son los siguientes:
          </Typography>
          <ul>
              <li>Google Cloud</li>
          </ul>
          <br/>
          <Typography variant="h2">5. Derechos</Typography>
          <Typography variant="body1">Por último, le informamos de sus derechos en materia de protección de datos.

          </Typography>
          <ul>
              <li>Derecho a solicitar el acceso a sus datos personales.</li>
              <li>Derecho a solicitar su rectificación o supresión (derecho al olvido).</li>
              <li>Derecho a solicitar la limitación de su tratamiento, y a oponerse al tratamiento.</li>
              <li>Derecho a la portabilidad de los datos.</li>
              <li>Derecho a la retirada de su consentimiento.</li>
              <li>Derecho a no ser objeto de una decisión automatizada, incluida la elaboración de perfiles (no obstante
                  tenga en cuenta que si Vd. consiente utilizar la aplicación, su buen funcionamiento dependerá del perfil
                  que elaboremos para poder establecer contacto con otros usuarios.)</li> 
          </ul>
          Para ejercer sus derechos no tiene más que realizar una solicitud dirigiéndose a nosotros a
          kelea@kelea.es 

          KELEA dispone de formularios adecuados al respecto. No tiene más que solicitarlos o bien presentar su propio
          escrito en kelea@kelea.es.  

          También tiene derecho a presentar una reclamación ante la Agencia Española de Protección de Datos.  
          <Typography variant="body1"></Typography>
          <br/>
          <Typography variant="h2">6. Medidas de seguridad</Typography>
          <Typography variant="body1">
            KELEA tiene implantadas en WEMIT las medidas técnicas y organizativas necesarias para garantizar la seguridad
              de sus datos de carácter personal y evitar su alteración, la pérdida y el tratamiento y/o el acceso no
              autorizado, teniendo en cuenta el estado de la tecnología, la naturaleza de los datos almacenados y los
              riesgos provenientes de la acción humana o del medio físico y natural a que están expuestas.    

              Actualizada en febrero de 2021.
          </Typography>
        </Box>  
      </main>
    );
  }
}