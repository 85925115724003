import { useState } from 'react';
import { GroupSessions } from './GroupSessions';
import { SubscribersList } from './SubscribersList'
import { makeStyles } from '@material-ui/core/styles';
import { Tabs, Tab, Box, Button } from '@material-ui/core';
import { EditGroupForm } from './EditGroupForm';
import { useTranslation } from "react-i18next";
import { Route, Switch, useParams, useRouteMatch } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { AddSession } from './AddSession';
import { InviteSubscribersDialog } from './InviteSubscribersDialog';
import { useGroup } from '../shared/context/group-context';
import { Loading } from './Loading';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        overflow: 'hidden',
        backgroundColor: theme.palette.background.paper,
        margin: '30px'
    },
    gridList: {
        width: "100%",
        height: "auto",
    },
    icon: {
        color: 'rgba(255, 255, 255, 0.54)',
    },
}));

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div {...other}>
            {value === index && <Box p={3}>{children}</Box>}
        </div>
    );
}

export function GroupDetails() {
    let { path } = useRouteMatch();
    const [tabValue, setTabValue] = useState(0);
    const classes = useStyles();
    const { t } = useTranslation();
    let { groupId } = useParams();
    let history = useHistory();
    const [openDialog, setOpenDialog] = useState(false);
    const { group, setGroup } = useGroup();
    const [ editSession , setEditSession ] = useState();

    const handleChange = (event, newValue) => {
        setTabValue(newValue);
    };

    const handleAddSession = (groupId) => {
        history.push(`/grupos/${groupId}/addSession`);
    };

    function addUsersButton() {
        if (group !== undefined) {
            if (group.hasOwnProperty('isPrivate')) {
                if (group.isPrivate) {
                    return (
                        <Button onClick={() => setOpenDialog(true)} variant="contained" disableElevation color="primary">
                        {t("group_subscribers.invite_users_button")}
                        </Button>
                    )
                }
            }
        }
    }

    const editSessionCallback = (props) => {
        setEditSession(props);
    }

    if (!group) {return <Loading/>};

    return (
        <div className={classes.root}>
            <style>{'body { background-color: #f2efea; }'}</style>
            <Switch>
                <Route exact path={path}>
                    <Box width={999999} mt={2}>
                        {tabValue == 1 && <div style={{ float: 'right' }}>
                            <Box mt={2} mr={3}>
                                <Button onClick={() => handleAddSession(groupId)} variant="contained" disableElevation color="primary">
                                    {t("group_details_tab.new_session_button")}
                                </Button>
                            </Box>
                        </div>}
                        {tabValue == 2 && <div style={{ float: 'right' }}>
                            <Box mt={2} mr={3}>
                                {addUsersButton()}
                            </Box>
                        </div>}
                        <Tabs
                            value={tabValue}
                            indicatorColor="primary"
                            textColor="primary"
                            onChange={handleChange}
                            aria-label="group detail tabs">
                            <Tab label={t("group_details_tab.info")} />
                            <Tab label={t("group_details_tab.sessions")} />
                            <Tab label={t("group_details_tab.subscribers")} />
                        </Tabs>
                        <TabPanel value={tabValue} index={0}>
                            <EditGroupForm />
                        </TabPanel>
                        <TabPanel value={tabValue} index={1}>
                            <GroupSessions editSessionCallback={editSessionCallback}/>
                        </TabPanel>
                        <TabPanel value={tabValue} index={2}>
                            <SubscribersList />
                        </TabPanel>
                    </Box>
                    <InviteSubscribersDialog
                        openDialog={openDialog}
                        setOpenDialog={setOpenDialog}
                        isNewGroup={false} 
                        closeCallback={(checkedUsers) => {
                            const modifiedGroup = { ...group };
                            modifiedGroup.participants = checkedUsers;
                            setGroup(modifiedGroup);
                            setOpenDialog(false);
                        }}
                        group={group}>
                    </InviteSubscribersDialog>
                    
                </Route>
                <Route path={`${path}/addSession`}>
                    <AddSession />
                </Route>
                <Route path={`${path}/editSession`}>
                    <AddSession isSessionToEdit={editSession}/>
                </Route>
            </Switch>
        </div>
    );
}
