import React, { useState } from 'react';
import { useForm, Controller } from "react-hook-form";
import { TextField, FormControl, Button, Box, Slider, Typography, Grid, IconButton, Switch, Divider, Collapse, Paper, Chip } from '@material-ui/core';
import { useAddSession } from '../shared/hooks/useAddSession';
import { makeStyles } from '@material-ui/core/styles';
import MinusIcon from '@material-ui/icons/Remove';
import PlusIcon from '@material-ui/icons/Add';
import { KeyboardDatePicker, KeyboardTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { useAdmin } from '../shared/context/admin-context';
import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { useTranslation } from "react-i18next";
import { useGroup } from '../shared/context/group-context';
import esLocale from "date-fns/locale/es";
import enLocale from "date-fns/locale/en-US";
import { AddIcebreakersDialog } from "./AddIcebreakersDialog";
import { RecurrentSessionDialog } from "./RecurrentSessionDialog";
import { DateObject } from "react-multi-date-picker";
import { Loading } from './Loading';


const useStyles = makeStyles({
    root: {
        width: "100%",
        borderRadius: 80,
    },
    iconButton: {
        backgroundColor: "#FF5851",
        color: "white",
        padding: 3,
    },
    counter: {
        fontSize: 25,
        color: "#888888"
    },
    squareBox: {
        marginLeft: 9,
        marginRight: 9,
        borderRadius: 5,
        border: '1px solid #bbbbbb',
        paddingLeft: 12,
        paddingTop: 3,
        paddingBottom: 3,
        paddingRight: 12,
    },
    picker: {
        marginRight: 3,
        borderRadius: 5,
        border: '1px solid #bbbbbb',
        paddingLeft: 12,
        paddingTop: 3,
        paddingBottom: 3,
    },
});

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export function AddSession(props) {
    const { isSessionToEdit } = props;
    const { event, userDetails } = useAdmin();
    const { group } = useGroup();
    let { groupId } = useParams();
    const { session, addSession, icebreakers } = useAddSession();
    const [maxParticipants, setMaxParticipants] = useState((isSessionToEdit) ? isSessionToEdit.maxParticipants : 20);
    const [roundDuration, setRoundDuration] = useState((isSessionToEdit) ? isSessionToEdit.roomDuration : 5);
    const [rounds, setRounds] = useState((isSessionToEdit) ? isSessionToEdit.rounds : 3);
    const [personsOnRoom, setPersonsOnRoom] = useState((isSessionToEdit) ? isSessionToEdit.personsOnRoom : 3);
    const [speech, setSpeech] = useState((isSessionToEdit) ? (isSessionToEdit.speechUid) ? true : false : false);
    const [isIcebreakersSelected, setIsIcebreakersSelected] = useState((isSessionToEdit) ? (isSessionToEdit.icebreakers) ? true : false : false);
    const [isRecurrentSessionSelected, setIsRecurrentSessionSelected] = useState((isSessionToEdit) ? ((isSessionToEdit.recurrenceDates !== null) && (isSessionToEdit.recurrenceDates !== undefined) ? true : false) : false);
    const [selectedIcebreakers, setSelectedIcebreakers] = useState(((isSessionToEdit) && (isSessionToEdit.icebreakers)) ? isSessionToEdit.icebreakers : []);
    const [selectedRecurrency, setSelectedRecurrency] = useState((isSessionToEdit) ? isSessionToEdit.recurrenceDates : []);
    const [day, setDay] = useState((isSessionToEdit) ? new Date(isSessionToEdit.startDate.seconds * 1000) : new Date());
    const [hour, setHour] = useState((isSessionToEdit) ? new Date(isSessionToEdit.startDate.seconds * 1000) : new Date());
    const [generalLoginError, setGeneralLoginError] = useState('');
    const { handleSubmit, control, formState: { errors: fieldsErrors }, watch } = useForm();
    const { t, i18n } = useTranslation();
    const classes = useStyles();
    let history = useHistory();
    const [openIcebreakersDialog, setOpenIcebreakersDialog] = useState(false);
    const [showIcebreakersSelected, setShowIcebreakersSelected] = useState(false);

    const [openFailureSnackbar, setOpenFailureSnackbar] = useState(false);
    const handleCloseFailureSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenFailureSnackbar(false);
    };

    const [openFailureIcebreakersSnackbar, setOpenFailureIcebreakersSnackbar] = useState(false);
    const handleCloseFailureIcebreakersSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenFailureIcebreakersSnackbar(false);
    };

    const [openSessionTimeErrorSnackbar, setOpenSessionTimeErrorSnackbar] = useState(false);
    const handleOpenSessionTimeErrorSnackbarSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSessionTimeErrorSnackbar(false);
    };

    const [loadingAddSession, setLoadingAddSession] = useState(false);
    const handleAddSession = data => {
        var selectedDate = new Date(day.getFullYear(), day.getMonth(), day.getDate(), hour.getHours(), hour.getMinutes());
        if (selectedDate.getDate() == new Date().getDate() && selectedDate.getHours() < new Date().getHours() || (selectedDate.getHours() == new Date().getHours() && selectedDate.getMinutes() < new Date(new Date().getTime() + 15 * 60000).getMinutes())) {
            setOpenSessionTimeErrorSnackbar(true);
        } else {
            setLoadingAddSession(true);
            if (isRecurrentSessionSelected) {
                var recurrentDates = [];
                selectedRecurrency.map(element => {
                    recurrentDates.push(new Date(element.year, element.month.number - 1, element.day, element.hour, element.minute))
                })
            }
            if (isIcebreakersSelected) {
                if (rounds === selectedIcebreakers.length) {
                    const newSession = { ...session };
                    newSession.recurrenceDates = (isRecurrentSessionSelected) ? recurrentDates : null;
                    newSession.name = data.sessionName;
                    newSession.description = data.sessionDescription;
                    newSession.maxParticipants = maxParticipants;
                    newSession.rounds = rounds;
                    newSession.personsOnRoom = personsOnRoom;
                    newSession.roomDuration = roundDuration;
                    newSession.icebreakers = selectedIcebreakers;
                    newSession.startDate = new Date(day.getFullYear(), day.getMonth(), day.getDate(), hour.getHours(), hour.getMinutes());
                    addSession(newSession, event, speech, userDetails.id, groupId, group, isSessionToEdit,
                        { successCallback: (success) => { success ? goBack() : setOpenFailureSnackbar(true) } });
                } else {
                    setOpenFailureIcebreakersSnackbar(true);
                    setOpenIcebreakersDialog(true);
                }
            } else {
                const newSession = { ...session };
                newSession.recurrenceDates = (isRecurrentSessionSelected) ? recurrentDates : null;
                newSession.name = data.sessionName;
                newSession.description = data.sessionDescription;
                newSession.maxParticipants = maxParticipants;
                newSession.rounds = rounds;
                newSession.personsOnRoom = personsOnRoom;
                newSession.roomDuration = roundDuration;
                newSession.icebreakers = null;
                newSession.startDate = new Date(day.getFullYear(), day.getMonth(), day.getDate(), hour.getHours(), hour.getMinutes())
                addSession(newSession, event, speech, userDetails.id, groupId, group, isSessionToEdit,
                    { successCallback: (success) => { success ? goBack() : setOpenFailureSnackbar(true) } });
            }
        }
    };

    const goBack = () => {
        setLoadingAddSession(false);
        history.push(`/grupos/${groupId}`);
    };

    const handleParticipantsSlider = (event, newValue) => {
        setMaxParticipants(newValue);
    };
    const handleDurationSlider = (event, newValue) => {
        setRoundDuration(newValue);
    };
    const handlePersonsOnRoomIncrement = () => {
        if (personsOnRoom < 4)
            setPersonsOnRoom(personsOnRoom => personsOnRoom + 1);
    };
    const handlePersonsOnRoomDecrease = () => {
        if (personsOnRoom > 2)
            setPersonsOnRoom(personsOnRoom => personsOnRoom - 1);
    };
    const handleRoundsIncrement = () => {
        if (rounds < 10)
            setRounds(rounds => rounds + 1);

    };
    const handleRoundsDecrease = () => {
        if (rounds > 3)
            setRounds(rounds => rounds - 1);
    };
    const handleSpeechSwitchChange = () => {
        setSpeech(!speech);
    }
    const handleIcebreakersSwitchChange = () => {
        setIsIcebreakersSelected(!isIcebreakersSelected);
        if (!isIcebreakersSelected) {
            setOpenIcebreakersDialog(true);
        } else {
            setShowIcebreakersSelected(false);
        }
    }
    const handleRecurrentSessionSwitchChange = () => {
        setIsRecurrentSessionSelected(!isRecurrentSessionSelected);
    }
    const handleDateChange = (date) => {
        setDay(date);
    };
    const handleHourChange = (date) => {
        setHour(date);
    };
    const handleIcebreakersCallback = (element) => {
        setOpenIcebreakersDialog(false);
        if (!element) {
            setIsIcebreakersSelected(false);
            setSelectedIcebreakers([])
        } else {
            setSelectedIcebreakers(element);
        }
    }
    const handleRecurrentSessionCallback = (element) => {
        setSelectedRecurrency(element);
    }

    const hanldeShowIcebreakersSelected = () => {
        setShowIcebreakersSelected((showIcebreakersSelected) ? false : true)
    }

    function handleIcebreakerDialog() {
        if ((isSessionToEdit) && (isSessionToEdit.icebreakers)) {
            var g = [];
            if ((icebreakers.length > 0) && (isSessionToEdit.icebreakers !== undefined)) {
                icebreakers.map((element, index) => {
                    if (isSessionToEdit.icebreakers.includes(element)) {
                        g.push(index);
                    }
                })
            }
            return (
                <AddIcebreakersDialog
                    openDialog={openIcebreakersDialog}
                    setOpenDialog={setOpenIcebreakersDialog}
                    icebreakers={icebreakers}
                    preSelectedIcebreakers={g}
                    rounds={rounds}
                    closeCallback={handleIcebreakersCallback}
                >
                </AddIcebreakersDialog>
            );
        } else {
            return (
                <AddIcebreakersDialog
                    openDialog={openIcebreakersDialog}
                    setOpenDialog={setOpenIcebreakersDialog}
                    icebreakers={icebreakers}
                    preSelectedIcebreakers={[]}
                    rounds={rounds}
                    closeCallback={handleIcebreakersCallback}
                >
                </AddIcebreakersDialog>
            )
        }
    }

    function handleRecurrentSessionDialog() {
        if ((isSessionToEdit) && (isSessionToEdit.recurrenceDates !== undefined) && (isSessionToEdit.recurrenceDates !== null)) {
            var datesConverted = [];
            isSessionToEdit.recurrenceDates.map(element => {
                var convertFromSecs = new Date(element.seconds * 1000);
                const data = new DateObject();
                data.set({
                    year: convertFromSecs.getFullYear(),
                    month: convertFromSecs.getMonth() + 1,
                    day: convertFromSecs.getDate(),
                    hour: convertFromSecs.getHours(),
                    minute: convertFromSecs.getMinutes()
                })
                datesConverted.push(data)
            })
            return (
                <RecurrentSessionDialog
                    closeCallback={handleRecurrentSessionCallback}
                    editRecurrentDates={datesConverted}
                    sessionDay={day}
                    sessionHour={hour}
                >
                </RecurrentSessionDialog>
            );
        } else {
            return (
                <RecurrentSessionDialog
                    closeCallback={handleRecurrentSessionCallback}
                    editRecurrentDates={[]}
                    sessionDay={day}
                    sessionHour={hour}
                >
                </RecurrentSessionDialog>
            );
        }

    }

    return (
        <div className={classes.root}>
            <style>{'body { background-color: #f2efea; }'}</style>
            {loadingAddSession ? <Loading /> :
                <form onSubmit={handleSubmit(handleAddSession)}>
                    <Box mx={4} my={3}>
                        <Box my={2}>
                            <Typography variant="h1">
                                {t("add_session.title")}
                            </Typography>
                        </Box>
                        <Box my={2} width="50%">
                            <Typography variant="h3">
                                {t("add_session.info")}
                            </Typography>
                        </Box>
                        <Box py={2}><Divider width="100%" /></Box>
                        <Box mt={1} mb={2}>
                            <Typography variant="h1">
                                {t("add_session.sessionData")}
                            </Typography>
                        </Box>
                        <Box width="70%">
                            <Grid container
                                direction="row"
                                alignItems="center"
                                justify="space-between"
                            >
                                <Typography variant="h2">
                                    {t("add_session.sessionTitle")}
                                </Typography>
                                <Typography variant={(watch('sessionName') !== undefined) ? watch('sessionName').length < 40 ? "h3" : "h1" : "h3"} style={{fontWeight: 600, fontSize: 22}}>
                                        {(watch('sessionName') !== undefined)
                                            ? (watch('sessionName').length) + ' / ' + 40 : 0 + ' / ' + 40}
                                    </Typography>
                            </Grid>
                        </Box>
                        <Box mt={2} mb={3} width="70%">
                            <FormControl fullWidth variant="outlined">
                                <Controller
                                    name="sessionName"
                                    render={({ field }) =>
                                        <TextField
                                            id="sessionName"
                                            labelwidth={40}
                                            helperText={fieldsErrors.sessionName ? fieldsErrors.sessionName.message : null}
                                            variant="outlined"
                                            inputProps={{ maxLength: 40 }}
                                            label={t("add_session.name")}
                                            error={!!fieldsErrors.sessionName}
                                            {...field}
                                        />}
                                    control={control}
                                    defaultValue={(isSessionToEdit) ? isSessionToEdit.name : ""}
                                    rules={{
                                        required: 'Required'
                                    }}
                                />
                            </FormControl>
                        </Box>
                        <Typography variant="h2">
                            {t("add_session.description")}
                        </Typography>
                        <Box mt={2} mb={5} width="70%">
                            <FormControl fullWidth variant="outlined">
                                <Controller
                                    name="sessionDescription"
                                    render={({ field }) =>
                                        <TextField
                                            id="sessionDescription"
                                            labelwidth={70}
                                            helperText={fieldsErrors.sessionDescription ? fieldsErrors.sessionDescription.message : null}
                                            variant="outlined"
                                            label={t("add_session.description")}
                                            multiline
                                            error={!!fieldsErrors.sessionDescription}
                                            {...field}
                                        />}
                                    control={control}
                                    defaultValue={(isSessionToEdit) ? isSessionToEdit.description : ""}
                                    rules={{
                                        required: 'Required'
                                    }}
                                />
                            </FormControl>
                        </Box>
                        <Box width="70%">
                            <Grid container
                                direction="row"
                                justify="space-between"
                                alignItems="center">
                                <Grid item>
                                    <Typography variant="h2">
                                        {t("add_session.maxParticipants")}
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography variant="h1">
                                        {maxParticipants}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Box>
                        <Box my={3} ml={1} width="70%">
                            <Slider
                                value={maxParticipants}
                                onChange={handleParticipantsSlider}
                                step={10}
                                min={20}
                                max={150}
                            />
                        </Box>
                        <Typography variant="h2">
                            {t("add_session.rounds")}
                        </Typography>
                        <Box width="70%" mb={4} mt={3}>
                            <Grid container
                                direction="row"
                                alignItems="center">
                                <Grid item>
                                    <IconButton className={classes.iconButton} onClick={handleRoundsDecrease}>
                                        <MinusIcon />
                                    </IconButton>
                                </Grid>
                                <Box className={classes.squareBox}>
                                    <Typography className={classes.counter} variant="h2">
                                        {rounds}
                                    </Typography>
                                </Box>
                                <Grid item>
                                    <IconButton className={classes.iconButton} onClick={handleRoundsIncrement}>
                                        <PlusIcon />
                                    </IconButton>
                                </Grid>
                            </Grid>
                        </Box>
                        <Typography variant="h2">
                            {t("add_session.personsOnRoom")}
                        </Typography>
                        <Box width="70%" mb={4} mt={3}>
                            <Grid container
                                direction="row"
                                alignItems="center">
                                <Grid item>
                                    <IconButton className={classes.iconButton} onClick={handlePersonsOnRoomDecrease}>
                                        <MinusIcon />
                                    </IconButton>
                                </Grid>
                                <Box className={classes.squareBox}>
                                    <Typography className={classes.counter} variant="h2">
                                        {personsOnRoom}
                                    </Typography>
                                </Box>
                                <Grid item>
                                    <IconButton className={classes.iconButton} onClick={handlePersonsOnRoomIncrement}>
                                        <PlusIcon />
                                    </IconButton>
                                </Grid>
                            </Grid>
                        </Box>
                        <Box width="70%">
                            <Grid container
                                direction="row"
                                justify="space-between"
                                alignItems="center">
                                <Grid item>
                                    <Typography variant="h2">
                                        {t("add_session.duration")}
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography variant="h1">
                                        {roundDuration + " min."}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Box>
                        <Box my={3} ml={1} width="70%">
                            <Slider
                                value={roundDuration}
                                onChange={handleDurationSlider}
                                step={1}
                                min={1}
                                max={12}
                            />
                        </Box>

                        <Typography variant="h2">
                            {t("add_session.speech_title")}
                        </Typography>
                        <Box my={2}>
                            <Grid container
                                direction="row"
                                alignItems="center">
                                <Grid item>
                                    <Switch checked={speech} onChange={handleSpeechSwitchChange} name="speech" color="primary" />
                                </Grid>
                                <Grid item>
                                    <Typography variant="h4">
                                        {t("add_session.speech_info")}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Box>
                        <Typography variant="h2">
                            {t("add_session.icebreakers_title")}
                        </Typography>
                        <Box my={2}>
                            <Grid container
                                direction="row"
                                alignItems="center">
                                <Grid item>
                                    <Switch checked={isIcebreakersSelected} onChange={handleIcebreakersSwitchChange} name="isIcebreakersSelected" color="primary" />
                                </Grid>
                                <Grid item>
                                    <Typography variant="h4">
                                        {t("add_session.icebreakers_info")}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Box>
                        <Grid
                            container
                            direction="column"
                            justify="flex-start"
                            alignItems="flex-start"
                            style={{ paddingBottom: 20 }}
                        >
                            {isIcebreakersSelected ?
                                <Chip label={t("add_session.icebreakers_show_buttton")} variant="contained" color="primary" onClick={() => hanldeShowIcebreakersSelected()} disableElevation style={{ marginRight: 10, marginBottom: 20 }} />
                                : ''}

                            {isIcebreakersSelected && showIcebreakersSelected ? selectedIcebreakers.map(element => (
                                <Chip label={element} variant="outlined" color={'#595959'} style={{ marginBottom: 10 }} />)
                            ) : ""}
                        </Grid>
                        <Typography variant="h2">
                            {t("add_session.recurrent_session_title")}
                        </Typography>
                        <Box my={2}>
                            <Grid container
                                direction="row"
                                alignItems="center">
                                <Grid item>
                                    <Switch checked={isRecurrentSessionSelected} onChange={handleRecurrentSessionSwitchChange} name="isRecurrentSessionSelected" color="primary" />
                                </Grid>
                                <Grid item>
                                    <Typography variant="h4">
                                        {t("add_session.recurrent_session_subtitle")}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Box>
                        <Collapse in={isRecurrentSessionSelected}>
                            <Paper elevation={4} style={{ paddingTop: 15 }}>
                                {handleRecurrentSessionDialog()}
                            </Paper>
                        </Collapse>
                        <Typography variant="h2">
                            {t("add_session.time")}
                        </Typography>
                        <Box my={2} >
                            <Grid container
                                direction="row"
                                alignItems="center">
                                <Grid item>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={(i18n.language === 'es') ? esLocale : enLocale}>
                                        <KeyboardDatePicker
                                            disableToolbar
                                            variant="inline"
                                            format="dd/MM/yyyy"
                                            margin="normal"
                                            id="date-picker-inline"
                                            className={classes.picker}
                                            disablePast
                                            InputProps={{
                                                disableUnderline: true,
                                            }}

                                            minDate={new Date()}
                                            value={day}
                                            onChange={handleDateChange}
                                            KeyboardButtonProps={{
                                                'aria-label': 'change date',
                                            }}
                                        />
                                    </MuiPickersUtilsProvider>
                                </Grid>
                                <Box ml={2}>
                                    <Grid item>
                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                            <KeyboardTimePicker
                                                margin="normal"
                                                ampm={false}
                                                id="time-picker"
                                                value={hour}
                                                InputProps={{
                                                    disableUnderline: true,
                                                }}
                                                className={classes.picker}
                                                minDate={new Date(new Date().getTime() - 15 * 60000)}
                                                minDateMessage={'Flipao'}
                                                onChange={handleHourChange}
                                                KeyboardButtonProps={{
                                                    'aria-label': 'change time',
                                                }}
                                            />
                                        </MuiPickersUtilsProvider>
                                    </Grid>
                                </Box>
                            </Grid>
                        </Box>
                        {generalLoginError && <div>{generalLoginError}</div>}
                        <Grid
                            container
                            direction="row"
                            alignItems="center"
                        >
                            {isSessionToEdit &&
                                <Box mt={4}>
                                    <Button variant="contained" color="secondary" onClick={() => goBack()} disableElevation style={{ marginRight: "10px" }}>
                                        {t("add_session.cancel")}
                                    </Button>
                                </Box>
                            }
                            <Box mt={4}>
                                <Button variant="contained" color="primary" type="submit" disableElevation>
                                    {(isSessionToEdit) ? t("add_session.save") : t("add_session.publish")}
                                </Button>
                            </Box>
                        </Grid>
                        {(icebreakers.length > 0) && handleIcebreakerDialog()}
                        <Snackbar open={openFailureSnackbar} autoHideDuration={3000} onClose={handleCloseFailureSnackbar}>
                            <Alert onClose={handleCloseFailureSnackbar} severity="error">
                                {t("add_session.error")}
                            </Alert>
                        </Snackbar>
                        <Snackbar open={openFailureIcebreakersSnackbar} autoHideDuration={3000} onClose={handleCloseFailureIcebreakersSnackbar}>
                            <Alert onClose={handleCloseFailureIcebreakersSnackbar} severity="error">
                                {t("icebreakers_dialog.icebreakers_dialog_failure_snackbar")}
                            </Alert>
                        </Snackbar>
                        <Snackbar open={openSessionTimeErrorSnackbar} autoHideDuration={3000} onClose={handleOpenSessionTimeErrorSnackbarSnackbar}>
                            <Alert onClose={handleOpenSessionTimeErrorSnackbarSnackbar} severity="error">
                                {t("icebreakers_dialog.session_time_error_snackbar")}
                            </Alert>
                        </Snackbar>
                    </Box>
                </form>}
        </div>
    );
}