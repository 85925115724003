import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import { useTranslation } from "react-i18next";
import { TextField, Typography, Button, IconButton } from '@material-ui/core';
import { useUsers } from '../shared/hooks/useUsers';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import Cancel from '@material-ui/icons/Cancel';
import Add from '@material-ui/icons/AddCircleOutlined';
import Template from '../ejemplo.csv';



const useStyles = makeStyles((theme) => ({
  description_grid: {
    marginBottom: "2%",
  },
  description_typography: {
    width: "60%"
  },
  description_button_pattern: {
    marginLeft: "20%"
  },
  description_button_csv: {
    marginLeft: "30px"
  },
  textfield_inputs: {
    width: "23%",
    margin: "2%",
  },
  textfield_index: {
    width: "10px",
    fontWeight: 600,
    marginLeft: "3%",
    marginRight: "5%"
  },
  textfield_icon_remove: {
    fontSize: '30px'
  },
  textfield_icon_add: {
    color: "#65CC11",
    fontSize: '30px'
  },
  input: {
    display: 'none',
  },
}));

export function AddUsersDialog(props) {
  const { openDialog, setOpenDialog, successCallback } = props;
  const { t } = useTranslation();
  const classes = useStyles();
  const { sendInvitations } = useUsers();
  const [openFailureSnackbar, setOpenFailureSnackbar] = useState(false);
  const [openCsvInvalidSnackbar, setOpenCsvInvalidSnackbar] = useState(false);
  const [openEmailInvalidSnackbar, setOpenEmailInvalidSnackbar] = useState(false);
  const [openNoEmailSnackbar, setOpenNoEmailSnackbar] = useState(false);
  const [inputList, setInputList] = useState([{ email: "", name: "", surname: "" }]);
  const [addButtonController, setAddButtonController] = useState(false);
  const emailPattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);


  function handleCsvFile(event) {
    var reader = new FileReader();
    const splitedList = [];
    try {
      reader.readAsText(event.target.files[0]);
      reader.onload = function (event) {
        const csvRow = event.target.result.split(';');
        csvRow.forEach(element => {
          const elementSplited = element.split('\n');
          if (elementSplited.length == 1) {
            splitedList.push(elementSplited[0]);
          } else {
            elementSplited.forEach(splitedString => (
              splitedList.push(splitedString)
            ))
          }
        });
        arrangeCsvData(splitedList);
      };
    } catch (e) {
      //TODO error handling
    }
  }

  function arrangeCsvData(splitedList) {

    var orderInRowIndex = 0;

    if (splitedList.length !== 0) {
      let arrangedData = []
      if ((splitedList[0] === 'email') && (splitedList[1] === 'nombre') && (splitedList[2] === 'apellidos')) {
        splitedList.forEach(tag => {
          let inputRowModel = {
            inputData: '',
            inputLabel: ''
          }
          if (tag)
            switch (orderInRowIndex) {
              case 0: inputRowModel.inputData = tag; inputRowModel.inputLabel = t("add_users_dialog.email");
                orderInRowIndex++;
                arrangedData.push(inputRowModel);
                break;
              case 1: inputRowModel.inputData = tag; inputRowModel.inputLabel = t("add_users_dialog.name");
                orderInRowIndex++;
                arrangedData.push(inputRowModel);
                break;
              case 2: inputRowModel.inputData = tag; inputRowModel.inputLabel = t("add_users_dialog.surname");
                orderInRowIndex = 0;
                arrangedData.push(inputRowModel);
                break;
            }
        })
        setTagsFromCsv(arrangedData);
      }
      else {
        setOpenCsvInvalidSnackbar(true);
      }
    }
  };

  let indexOfCsvData = 3; //0 - Includes All  3 - Jump the headers
  function setTagsFromCsv(arrangedData) {
    let newInputs = []
    while (arrangedData[indexOfCsvData + 2]) {
      const email = arrangedData[indexOfCsvData].inputData;
      indexOfCsvData++;
      const name = arrangedData[indexOfCsvData].inputData;
      indexOfCsvData++;
      const surname = arrangedData[indexOfCsvData].inputData;
      indexOfCsvData++;
      newInputs.push({ email: email, name: name, surname: surname })
    }
    setInputList([...newInputs]);
    setAddButtonController(true);
  }

  const handleCloseFailureSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenFailureSnackbar(false);
  };

  const handleCsvInvalidSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenCsvInvalidSnackbar(false);
  };

  const handleEmailInvalidSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenEmailInvalidSnackbar(false);
  };

  const handleNoEmailSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenNoEmailSnackbar(false);
  };

  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    if (name === 'email') {
      inputEmailValidation(value);
    }
    const list = [...inputList];
    list[index][name] = value;
    setInputList(list);
  };

  const handleRemoveClick = index => {
    const list = [...inputList];
    list.splice(index, 1);
    setInputList(list);
  };

  const handleAddClick = () => {
    setInputList([...inputList, { email: "", name: "", surname: "" }]);
    setAddButtonController(false);
    setShowEmptyEmailHelperText(false);
  };

  function closeDialog() {
    setOpenDialog(false);
    setShowEmptyEmailHelperText(false);
    setAddButtonController(false);
    setInputList([{ email: "", name: "", surname: "" }]);
  }

  const [showEmptyEmailHelperText, setShowEmptyEmailHelperText] = useState(false)
  function sendUsersInvitations() {
    if (addButtonController) {
      sendInvitations(inputList, { successCallback: (success) => { success ? onSuccess() : setOpenFailureSnackbar(true) } });
    } else {
      let emptyEmailField = false;
      inputList.map(el => {
        if (el.email === "") {
          emptyEmailField = true;
        }
      })
      if (emptyEmailField) {
        setShowEmptyEmailHelperText(true);
        setOpenNoEmailSnackbar(true);
      } else {
        setOpenEmailInvalidSnackbar(true);
      }
    }
  }

  function onSuccess() {
    setShowEmptyEmailHelperText(false);
    setAddButtonController(false);
    setInputList([{ email: "", name: "", surname: "" }]);
    successCallback(true);
    setOpenDialog(false);
  }

  function inputEmailValidation(value) {
    if (typeof value !== "") {
      if (emailValidation(value)) {
        setAddButtonController(true);
      } else {

        setAddButtonController(false);
      }
    }
  }

  function emailValidation(value) {
    return emailPattern.test(value);
  }

  function handleErrorText(email) {
    if (email === '') {
      return (showEmptyEmailHelperText)?false:true;
    } else if (emailValidation(email)) {
      return true
    } else {
      return false;
    }
  }
  return (
    <Dialog
      open={openDialog}
      onClose={setOpenDialog}
      maxWidth={"lg"}
      fullWidth={false}
      scroll={"paper"}
    >
      <DialogTitle><Typography variant="h1">{t("add_users_dialog.title")}</Typography></DialogTitle>
      <DialogContent>
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="center"
          className={classes.description_grid}>
          <Typography variant="h3" className={classes.description_typography}> {t("add_users_dialog.description")} </Typography>
          <a className={classes.description_button_pattern} href={Template} download="ejemplo.csv">{t("add_users_dialog.template")}</a>
          <input accept=".csv" className={classes.input} id="contained-button-file" multiple type="file" onChange={handleCsvFile} />
          <label htmlFor="contained-button-file">
            <Button component="span" variant="contained" className={classes.description_button_csv} disableElevation color="primary" >{t("add_users_dialog.button_uploadCsv")}</Button>
          </label>
        </Grid>
        {(inputList.map((x, i) => {
          return (<Grid
            container
            direction="row"
            justify="flex-start"
            alignItems="center"
            className={classes.grid_textfields}>
            <Typography
              className={classes.textfield_index}>{i + 1}</Typography>
            <TextField
              className={classes.textfield_inputs}
              name="email"
              labelwidth={40}
              variant="outlined"
              label={t("add_users_dialog.email")}
              value={x.email}
              onChange={e => handleInputChange(e, i)}
              helperText={handleErrorText(x.email)
                ? null
                : ((x.email === '')
                  ? ((showEmptyEmailHelperText) && t("add_users_dialog.no_email_helper_text"))
                  : t("add_users_dialog.email_invalid"))}
              error={!handleErrorText(x.email)}
            />
            <TextField
              className={classes.textfield_inputs}
              name="name"
              labelwidth={40}
              variant="outlined"
              label={t("add_users_dialog.name")}
              value={x.name}
              onChange={e => handleInputChange(e, i)}
            />
            <TextField
              className={classes.textfield_inputs}
              name="surname"
              labelwidth={40}
              variant="outlined"
              label={t("add_users_dialog.surname")}
              value={x.surname}
              onChange={e => handleInputChange(e, i)}
            />
            <div className="btn-box">
              {inputList.length !== 1 && <IconButton
                component="span"
                variant="outlined"
                disableElevation
                color="primary"
                onClick={() => handleRemoveClick(i)}>
                <Cancel
                  className={classes.textfield_icon_remove} />
              </IconButton>}
              {(addButtonController === true && inputList.length - 1 === i) && <IconButton
                component="span"
                variant="outlined"
                disableElevation
                onClick={handleAddClick}>
                <Add
                  className={classes.textfield_icon_add} />
              </IconButton>}
            </div>
          </Grid>
          );
        }))}
      </DialogContent>
      <DialogActions>
        <Button
          className={classes.description_button_csv}
          variant="contained"
          disableElevation
          color="secondary"
          onClick={() => closeDialog()}>
          {t("add_users_dialog.button_cancel")}
        </Button>
        <Button
          className={classes.description_button_csv}
          variant="contained"
          disableElevation
          color="primary"
          onClick={() => sendUsersInvitations()}>
          {t("add_users_dialog.button_accept")}
        </Button>
      </DialogActions>
      <Snackbar open={openFailureSnackbar} autoHideDuration={3000} onClose={handleCloseFailureSnackbar}>
        <Alert onClose={handleCloseFailureSnackbar} severity="error">
          {t("add_users_dialog.invitation_error")}
        </Alert>
      </Snackbar>
      <Snackbar open={openCsvInvalidSnackbar} autoHideDuration={3000} onClose={handleCsvInvalidSnackbar}>
        <Alert onClose={handleCsvInvalidSnackbar} severity="error">
          {t("add_users_dialog.wrong_csv_template")}
        </Alert>
      </Snackbar>
      <Snackbar open={openEmailInvalidSnackbar} autoHideDuration={3000} onClose={handleEmailInvalidSnackbar}>
        <Alert onClose={handleEmailInvalidSnackbar} severity="error">
          {t("add_users_dialog.email_invalid")}
        </Alert>
      </Snackbar>
      <Snackbar open={openNoEmailSnackbar} autoHideDuration={3000} onClose={handleEmailInvalidSnackbar}>
        <Alert onClose={handleNoEmailSnackbar} severity="error">
          {t("add_users_dialog.no_email_error")}
        </Alert>
      </Snackbar>
    </Dialog>

  )
}