import { useState, useEffect } from 'react';
import { useAdmin } from '../context/admin-context';
import { useFirebase } from '../context/firebase-context';

export function useEventSessions() {
    const { db } = useFirebase();
    const { event } = useAdmin();
    const [eventSessionsList, setEventSessionsList] = useState([]);

    useEffect(() => {
        if (event) {
            db.collection('sessions')
                .where('eventId', '==', event.id)
                .orderBy('startDate', 'asc')
                .onSnapshot((snapshot) => {
                    const g = [];
                    snapshot.forEach(function (doc) {
                        g.push({ id: doc.id, ...doc.data() });
                    });
                    setEventSessionsList(g);
                });
        }
    }, [db, event]);

    return {
        eventSessionsList
    }

}