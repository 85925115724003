import React, { useContext, useState, useEffect } from 'react';
import { useFirebase } from '../context/firebase-context';

const AdminContext = React.createContext(null);

export function AdminProvider({ children }) {
    const { db, user } = useFirebase();
    const [userDetails, setUserDetails] = useState(null);
    const [event, setEvent] = useState(null);
    const [groupList, setGroupList] = useState([]);
    const [reload, setReload] = useState(false);

    useEffect(() => {
        if (user) {
            db
                .collection('users')
                .doc(user.uid)
                .get()
                .then((doc) => {
                    setUserDetails({ id: doc.id, ...doc.data() });
                    setReload(false);
                });
        }
    }, [user, reload]);

    useEffect(() => {
        if (userDetails) {
            if ((userDetails.isAdmin !== undefined) && (userDetails.defaultEvent !== undefined)) {
                db.collection('events')
                    .doc((userDetails.defaultEvent !== "") ? userDetails.defaultEvent : userDetails.isAdmin[0])
                    .get()
                    .then((doc) => {
                        setEvent({ id: doc.id, ...doc.data() });
                    });
            }
        }
    }, [userDetails]);

    return (
        <AdminContext.Provider
            value={{
                event,
                userDetails,
                groupList,
                setEvent,
                setUserDetails,
                setGroupList,
                setReload
            }}
        >
            {children}
        </AdminContext.Provider>
    );
}

export function useAdmin() {
    const context = useContext(AdminContext);
    return context;
}