import React from 'react';
import { useEventSessions } from '../shared/hooks/useEventSessions';
import { useTranslation } from "react-i18next";
import { Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        justifyContent: 'left',
        flexWrap: 'wrap',
        listStyle: 'none',
        width: "96%",
        padding: theme.spacing(0.5),
        backgroundColor: theme.palette.background.paper,
        margin: 20,
    },
    rowTitle: {
        fontWeight: 600,
        fontSize: 16,
        color: "#aaaaaa"
    },
    cellDark: {
        fontSize: 15,
        fontWeight: 500,
    }
}));

export function SessionList() {
    const { eventSessionsList } = useEventSessions();
    const { t, i18n } = useTranslation();
    const classes = useStyles();

    function getSessionDate(date) {
        const monthFormatter = new Intl.DateTimeFormat(i18n.language, { month: 'short' });
        const dayFormatter = new Intl.DateTimeFormat(i18n.language, { weekday: 'short' });
        const monthName = monthFormatter.format(date);
        const dayName = dayFormatter.format(date);
        var day = date.getDate();
        var hour = date.getHours();
        var minute = date.getMinutes();
        return `${monthName.charAt(0).toUpperCase() + monthName.slice(1)} ${day} - ${dayName.charAt(0).toUpperCase() + dayName.slice(1)} ${hour}:${minute} `
    }

    function isBeforeNow(date) {
        var todaysDate = new Date();
        return date <= todaysDate;
    }

    function handleTheresAnyActiveSession() {
        var theresSomeActiveSession = false;
        eventSessionsList.forEach(session => {
            if (!isBeforeNow(session.startDate.toDate())) {
                theresSomeActiveSession = true;
            }
        })
        return theresSomeActiveSession;
    }

    function handleActiveSessions(session) {
        return (
            !isBeforeNow(session.startDate.toDate())
                ?
                <TableRow key={session.id} style={{ backgroundColor: '#FFFFFF' }}>
                    <TableCell scope="row" style={{ width: "15%" }}>
                        <p className={classes.cellDark}>{getSessionDate(session.startDate.toDate())}</p>
                    </TableCell>
                    <TableCell component="th" scope="row" style={{ width: "35%" }}>
                        <p className={classes.cellDark} style={{ fontWeight: 600}}>{session.name}</p>
                    </TableCell>
                    <TableCell scope="row" align="left">
                        <p className={classes.cellDark}>{session.maxParticipants}</p>
                    </TableCell>
                    <TableCell scope="row" align="left">
                        <p className={classes.cellDark}>{session.participants.length}</p>
                    </TableCell>
                    <TableCell scope="row" align="left">
                        <p className={classes.cellDark}>{session.groupName}</p>
                    </TableCell>
                </TableRow>
                :
                ''
        )
    }

    return (
        <div className={classes.root}>
            <style>{'body { background-color: #f2efea; }'}</style>
            {handleTheresAnyActiveSession()
                ?
                <TableContainer style={{ padding: "30px" }}>
                    <Table size="big" aria-label="group_users_table">
                        <TableHead >
                            <TableRow ><Typography variant="h1" style={{marginBottom:"10%"}}>{t("session_list.title")}</Typography></TableRow>
                            <TableRow>
                                <TableCell className={classes.rowTitle} >{t("session_list.table_date")}</TableCell>
                                <TableCell className={classes.rowTitle} >{t("session_list.table_title")}</TableCell>
                                <TableCell className={classes.rowTitle} >{t("session_list.table_seats")}</TableCell>
                                <TableCell className={classes.rowTitle} >{t("session_list.table_inscribed")}</TableCell>
                                <TableCell className={classes.rowTitle} >{t("session_list.table_group")}</TableCell>
                                <TableCell className={classes.rowTitle} style={{ width: "10%" }}>{""}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody >
                            {eventSessionsList.map((session) => (
                                handleActiveSessions(session)
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                :
                <Box style={{ marginTop: "5%", marginLeft: "40%", marginBottom: "5%" }}>
                    <Typography variant="h3">{t("session_list.no_sessions")}</Typography>
                </Box>
            }

        </div>
    );
}