import { useState, useEffect } from 'react';
import { useFirebase } from '../context/firebase-context';
import { useAdmin } from '../context/admin-context';

export function useGroupSessions(groupId) {
    const [sessions, setSessions] = useState([]);
    const [feedback, setFeedback] = useState([]);
    const { db } = useFirebase();
    const { event } = useAdmin();

    useEffect(() => {
        if (event && groupId) {
            db.collection('sessions').where('eventId', "==", event.id).where('groupId', "==", groupId).orderBy('startDate', 'asc')
                .onSnapshot((snapshot) => {
                    const s = [];
                    snapshot.forEach(function (doc) {
                        s.push({ id: doc.id, ...doc.data() });
                    });
                    setSessions(s);
                    s.forEach(session => {
                        db.collection('sessions')
                            .doc(session.id)
                            .collection('feedback')
                            .onSnapshot((snapshot) => {
                                const g = [];
                                snapshot.forEach(function (doc) {
                                    g.push({ id: doc.id, ...doc.data() });
                                });
                                if(g.length > 0){
                                    setFeedback(g);
                                }
                            })

                    })
                });
        }
    }, [db, event]);

    const deleteSession = (sessionId, { errorCallback }) => {
        db.collection('sessions')
            .doc(sessionId).delete()
            .catch(err => {
                errorCallback();
            });
    };

    return {
        sessions,
        deleteSession,
        feedback
    }

}