import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from "react-i18next";
import { Typography, Button, IconButton } from '@material-ui/core';
import DatePicker, { DateObject } from "react-multi-date-picker";
import "react-multi-date-picker/styles/colors/red.css"
import DatePanel from "react-multi-date-picker/plugins/date_panel";
import TimePicker from "react-multi-date-picker/plugins/time_picker";
import Cancel from '@material-ui/icons/Cancel';


const useStyles = makeStyles((theme) => ({
    button: {
        marginLeft: "30px"
    },
    dialog_title: {
        marginLeft: "5%",
        marginTop: "25px",
    },
    dialog_subtitle: {
        marginBottom: "30px",
        width: "90%",
    },
    dialog_actions: {
        marginTop: "15px",
        margin: "10px"
    }
}));

export function RecurrentSessionDialog(props) {
    const { closeCallback, editRecurrentDates, sessionDay, sessionHour } = props;
    const { t, i18n } = useTranslation();
    const classes = useStyles();
    const [datesSelection, setDatesSelection] = useState(editRecurrentDates)
    const weekDays = (i18n.language === 'es') ? ["Do", "Lu", "Ma", "Mi", "Ju", "Vi", "Sa"] : ''
    const months = (i18n.language === 'es') ? ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"] : ''

    closeCallback(datesSelection);

    function HandleSelectedDates(props) {
        const elementDate = props.elementDate;
        const indexPosition = props.indexPosition

        function handleDeleteDate() {
            const newDatesSelection = [...datesSelection];
            newDatesSelection.splice(indexPosition, 1);
            setDatesSelection(newDatesSelection);
            closeCallback(datesSelection);
        }

        return <Grid
            container
            direction="row"
            justify="flex-start"
            alignItems="center"
            style={{ marginBottom: 20 }}>
            <Typography variant="h3" >{t("recurrent_session_dialog.selected_date")}</Typography>
            <Typography variant="h1" style={{ fontWeight: 400, fontSize: 16, marginLeft: 10 }}>{elementDate.day + "/" + elementDate.month.number + "/" + elementDate.year }</Typography>
            <Typography variant="h3" style={{ marginLeft: 10 }}>{t("recurrent_session_dialog.selected_date_2")}</Typography>
            <Typography variant="h1" style={{ fontWeight: 400, fontSize: 16, marginLeft: 10 }}>{sessionHour.getHours() + ":" + sessionHour.getMinutes()}</Typography>
            <IconButton
                component="span"
                variant="outlined"
                disableElevation
                color="primary"
                onClick={() => handleDeleteDate()}
                style={{ width: 35, height: 35, marginLeft: 15 }}>
                <Cancel
                    className={classes.textfield_icon_remove} />
            </IconButton>
        </Grid>
    }

    function handleMinDate() {
        if (sessionDay > datesSelection[0]) {
            datesSelection.map((date, index) => {
                if (date.day < sessionDay.getDate() + 1) {
                    const newDatesSelection = [...datesSelection];
                    newDatesSelection.splice(index, 1);
                    setDatesSelection(newDatesSelection);
                }
            })
        }

        var date = new DateObject(sessionDay);
        date.setHour(new Date(sessionHour).getHours())
        date.setMinute(new Date(sessionHour).getMinutes())
        return date.add(1, "days");
    }

    return (
        <div style={{ minHeight: '150px', height: 'auto', marginLeft: 50, marginBottom: 50 }}>
            <Typography variant="h1" style={{ marginTop: 20 }}>{t("recurrent_session_dialog.title")}</Typography>
            <div style={{ minHeight: '150px', height: 'auto', width: 650 }} >
                <Typography variant="h3" className={classes.dialog_subtitle} style={{ marginBottom: 30, marginTop: 15 }} > {t("recurrent_session_dialog.subtitle")} </Typography>
                <DatePicker
                    multiple
                    className="red"
                    format="DD/MM/YYYY"
                    currentDate={handleMinDate()}
                    value={datesSelection}
                    onChange={setDatesSelection}
                    weekDays={weekDays}
                    months={months}
                    minDate={handleMinDate()}
                    arrow={false}
                    render={(element, openCalendar) => {
                        return <Button
                            variant="contained"
                            style={{ marginBottom: 30 }}
                            disableElevation
                            color="primary"
                            onClick={openCalendar}>
                            {t("recurrent_session_dialog.date_picker_button")}
                        </Button>
                    }}
                    plugins={[<DatePanel header={(i18n.language === 'es') ? 'Fechas' : 'Dates'} sort="date" markFocused />]}
                />
                <Grid
                    container
                    direction="column"
                    justify="flex-start"
                    alignItems="center"
                    style={{ paddingBottom: 10 }}
                >
                    {datesSelection.length > 0 &&
                        datesSelection.sort((a, b) => a - b).map((element, index) => (
                            <HandleSelectedDates elementDate={element} indexPosition={index} />
                        ))}
                </Grid>
            </div>
        </div>
    )
}