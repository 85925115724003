import React, { useState, useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useFirebase } from '../shared/context/firebase-context';
import { useHistory } from 'react-router-dom';
import {
    Box,
    Typography,
    ButtonGroup,
    Button,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    Chip,
    TextField,
    Snackbar,
    Grid, Dialog, DialogTitle, DialogContent, DialogActions
} from '@material-ui/core';
import { useTranslation } from "react-i18next";
import Divider from '@material-ui/core/Divider';
import { useAdmin } from '../shared/context/admin-context';
import { useEvent } from '../shared/hooks/useEvents';
import Lottie from 'react-lottie';
import animationData from '../lotties/gears_loading';
import Cropper from 'react-easy-crop';
import { getCroppedImg } from '../shared/utils/cropImage';
import MuiAlert from '@material-ui/lab/Alert';


function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        width: '100%',
    },
    rootChip: {
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
        listStyle: 'none',
        padding: theme.spacing(0.5),
        margin: 0,
    },
    chip: {
        margin: theme.spacing(0.5),
    },
    imgRound: {
        border: '1px solid #FF5851',
        width: 80,
        height: 80,
        borderRadius: 80 / 2,
        objectFit: 'contain'
    },
    input: {
        display: 'none',
    }
}));

const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
        preserveAspectRatio: "xMidYMid slice"
    }
};

export function NewEventDialog(props) {
    const { openNewEventDialog, setOpenNewEventDialog } = props;
    const classes = useStyles();
    const { auth, user } = useFirebase();
    const { addEvent } = useEvent();
    const { userDetails } = useAdmin();
    const { t, i18n } = useTranslation();
    const history = useHistory();
    const [unCroppedLogo, setUncroppedLogo] = useState();
    const [croppedLogoImage, setCroppedLogoImage] = useState();
    const [openDialog, setOpenDialog] = useState(false);
    const [crop, setCrop] = useState({ x: 0, y: 0 })
    const [zoom, setZoom] = useState(1)
    const [croppedAreaPixels, setcroppedAreaPixels] = useState()

    const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
        setcroppedAreaPixels(croppedAreaPixels);
    }, [])

    const handleLogoCrop = (event) => {
        if (event.target.files.length > 0) {
            setUncroppedLogo({ url: URL.createObjectURL(event.target.files[0]), file: event.target.files[0] });
            setOpenDialog(true);
        }
    }

    const saveCroppedImage = async () => {
        const croppedImage = await getCroppedImg(
            unCroppedLogo.url, croppedAreaPixels, 256, 256
        );
        setLogo(croppedImage);
        var img = URL.createObjectURL(croppedImage);
        setCroppedLogoImage(img);
        setOpenDialog(false);
    }

    function handleLanguage(lang) {
        i18n.changeLanguage(lang);
    }

    const [openFailureSnackbar, setOpenFailureSnackbar] = useState(false);
    const handleCloseFailureSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenFailureSnackbar(false);
    };

    const [helperError, setHelperError] = useState(' ');
    const [retry, setRetry] = useState(false);

    const [size, setSize] = useState('');
    const [periodicity, setPeriodicity] = useState('');

    const handleSizeChange = (event) => {
        setSize(event.target.value);
        setHelperError(' ');
    };

    const handlePeriodicityChange = (event) => {
        setPeriodicity(event.target.value);
        setHelperError(' ');
    };

    const [chipData] = useState([
        { key: "conference", label: t("createAdmin.step_two_third_question_first_answer") },
        { key: "webinar", label: t("createAdmin.step_two_third_question_second_answer") },
        { key: "rrhh", label: t("createAdmin.step_two_third_question_third_answer") },
        { key: "meetup", label: t("createAdmin.step_two_third_question_fourth_answer") },
        { key: "workshop", label: t("createAdmin.step_two_third_question_fifth_answer") },
        { key: "summit", label: t("createAdmin.step_two_third_question_sixth_answer") },
        { key: "training", label: t("createAdmin.step_two_third_question_seventh_answer") },
        { key: "networking", label: t("createAdmin.step_two_third_question_eigth_answer") },
    ]);

    const [valueType, setValueType] = useState();

    const handleTypeClick = (chipClicked) => {
        setValueType(chipClicked.key);
    };

    const [valueName, setValueName] = useState("");

    const handleChangeName = (event) => {
        setValueName(event.target.value);
    };

    const [logo, setLogo] = useState();

    const [activeStep, setActiveStep] = useState(1);
    const handleNext = () => {
        switch (activeStep) {
            case 0:
                if (valueRadio) {
                    setHelperError(' ');
                    setActiveStep((prevActiveStep) => prevActiveStep + 1);
                } else {
                    setHelperError(t("createAdmin.error_non_selected_item"));
                }
                break;
            case 1:
                if (size && periodicity) {
                    setHelperError(' ');
                    setActiveStep((prevActiveStep) => prevActiveStep + 1);
                } else {
                    setHelperError(t("createAdmin.error_non_selected_item"));
                }
                break;
            case 2:
                if (valueName) {
                    setHelperError(' ');
                    addEvent(user.uid,
                        valueName,
                        logo,
                        valueRadio,
                        size,
                        periodicity,
                        valueType ? [valueType] : [],
                        userDetails,
                        { successCallback: (success) => { success ? closeDialog() : handleError() } });
                    setActiveStep((prevActiveStep) => prevActiveStep + 1);
                } else {
                    setHelperError(t("createAdmin.error_name_empty"));
                }
                break;
            default:
            // code block
        }
    };


    function closeDialog() {
        history.push('/');
        setActiveStep(1);
        setCrop({ x: 0, y: 0 });
        setZoom(1);
        setSize('');
        setValueRadio('');
        setValueType();
        setPeriodicity('');
        setcroppedAreaPixels();
        setUncroppedLogo();
        setLogo();
        setCroppedLogoImage();
        setValueName('');
        setOpenNewEventDialog(false);
    }

    const handleError = () => {
        setRetry(true);
        setOpenFailureSnackbar(true);
    }

    const handleRetry = () => {
        setRetry(false);
        addEvent(user.uid,
            valueName,
            logo,
            valueRadio,
            size,
            periodicity,
            valueType ? [valueType] : [],
            userDetails,
            { successCallback: (success) => { success ? closeDialog() : handleError() } });
    }

    const [valueRadio, setValueRadio] = useState('');

    const getStepContent = (stepIndex) => {
        switch (stepIndex) {
            case 1:
                return (
                    <Box display="flex" alignItems="center" flexDirection="column" style={{ minWidth: "200px", maxWidth: "400px", marginTop: '20px' }}>
                        <Typography variant="h1" >
                            {t("createAdmin.step_two_title")}
                        </Typography>
                        <Box flexDirection="column" style={{ display: 'flex', marginTop: "10px" }}>
                            <Typography variant="body1">
                                {t("createAdmin.step_two_subtitle")}
                            </Typography>
                        </Box>
                        <Box width="100%" alignItems="left" flexDirection="column" style={{ marginTop: "40px" }}>
                            <Typography variant="h2" color="textPrimary">
                                {t("createAdmin.step_two_first_question")}
                            </Typography>
                            <FormControl variant="outlined" className={classes.formControl}>
                                <InputLabel id="size-select-outlined-label">{t("createAdmin.hint_dropdown")}</InputLabel>
                                <Select
                                    labelId="size-select-outlined-label"
                                    id="size-simple-select-outlined"
                                    value={size}
                                    onChange={handleSizeChange}
                                    label={t("createAdmin.hint_dropdown")}
                                >
                                    <MenuItem value="1_10_members">{t("createAdmin.step_two_first_question_first_answer")}</MenuItem>
                                    <MenuItem value="11_50_members">{t("createAdmin.step_two_first_question_second_answer")}</MenuItem>
                                    <MenuItem value="51_200_members">{t("createAdmin.step_two_first_question_third_answer")}</MenuItem>
                                    <MenuItem value="201_1000_members">{t("createAdmin.step_two_first_question_fourth_answer")}</MenuItem>
                                    <MenuItem value="more_than_1000">{t("createAdmin.step_two_first_question_fifth_answer")}</MenuItem>
                                </Select>
                            </FormControl>
                            <Typography variant="h2" color="textPrimary" style={{ marginTop: "10px" }}>
                                {t("createAdmin.step_two_second_question")}
                            </Typography>
                            <FormControl variant="outlined" className={classes.formControl}>
                                <InputLabel id="periodicity-select-outlined-label">{t("createAdmin.hint_dropdown")}</InputLabel>
                                <Select
                                    labelId="periodicity-select-outlined-label"
                                    id="periodicity-simple-select-outlined"
                                    value={periodicity}
                                    onChange={handlePeriodicityChange}
                                    label={t("createAdmin.hint_dropdown")}
                                >
                                    <MenuItem value="all_weeks">{t("createAdmin.step_two_second_question_first_answer")}</MenuItem>
                                    <MenuItem value="once_month">{t("createAdmin.step_two_second_question_second_answer")}</MenuItem>
                                    <MenuItem value="some_anually">{t("createAdmin.step_two_second_question_third_answer")}</MenuItem>
                                    <MenuItem value="sporadically">{t("createAdmin.step_two_second_question_fourth_answer")}</MenuItem>
                                </Select>
                            </FormControl>
                            <Typography variant="h2" color="textPrimary" style={{ marginTop: "10px" }}>
                                {t("createAdmin.step_two_third_question")}
                            </Typography>
                            <Box component="ul" className={classes.rootChip}>
                                {chipData.map((data) => {
                                    return (
                                        <li key={data.key}>
                                            <Chip
                                                onClick={() => handleTypeClick(data)}
                                                color={data.key === valueType ? "primary" : undefined}
                                                clickable={true}
                                                variant="outlined"
                                                label={data.label}
                                                className={classes.chip}
                                            />
                                        </li>
                                    );
                                })}
                            </Box>
                        </Box>
                    </Box>
                );
            case 2:
                return (
                    <Box display="flex" alignItems="center" flexDirection="column" style={{ minWidth: "200px", maxWidth: "400px", marginTop: '20px' }}>
                        <Typography variant="h1" >
                            {t("createAdmin.step_three_title")}
                        </Typography>
                        <Box flexDirection="column" style={{ display: 'flex', marginTop: "10px" }}>
                            <Typography variant="body1">
                                {t("createAdmin.step_three_subtitle")}
                            </Typography>
                        </Box>
                        <Box width="100%" alignItems="left" flexDirection="column" style={{ marginTop: "40px" }}>
                            <Typography variant="h2" color="textPrimary">
                                {t("createAdmin.step_three_question")}
                            </Typography>
                            <TextField value={valueName} style={{ width: "100%" }} id="eventName" label=" " onChange={handleChangeName} />
                            <Typography variant="h2" color="textPrimary" style={{ marginTop: "20px" }}>
                                {t("createAdmin.step_three_image_title")}
                            </Typography>
                            <Box display="flex" width="100%" alignItems="center" flexDirection="column" style={{ marginTop: "40px" }}>
                                {logo
                                    ? <img src={croppedLogoImage} alt='event logo' className={classes.imgRound} />
                                    : <img src="/assets/images/event_logo_placeholder.png" alt='event logo' className={classes.imgRound} />}
                                <input accept="image/*" className={classes.input} id="contained-button-logo" multiple type="file" onChange={handleLogoCrop} />
                                <label htmlFor="contained-button-logo">
                                    <Button color="primary" component="span" style={{ marginTop: "10px" }}>
                                        {t("group_details_form.button_upload_image")}
                                    </Button>
                                </label>
                            </Box>
                        </Box>
                        <Dialog
                            open={openDialog}
                            onClose={setOpenDialog}
                            className={classes.crop_container}
                        >
                            <DialogTitle >
                                <Typography variant="h1">{t("crop_dialog.title")}</Typography>
                            </DialogTitle>
                            <DialogContent>
                                {unCroppedLogo
                                    ? <Box height="400px" width="400px" pb={5} position="relative" color="white">
                                        <Cropper
                                            image={unCroppedLogo.url}
                                            crop={crop}
                                            zoom={zoom}
                                            minZoom={-10}
                                            cropShape="round"
                                            showGrid={false}
                                            aspect={1}
                                            onCropChange={setCrop}
                                            onCropComplete={onCropComplete}
                                            onZoomChange={setZoom}
                                        />
                                    </Box>
                                    : <Box></Box>}
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={() => setOpenDialog(false)} variant="contained" color="secondary" autoFocus disableElevation>
                                    {t("crop_dialog.cancel")}
                                </Button>
                                <Button onClick={saveCroppedImage} variant="contained" color="primary" disableElevation>
                                    {t("crop_dialog.crop")}
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </Box>
                );
            default:
                return (
                    <Box display="flex" width="100%" alignItems="center" flexDirection="column" >
                        <Lottie
                            options={defaultOptions}
                            height={400}
                            width={400}
                        />
                        <Typography variant="h2" color="textPrimary" style={{ marginTop: "10px" }}>
                            {t("createAdmin.step_done_title")}
                        </Typography>
                    </Box>

                );
        }
    };

    return (
        <Dialog
            open={openNewEventDialog}
            onClose={setOpenNewEventDialog}
            maxWidth={"xl"}
            fullWidth={false}
            fullScreen={true}
            scroll={"paper"}
        >
            <main>
                <Box display="flex" width="100%" alignItems="center" flexDirection="column" >
                    <Grid
                        container
                        direction="row"
                        justify="center"
                        alignItems="center">
                        <img src="/assets/images/wemit.png" alt="wemit logo" width="120" style={{ margin: "25px" }} />
                        <ButtonGroup variant="text" color="primary" aria-label="text primary button group" style={{ marginTop: '5px', right: '5px', position: 'absolute' }}>
                            <Button onClick={() => handleLanguage("es")}>
                                ES
                    </Button>
                            <Button onClick={() => handleLanguage("en")}>
                                EN
                    </Button>
                        </ButtonGroup>
                    </Grid>
                    <Divider width="100%" />
                </Box>
                <Box display="flex" width="100%" alignItems="center" flexDirection="column" style={{ marginTop: "10px" }}>
                    {getStepContent(activeStep)}
                    <Typography variant="body2" style={{ color: "#FF0000", marginTop: "10px" }}>
                        {helperError}
                    </Typography>
                    <Box display="flex" width="100%" alignItems="center" flexDirection="column" style={{ marginTop: "40px" }}>
                        {activeStep !== 3 ? <Button
                            variant="contained"
                            color="primary"
                            width="200"
                            onClick={handleNext}
                            disableElevation
                            style={{ maxWidth: '25em', minWidth: '25em' }}>
                            {t("createAdmin.button_continue")}
                        </Button> :
                            retry ?
                                <Button
                                    variant="contained"
                                    color="primary"
                                    width="200"
                                    onClick={handleRetry}
                                    disableElevation
                                    style={{ maxWidth: '25em', minWidth: '25em' }}>
                                    {t("createAdmin.button_retry")}
                                </Button> : null
                        }
                    </Box>
                    <Button
                            variant="contained"
                            color="secondary"
                            width="200"
                            onClick={() => closeDialog()}
                            disableElevation
                            style={{ maxWidth: '25em', minWidth: '25em', marginTop: '20px', marginBottom: '4%' }}>
                            {t("createAdmin.button_cancel")}
                        </Button>
                </Box>
                <Snackbar open={openFailureSnackbar} autoHideDuration={3000} onClose={handleCloseFailureSnackbar}>
                    <Alert onClose={handleCloseFailureSnackbar} severity="error">
                        {t("createAdmin.error_creating_event")}
                    </Alert>
                </Snackbar>
            </main>
        </Dialog>
    );

}