import React from 'react';
import { Avatar, Typography } from '@material-ui/core';
import { useGroupSubscribers } from '../shared/hooks/useGroupSubscribers';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
    avatar: {
        marginRight: 20,
        width: "55px",
        height: "55px"
    },
    topicChips: {
        marginRight: 15,
    },
    noUsers_message_div: {
        margin: "20px",
        width: "100%"
    },
    user_name: {
        marginBottom: "0px",
        fontWeight: 600,
        fontSize: 14,
    },
    user_email: {
        marginTop: "5px"
    },
    rowTitle: {
        color: "DarkGray",
        fontWeight: "bold",
        fontSize: "17px"
    },
    tableCell: {
        paddingLeft: "20px",
        paddingRight: "20px",
        padding: "3px"
    }
}));

export function SubscribersList() {
    const { t } = useTranslation();
    const classes = useStyles();
    const { subscribers } = useGroupSubscribers();

    function createData(name, email, surname, imageUrl, rol, interests) {
        return { name, email, surname, imageUrl, rol, interests };
    }
    

    const rows = subscribers.map((user) => (
        (user.topics === undefined)
            ? createData(user.name, user.email, user.surname, user.imageUrl ?? '', user.job ?? '')
            : createData(user.name, user.email, user.surname, user.imageUrl ?? '', user.job ?? '', [user.topics.receive[0] ?? undefined, user.topics.receive[1] ?? undefined, user.topics.receive[2] ?? undefined])

    ))

    function setTopicChips(row) {
        if (row.interests != undefined) {
            return row.interests.map((interest) => {
                if (interest != undefined) {
                    return (
                        <Chip variant="outlined" size="small" className={classes.topicChips} label={interest} />
                    );
                }
            });
        }
    }

    function setNoUsersMessage() {
        return <div
            className={classes.noUsers_message_div}>
            <Typography variant="h3">{t("group_subscribers.noUsersMessage")}</Typography>
        </div>
    }

    return (
        <TableContainer>
            <Table size="big" aria-label="group_users_table">
                <TableHead>
                    <TableRow>
                        <TableCell className={classes.rowTitle} >{t("group_subscribers.userRowTitle")}</TableCell>
                        <TableCell className={classes.rowTitle} align="right">{t("group_subscribers.rolRowTitle")}</TableCell>
                        <TableCell className={classes.rowTitle__interest} align="right">{t("group_subscribers.interestsRowTitle")}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {(subscribers.length == 0) ? setNoUsersMessage() : rows.map((row) => (
                        <TableRow key={row.name}>
                            <TableCell component="th" scope="row">
                                <Grid
                                    container
                                    direction="row"
                                    justify="flex-start"
                                    alignItems="center"
                                >
                                    <Avatar className={classes.avatar} alt={row.name} src={row.imageUrl ?? ''} />
                                    <Grid item>
                                        <p className={classes.user_name}>{row.name} {row.surname}</p>
                                        <p className={classes.user_email}>{row.email}</p>
                                    </Grid>
                                </Grid>
                            </TableCell>
                            <TableCell align="right">{row.rol}</TableCell>
                            <TableCell align="right">
                                <Grid
                                    container
                                    direction="row"
                                    justify="flex-end"
                                >
                                    {setTopicChips(row)}
                                </Grid>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}