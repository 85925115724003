import React from 'react';
import { Box, CircularProgress } from '@material-ui/core';

export function Loading() {

    return (
        <main>
            <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                flexDirection="column"
                height="100vh"
            >
                <CircularProgress color="primary" />
            </Box>
        </main>
    );
}