import { useState, useEffect } from 'react';
import { useFirebase } from '../context/firebase-context';
import { useAdmin } from '../context/admin-context';
import { useParams } from 'react-router-dom';

export function useGroupSubscribers() {

    const { db, arrayUnion } = useFirebase();
    const { event } = useAdmin();
    let { groupId } = useParams();
    const [subscribers, setSubscribers] = useState([]);

    useEffect(() => {
        const usersInActualGroup = [];
        if (groupId && event) {
            db.collection('groups')
                .doc(groupId)
                .get()
                .then((doc) => {
                    const actualGroup = { id: doc.id, ...doc.data() };
                    db.collection('users')
                    .orderBy('name', 'asc')
                        .onSnapshot((snapshot) => {
                            snapshot.forEach(function (user) {
                                try {
                                    if (actualGroup.participants.includes(user.id)) {
                                        usersInActualGroup.push({ id: user.id, ...user.data() });
                                    }
                                } catch (e) {
                                    setSubscribers([]);
                                }
                            });
                            setSubscribers(usersInActualGroup);
                        });
                });
        };
    }, [db, event])

    const updatePrivateGroupUsers = async (invitationsList, { successCallback }) => {
        await db.collection('groups').doc(groupId).update({
            'participants': invitationsList,
        }).catch(function (error) {
            successCallback(false);
        });
        console.log(
            "success"
        );
        successCallback(true);
    }

    return {
        subscribers,
        updatePrivateGroupUsers
    }
}