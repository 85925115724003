import { useState, useEffect } from 'react';
import { useAdmin } from '../context/admin-context';
import { useFirebase } from '../context/firebase-context';

export function useEvent() {
  const { db, storage } = useFirebase();
  const { setEvent, userDetails, setUserDetails, setReload } = useAdmin();
  const [ eventList, setEventList] = useState([]);

  useEffect( () => {
    db.collection('events')
    .orderBy('name', 'asc')
            .onSnapshot((snapshot) => {
                const g = [];
                snapshot.forEach(function (doc) {
                    g.push({ id: doc.id, ...doc.data() });
                });
                setEventList(g);
            });

}, [db, userDetails]);

  const addEvent = (uid, eventName, eventLogo, usage, size, periodicity, type, currentUser, { successCallback }) => {
    const event = {
      name: eventName,
      description: "",
      website: "",
      logoUrl: "",
      participants: [uid],
      info_analitycs: {
        usage: usage,
        org_size: size,
        periodicity: periodicity,
        type: type
      }
    };

    createFirestoreEventDetails(event, eventLogo, currentUser, { successCallback: successCallback });
  };

  const updateEventDetails = (event, eventLogo, currentUser, { successCallback }) => {
    const { id, ...auxEvent } = event;
    auxEvent.id = id;
    if (eventLogo) {
      uploadWithLogo(auxEvent, eventLogo, false, currentUser ,{ successCallback: successCallback });
    } else {
      updateFirestoreEventDetails(event.id, auxEvent, { successCallback: successCallback });
    }
  };

  const uploadWithLogo = (event, eventLogo, create, currentUser, { successCallback }) => {
    var storageRef = storage.ref();
    var storageEventRef = storageRef.child('eventLogos/' + event.id + '/eventLogo/' + Date.now().toString());
    storageEventRef.put(eventLogo).then(function (snapshot) {
      snapshot.ref.getDownloadURL().then((url) => {
        event.logoUrl = url;
        if (create) {
          updateUserDetails(event.participants[0], event.id, currentUser, { successCallback: successCallback });
          const {id, ...auxEvent} = event;
          updateFirestoreEventDetails(event.id, auxEvent, { successCallback: null });
        } else {
          updateFirestoreEventDetails(event.id, event, { successCallback: successCallback });
        }
      })
        .catch(err => {
          successCallback(false);
        });
    })
      .catch(err => {
        successCallback(false);
      });
  }

  const updateFirestoreEventDetails = (idEvent, event, { successCallback }) => {
    db.collection('events')
      .doc(idEvent).update(event)
      .then(function () {
        const { id, ...auxEvent } = event;
        auxEvent.id = idEvent;
        setEvent(auxEvent);
        setReload(true);
        if (successCallback) {
          successCallback(true);
        }
      })
      .catch(err => {
        successCallback(false);
      });
  };
 
  const createFirestoreEventDetails = (event, eventLogo, currentUser, { successCallback }) => {
    db.collection('events')
      .add(event)
      .then(function (doc) {
        const { id, ...auxEvent } = event;
        auxEvent.id = doc.id;
        if (eventLogo) {
          uploadWithLogo(auxEvent, eventLogo, true, currentUser, { successCallback: successCallback });
        } else {
          updateFirestoreEventDetails(auxEvent.id, auxEvent, { successCallback: successCallback });
          updateUserDetails(event.participants[0], doc.id, currentUser,{ successCallback: successCallback });
        }
      })
      .catch(err => {
        successCallback(false);
      });
  };

  const updateUserDetails = (userId, eventId, currentUser, { successCallback }) => {
    if(currentUser !== undefined){
      db.collection('users') 
      .doc(userId)
      .set({
        isAdmin: [ ...currentUser.isAdmin, eventId ],
        defaultEvent: eventId
      }, {merge: true})
      .then(function () {
        const { isAdmin, ...auxUserDetails } = userDetails;
        auxUserDetails.isAdmin = [ ...currentUser.isAdmin, eventId ];
        setUserDetails(auxUserDetails);
        successCallback(true);
      })
      .catch(err => {
        successCallback(false);
      });
    } else {
      db.collection('users') 
      .doc(userId)
      .set({
        isAdmin: [ eventId ],
        defaultEvent: eventId
      }, {merge: true})
      .then(function () {
        const { isAdmin, ...auxUserDetails } = userDetails;
        auxUserDetails.isAdmin = [ eventId ];
        setUserDetails(auxUserDetails);
        successCallback(true);
      })
      .catch(err => {
        successCallback(false);
      });
    }
    
  };


  return {
    updateEventDetails,
    addEvent,
    eventList
  }

}